import { firebaseDatabase } from 'js/library/utils/firebaseUtils';
import {
  convertToMillis,
  getUserInfo,
  separateBase64String,
  calcCooldown,
  isBase64,
} from 'js/library/utils/helpers';
import createStore from 'js/library/utils/API/Store/apiCreateStore';
import { createStoreChange, updateStore } from 'js/library/utils/API/Store/apiUpdateStore';
import { updateVoucherTemplate } from 'js/library/utils/API/apiUpdateVoucherTemplate';
import { createVoucherTemplate } from 'js/library/utils/API/apiCreateVoucherTemplate';
import { updateHasPromo } from 'js/library/utils/API/Store/apiUpdateHasPromo';
import { uploadFiles } from 'js/library/utils/API/apiUploadFiles';

export const updateStorePromotionExternalAction = async ({
  id,
  consumoUnico,
  cooldownHours,
  cooldownMinutes,
  dataGeracao,
  descricao,
  detailsDescription,
  neighborhood,
  qtdSimultaneo,
  titulo,
  type,
  fileThumbnail,
  thumbnail,
  fileFoto,
  foto,
  couponGetMode,

  idStore,
  description,
  showQRCode,
  title,
  storeType,
  autoValidate,
  enableDate,
  disableDate,
  tags,
  fileStoreThumbnail,
  storeThumbnail,
  fileStorePhoto,
  storePhoto,

  idEstabelecimento,
  idGestor,
  nomeEstabelecimento,
  lat,
  long,
  address,

  hasSimultaneo,
  photoListFile,

  templateInfo,
}) => {
  const newPromotion = idStore ? false : true;

  const currentDate = Date.now();
  idStore = idStore ?? firebaseDatabase.ref('Store').push().key;

  const allPromises = [];

  //tratando se imagem existe e é um base64
  if (isBase64(fileThumbnail)) {
    thumbnail = 'storage:' + idStore + ':thumbnail:' + currentDate;
    fileThumbnail = separateBase64String(fileThumbnail);

    allPromises.push(
      uploadFiles(
        'voucher',
        {
          mimeType: fileThumbnail.mimeType,
          fileName: thumbnail,
          buffer: fileThumbnail.buffer,
        },
        false
      )
    );
  }

  if (isBase64(fileFoto)) {
    foto = 'storage:' + idStore + ':foto:' + currentDate;
    fileFoto = separateBase64String(fileFoto);

    allPromises.push(
      uploadFiles(
        'voucher',
        {
          mimeType: fileFoto.mimeType,
          fileName: foto,
          buffer: fileFoto.buffer,
        },
        false
      )
    );
  }

  const storeFolder = storeType === 'promotion' ? 'promocao' : 'triibomania-premios';

  if (isBase64(fileStoreThumbnail)) {
    storeThumbnail = 'storage:' + idStore + ':thumbnail:' + currentDate;
    fileStoreThumbnail = separateBase64String(fileStoreThumbnail);

    allPromises.push(
      uploadFiles(
        storeFolder,
        {
          mimeType: fileStoreThumbnail.mimeType,
          fileName: storeThumbnail,
          buffer: fileStoreThumbnail.buffer,
        },
        false
      )
    );

    //renomeando devido a pastas diferentes de produto e promoção
    storeThumbnail =
      storeType === 'promotion' ? storeThumbnail : 'triibomania-premios/' + storeThumbnail;
  }

  if (isBase64(fileStorePhoto)) {
    storePhoto = 'storage:' + idStore + ':foto:' + currentDate;
    fileStorePhoto = separateBase64String(fileStorePhoto);

    allPromises.push(
      uploadFiles(
        storeFolder,
        {
          mimeType: fileStorePhoto.mimeType,
          fileName: storePhoto,
          buffer: fileStorePhoto.buffer,
        },
        false
      )
    );

    //renomeando devido a pastas diferentes de produto e promoção
    storePhoto = storeType === 'promotion' ? storePhoto : 'triibomania-premios/' + storePhoto;
  }

  let listaFotos = [];
  let listaFotosName = [];

  if (photoListFile !== null) {
    for (const key in photoListFile) {
      if (isBase64(photoListFile[key])) {
        listaFotos[key] = 'gal' + key + '_' + idStore + ':' + currentDate;
        listaFotosName[key] =
          storeType === 'promotion'
            ? 'gal' + key + '_' + idStore + ':' + currentDate
            : 'triibomania-premios/' + 'gal' + key + '_' + idStore + ':' + currentDate;
        photoListFile[key] = separateBase64String(photoListFile[key]);

        allPromises.push(
          uploadFiles(
            storeFolder,
            {
              mimeType: photoListFile[key].mimeType,
              fileName: listaFotos[key],
              buffer: photoListFile[key].buffer,
            },
            false
          )
        );
      }
    }
  }

  //dados do template
  const dataTemplate = {
    consumoUnico,
    cooldown: calcCooldown(cooldownHours, cooldownMinutes),
    dataGeracao: dataGeracao ?? Date.now(),
    descricao,
    detailsDescription: detailsDescription ?? descricao,
    detailsTitle: templateInfo?.detailsTitle ?? titulo,
    generator: templateInfo?.generator ?? true,
    neighborhood,
    foto,
    thumbnail,
    plural: templateInfo?.plural ?? 'Cupons',
    singular: templateInfo?.singular ?? 'Cupom',
    qtdSimultaneo: hasSimultaneo || showQRCode ? parseInt(qtdSimultaneo, 10) : 1,
    titulo,
    type: type ?? 'promotion',
    idEstabelecimento,
    idGestor,
    nomeEstabelecimento,
    lat: lat ?? 0,
    long: long ?? 0,
    address: address ?? 'Brasil',
    disableDate: templateInfo?.disableDate ?? 0,
    onSendExpiration: templateInfo?.onSendExpiration ?? 0,
    couponGetMode: couponGetMode ?? ' ',
    tags,
    nomeTemplate: templateInfo?.nomeTemplate ?? ' ',
    campanhaTriibo: templateInfo?.campanhaTriibo ?? false,
    consumed: templateInfo?.consumed ?? false,
  };

  if (!dataTemplate.couponGetMode) delete dataTemplate.couponGetMode;
  if (!dataTemplate.neighborhood) delete dataTemplate.neighborhood;

  //dados da store
  let dataStore = {
    id: idStore,
    description,
    dueDate: convertToMillis(disableDate),
    showQRCode,
    thumbnail: storeThumbnail,
    photo: storePhoto,
    photoList: listaFotosName,
    title,
    type: storeType,
    establishmentId: idEstabelecimento,
    establishmentName: nomeEstabelecimento,
    lat: lat ?? 0,
    long: long ?? 0,
    templateId: id,
    enableDate: convertToMillis(enableDate),
    disableDate: convertToMillis(disableDate),
    autoValidate: autoValidate === 'cliente' ? true : false,
    tags,
  };

  console.log('---', photoListFile, photoListFile.length === 0);

  //não enviando caso não seja adicionado fotos
  if (listaFotosName.length === 0) delete dataStore.photoList;
  if (photoListFile.length === 0) {
    console.log('entrou');
    dataStore.photoList = [];
  }

  console.log(dataStore.photoList);

  const uId = getUserInfo().uId;

  if (newPromotion) {
    const idTemplate = await createVoucherTemplate(uId, dataTemplate);
    dataStore.templateId = idTemplate.data.result;

    allPromises.push(createStore(uId, idStore, dataStore), updateHasPromo(idEstabelecimento));

    await Promise.all(allPromises);
  } else {
    const statusPromotion = localStorage.getItem('statusPromotion');

    if (['Ativo', 'Reprovado', 'Inativo'].includes(statusPromotion)) {
      allPromises.push(
        createStoreChange(
          uId,
          idEstabelecimento,
          nomeEstabelecimento,
          idStore,
          dataStore,
          convertToMillis(disableDate)
        )
      );
    } else {
      allPromises(updateStore(idStore, dataStore));
    }

    allPromises.push(
      updateHasPromo(idEstabelecimento),
      updateVoucherTemplate(uId, id, dataTemplate)
    );

    await Promise.all(allPromises);
  }
};

