import React, { useEffect, useState } from 'react';
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  ResponsiveContainer,
} from 'recharts';
import { Box } from '@mui/material';

const COLORS = [
  '#00BCD5',
  '#FFC921',
  '#001DFF',
  '#D01313',
  '#4DB417',
  '#EF534F',
  '#FFCD39',
  '#BDBDBD',
  '#5D6BC0',
  '#A3E0FB',
  '#42A5F5',
  '#AB47BC',
  '#76C27A',
];

export default ({ data, margin, height }) => {
  const [info, setInfo] = useState([]);

  const renderBars = () => {
    const properties = [];

    info.forEach((item) =>
      properties.push(
        ...Object.keys(item).filter(
          (property) =>
            property !== 'name' && property !== 'value' && !properties.includes(property)
        )
      )
    );

    return properties.map((prop, index) => <Bar dataKey={prop} key={index} fill={COLORS[index]} />);
  };

  useEffect(() => {
    const formatData = () => {
      const formatedData = [...data.map((item) => ({ [item.name]: item.value }))];

      setInfo(formatedData);
    };

    if (data) {
      setInfo(data);
      if (data[0]?.name && data[0]?.value) formatData();
    }
  }, [data]);

  return (
    <ResponsiveContainer width="80%" height={180}>
      {info.length === 0 ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderLeft="2px solid #00000030"
          borderBottom="2px solid #00000030"
        >
          Não há Dados
        </Box>
      ) : (
        <BarChart data={info} margin={10} barSize={30}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          {renderBars()}
        </BarChart>
      )}
    </ResponsiveContainer>
  );
};

export const MultiAxialBarChart = React.memo(({ data }) => {
  const renderBars = React.useCallback(() => {
    const properties = [];

    data.forEach((item) =>
      properties.push(
        ...Object.keys(item).filter(
          (property) => property !== 'name' && !properties.includes(property)
        )
      )
    );

    return properties.map((prop, index) => (
      <Bar yAxisId="left" key={index} dataKey={prop} fill={COLORS[parseInt(index)]} />
    ));
  }, [data]);

  return (
    <ResponsiveContainer width={'100%'} height={300}>
      {data.length === 0 ? (
        <Box
          style={{ height: '246px', width: '85%', marginLeft: '10%' }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderLeft="2px solid #00000030"
          borderBottom="2px solid #00000030"
        >
          Não há Dados
        </Box>
      ) : (
        <BarChart
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          barSize={30}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
          <Tooltip />
          <Legend />
          {renderBars()}
        </BarChart>
      )}
    </ResponsiveContainer>
  );
});

