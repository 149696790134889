import React, { useEffect, useState } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

// STYLES
import * as S from '../../Establishment/ListEstablishment/styles';
import { Paper, Box, Button, CircularProgress, Typography, useMediaQuery } from '@mui/material';
import { GoBackHeader } from '../CreateClub/RelationshipClub/GoBackHeader';
import { HeaderTitle } from '../CreateClub/RelationshipClub/HeaderTitle';

// APIS
import { getUserInfo, sortByClosestKey } from 'js/library/utils/helpers';
import { getBalance_v1 } from 'js/library/utils/API/Points/getBalance_v1';
import { GetPartnerUserForms } from 'js/library/utils/API/getPartnerUserById';
import SendUnitary from './SendUnitary';
import SendByFile from './SendByFile';
import { getEstablishmentInfoInBatch } from 'js/library/utils/API/Establishment/apiGetEstablishmentInfoInBatch';
import { getSeuClubList } from 'js/library/utils/API/seuClube';
import { apiListEstablishment } from 'js/library/utils/API/Establishment/apiListEstablishments';

export default function SendingPage(props) {
  const clubId = props?.location?.state?.clubId;
  const contractIdClub = props?.location?.state?.contractIdClub;
  const partnerId = props?.location?.state?.clubeData.partnerId;
  const establishmentId = props?.location?.state?.establishmentId;
  const contractIdEstablishment = props?.location?.state?.contractIdEstablishment;

  console.log('establishmentId', establishmentId);

  const [fromWhere, setFromWhere] = useState('');
  const [sendMode, setSendMode] = useState(1);
  const [fieldKey, setFieldKey] = useState({});
  const [balancePoints, setBalancePoints] = useState(0);
  const [loadingBalancePoints, setLoadingBalancePoints] = useState(false);
  const [establishmentList, setEstablishmentList] = useState([]);
  const [clubesList, setClubesList] = useState([]);
  const [queryEstablishment, setQueryEstablishment] = useState('');
  const [loadingEstablishmentList, setLoadingEstablishmentList] = useState(false);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(600));
  const userInfo = getUserInfo();
  const uId = userInfo.uId;

  const previousUrl = localStorage.getItem('previousUrlPoints');
  const history = useHistory();

  useEffect(() => {
    let timeDebounce;
    timeDebounce = setTimeout(async () => {
      setLoadingEstablishmentList(true);
      await fetchDataEstablishment(queryEstablishment);
      setLoadingEstablishmentList(false);
    }, 900);
    return () => {
      clearTimeout(timeDebounce);
    };
  }, [queryEstablishment]);

  useEffect(() => {
    if (!previousUrl) {
      setFromWhere('error');
    } else if (previousUrl.includes('estabelecimento')) {
      setFromWhere('estabelecimento');
    } else if (previousUrl.includes('seu-clube')) {
      setFromWhere('seuClube');
    } else if (previousUrl.includes('configuracoes')) {
      setFromWhere('adminConfig');
    }

    fetchDataClub();
  }, [previousUrl]);

  useEffect(() => {
    // é o contractId do estabelecimento triiboWifi + @contrato-triibo,com,br
    let triiboId = '';

    if (fromWhere === 'adminConfig') {
      triiboId = '-LEWO0lc274ebMRbuFnG@contrato-triibo,com,br';
      setFieldKey({
        fieldName: 'cellPhone',
        indexKey: true,
        allowEdition: false,
        inputType: 'text',
        label: 'Insira um celular',
        type: 'contactList',
        inputMask: 'cellPhone',
        fieldType: 'cellPhone',
        required: true,
      });
    } else if (fromWhere === 'estabelecimento' && contractIdEstablishment !== '') {
      triiboId = `${contractIdEstablishment}@contrato-triibo.com.br`;
      setFieldKey({
        fieldName: 'cellPhone',
        indexKey: true,
        allowEdition: false,
        inputType: 'text',
        label: 'Insira um celular',
        type: 'contactList',
        inputMask: 'cellPhone',
        fieldType: 'cellPhone',
        required: true,
      });
    } else if (fromWhere === 'seuClube' && contractIdClub !== '') {
      triiboId = `${contractIdClub}@contrato-triibo.com.br`;
    }

    if (fromWhere === 'seuClube') setLoadingBalancePoints(true);

    if (fromWhere === 'seuClube') {
      GetPartnerUserForms(partnerId).then((response) => {
        const field = response.formFields.filter((item) => item.indexKey);
        setFieldKey(field[0]);
      });
    }

    if (triiboId !== '') {
      getBalance_v1(uId, triiboId)
        .then((res) => {
          setBalancePoints(res.balance.consolidateBalance?.total);
        })
        .catch((e) => {
          setLoadingBalancePoints(false);
        })
        .finally(() => {
          setLoadingBalancePoints(false);
        });
    }
  }, [fromWhere, contractIdClub, contractIdEstablishment]);

  const fetchDataEstablishment = async (query = '') => {
    if (userInfo.admin) {
      const responseEstablishmentList = await apiListEstablishment(query, 0, 20);

      setEstablishmentList(responseEstablishmentList);
    } else if (!userInfo.admin && userInfo.estAdmin && userInfo.estAdmin.length > 0) {
      const responseEstablishmentList = await getEstablishmentInfoInBatch(userInfo.estAdmin);
      setEstablishmentList(responseEstablishmentList.response.result);
    } else {
      setEstablishmentList([]);
    }
  };

  const fetchDataClub = async () => {
    const responseClubsList = await getSeuClubList(userInfo.uId);
    setClubesList(responseClubsList);
  };

  if (!previousUrl) {
    return <Redirect to={'/admin'} />;
  } else
    return (
      <div style={{ paddingBottom: '10rem' }}>
        <S.Container>
          <GoBackHeader />
          <HeaderTitle pageInfo="Gestão de Pontos" />
          <ToastContainer autoClose={2000} />
        </S.Container>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ width: '100%', marginBottom: '20px' }}
        >
          <Typography variant={isMobile ? 'h6' : 'h5'}>Saldo disponível:</Typography>
          <Box sx={{ marginLeft: '10px' }}>
            {loadingBalancePoints ? (
              <Box sx={{ marginTop: '5px' }}>
                <CircularProgress size={20} />
              </Box>
            ) : (
              <Typography variant={isMobile ? 'h6' : 'h5'}>
                <strong>{balancePoints ?? 0}</strong>
              </Typography>
            )}
          </Box>
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ width: '100%', margin: '20px 0px', gap: '25px' }}
        >
          <Button
            variant="contained"
            onClick={() => setSendMode(1)}
            color={sendMode === 1 ? 'primary' : 'secondary'}
            sx={{
              transition: '1000ms',
              transform: sendMode === 1 ? 'scale(1.2)' : 'scale(0.9)',
              opacity: sendMode === 1 ? '1' : '0.25',
            }}
          >
            Envio por arquivo
          </Button>
          <Button
            variant="contained"
            onClick={() => setSendMode(2)}
            color={sendMode === 2 ? 'primary' : 'secondary'}
            sx={{
              transition: '1000ms',
              transform: sendMode === 2 ? 'scale(1.2)' : 'scale(0.9)',
              opacity: sendMode === 2 ? '1' : '0.25',
            }}
          >
            Envio unitário
          </Button>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Paper
            elevation={2}
            sx={{
              padding: '2rem 32px',
              margin: '0px 40px',
              width: '800px',
              height: 'max-content',
            }}
          >
            {sendMode === 1 && (
              <SendByFile
                clubId={clubId}
                establishmentId={establishmentId}
                history={history}
                fromWhere={fromWhere}
              />
            )}
            {sendMode === 2 && (
              <SendUnitary
                fieldKey={fieldKey}
                clubId={clubId}
                partnerId={partnerId}
                establishmentId={establishmentId}
                history={history}
                fromWhere={fromWhere}
                establishmentList={establishmentList}
                clubesList={clubesList}
                queryEstablishment={queryEstablishment}
                setQueryEstablishment={setQueryEstablishment}
                loadingEstablishmentList={loadingEstablishmentList}
                userInfo={userInfo}
              />
            )}
          </Paper>
        </Box>
      </div>
    );
}
