import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import * as D from 'js/components/DesignSystem/styles';
import * as S from './styles';

import closeImg from 'styles/assets/communications/closeImg.svg';
import editImg from 'styles/assets/communications/edit.svg';
import placeholderImportImg from 'styles/assets/communications/placeholderImportImg.svg';
import saveFile from 'styles/assets/communications/saveFile.svg';
import CropperModal from 'js/components/DesignSystem/Cropper/CropperModal';
import { toast } from 'react-toastify';

export default function ImportImage({
  image,
  setImage,
  ratio,
  autoWidth,
  imageDefault,
  allowFreeCrop = false,
  refScroll,
  setScrollPosition,
  setIsActiveOverflow,
}) {
  const [imageCrop, setImageCrop] = useState('');
  const [isCropImageOpen, setIsCropImageOpen] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });

  const hiddenFileInput = useRef(null);

  const handleClickInputImage = (e) => {
    hiddenFileInput.current.click();
  };

  const dragEvents = {
    onDragEnter: (e) => {
      e.preventDefault();
    },
    onDragLeave: (e) => {
      e.preventDefault();
    },
    onDragOver: (e) => {
      e.preventDefault();
    },
    onDrop: (e) => {
      e.preventDefault();
      e.stopPropagation();
      let file;
      if (e.dataTransfer) {
        file = e.dataTransfer.files;
      } else if (e.target) {
        file = e.target.files;
      }
      const reader = new FileReader();

      reader.readAsDataURL(file[0]);

      ratio && setIsCropImageOpen(true);

      reader.onloadend = () => {
        const image = reader.result;

        ratio ? setImageCrop(image) : setImage(image);
        setIsActiveOverflow && ratio === false && setIsActiveOverflow(true);
      };

      e.target.value = null;
    },
  };

  const handleChangeImage = (e) => {
    e.preventDefault();

    setScrollPosition && refScroll && setScrollPosition(refScroll, 0);

    let file;
    if (e.dataTransfer) {
      file = e.dataTransfer.files;
    } else if (e.target) {
      file = e.target.files;
    }
    const reader = new FileReader();

    reader.readAsDataURL(file[0]);

    ratio && setIsCropImageOpen(true);

    reader.onloadend = () => {
      const image = reader.result;
      ratio ? setImageCrop(image) : setImage(image);
      setIsActiveOverflow && ratio === false && setIsActiveOverflow(true);
    };

    e.target.value = null;
  };

  const toggleModal = (isActive) => {
    setIsCropImageOpen(isActive);
    setIsActiveOverflow && setIsActiveOverflow(true);
    setScrollPosition && refScroll && setScrollPosition(refScroll, 450);
  };

  const handleOpenModal = (e) => {
    const rect = e.target.getBoundingClientRect();
    setModalPosition({ top: rect.bottom + window.scrollY, left: rect.left + window.scrollX });
    setImageCrop(image);
    setIsCropImageOpen(true);
    setIsActiveOverflow && setIsActiveOverflow(false);
    setScrollPosition && refScroll && setScrollPosition(refScroll, 0);
  };

  return (
    <S.Container>
      {isCropImageOpen &&
        ReactDOM.createPortal(
          <CropperModal
            setIsActiveOverflow={setIsActiveOverflow}
            open={isCropImageOpen}
            setOpen={toggleModal}
            img={imageCrop}
            allowFreeCrop={allowFreeCrop}
            setImg={setImage}
            ratio={ratio}
            style={{
              position: 'absolute',
              top: `${modalPosition.top}px`,
              left: `${modalPosition.left}px`,
              zIndex: 1000,
            }}
          />,
          document.body
        )}

      <input
        type="file"
        ref={hiddenFileInput}
        accept="image/*"
        onChange={(e) => {
          if (e.target.files[0].size > 1048576 * 10) {
            toast.warn('Arquivo excedeu 10MB');
            return;
          }
          handleChangeImage(e);
        }}
        style={{ visibility: 'hidden', display: 'none' }}
      />
      <S.ImageImportContainer autoWidth={autoWidth} imageIsImported={image !== ''}>
        {image === '' ? (
          <S.ImageImportBox {...dragEvents}>
            <img src={placeholderImportImg} alt="" />
            <h5>
              Arraste e solte os arquivos ou <span>selecione</span>
            </h5>
            <div>
              <S.ButtonImportImage
                onClick={(e) => {
                  e.preventDefault();
                  setImage('');
                  handleClickInputImage();
                }}
              >
                <img src={saveFile} alt="" /> Carregar arquivo
              </S.ButtonImportImage>
              <D.TriiboH6>Tamanho máx: 10mb</D.TriiboH6>
            </div>
          </S.ImageImportBox>
        ) : (
          <S.PreviewImageBox>
            <S.ResetImage type="button" className="resetImageIcon" onClick={() => setImage('')}>
              <img src={closeImg} alt="" />
            </S.ResetImage>
            <img src={image} alt="" className="image" />
            {!imageDefault && (
              <S.editImage type="button" className="editImageIcon" onClick={handleOpenModal}>
                <img src={editImg} alt="" /> Editar
              </S.editImage>
            )}
          </S.PreviewImageBox>
        )}
      </S.ImageImportContainer>
    </S.Container>
  );
}

