import { Button } from '@mui/material';
import ReactInputMask from 'react-input-mask';
import styled from 'styled-components';

export const triiboBlack = '#383838';
export const triiboGray = '#AFAFAF';
export const triiboBlue = '#328796';
export const triiboPurple = '#6E3296';
export const triiboGradient = '#4E2952';
export const triiboLightBlue = '#08bad0';

export const TriiboH1 = styled.h1`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: ${triiboBlack};

  @media (min-width: 600px) {
    //tamanho tablet
    font-size: 30px;
  }

  @media (min-width: 900px) {
    //tamanho desktop
    font-size: 50px;
  }
`;

export const TriiboH2 = styled.h2`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: ${triiboBlack};

  @media (min-width: 600px) {
    //tamanho tablet
    font-size: 25px;
  }

  @media (min-width: 900px) {
    //tamanho desktop
    font-size: 30px;
  }
`;

export const TriiboH3 = styled.h3`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: ${triiboBlack};

  @media (min-width: 600px) {
    //tamanho tablet
    font-size: 22px;
  }

  @media (min-width: 900px) {
    //tamanho desktop
    font-size: 25px;
  }
`;

export const TriiboH4 = styled.h4`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: ${triiboBlack};

  @media (min-width: 600px) {
    //tamanho tablet
    font-size: 20px;
  }

  @media (min-width: 900px) {
    //tamanho desktop
    font-size: 20px;
  }
`;

export const TriiboH5 = styled.h5`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: ${triiboBlack};

  @media (min-width: 600px) {
    //tamanho tablet
    font-size: 15px;
  }

  @media (min-width: 900px) {
    //tamanho desktop
    font-size: 15px;
  }
`;

export const TriiboH6 = styled.h6`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: ${triiboBlack};

  @media (min-width: 600px) {
    //tamanho tablet
    font-size: 15px;
  }

  @media (min-width: 900px) {
    //tamanho desktop
    font-size: 12px;
  }
`;

export const TriiboFilledButton = styled.button`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  border-radius: 10px;
  padding: 0.4rem;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.3)) top/100% 800%;
  background-color: ${triiboGray};
  text-transform: capitalize;
  color: white;
  min-width: 100%;
  border: none;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  &:hover {
    background-position: bottom;
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.3) 0 0); */
  }

  &:disabled {
    cursor: not-allowed;
  }

  ${({ lightBlue }) =>
    lightBlue &&
    `
        background-color: ${triiboLightBlue};
    `}

  ${({ blue }) =>
    blue &&
    `
        background-color: ${triiboBlue};
    `}

  ${({ gray }) =>
    gray &&
    `
        background-color: ${triiboGray};
    `}

  ${({ purple }) =>
    purple &&
    `
        background-color: ${triiboPurple};
    `}

  ${({ black }) =>
    black &&
    `
        background-color: ${triiboBlack};
    `}

  ${({ white }) =>
    white &&
    `
        background: linear-gradient(white, rgba(0, 0, 0, 0.1)) top/100% 800%;
        color: ${triiboBlue};
        background-color: white;
    `}

@media (min-width: 900px) {
    min-width: 5%;
    padding: 0.5rem 1.8rem;
  }
`;

export const ButtonsEditClub = styled(TriiboFilledButton)`
  background: #06bad0;

  filter: brightness(100%);

  &:hover {
    filter: brightness(80%);
  }
`;

export const ButtonUploadImageClube = styled(TriiboFilledButton)`
  @media (max-width: 900px) {
    min-width: 50%;
  }

  @media (max-width: 700px) {
    width: 8rem;
    margin-left: 0 !important;
    margin-top: 0.7rem;
  }

  @media (max-width: 500px) {
    min-width: 100%;
  }
`;

export const SearchUserButton = styled(TriiboFilledButton)`
  border-radius: 0.3rem;
  height: 4rem;
  font-size: 1.2rem;
  margin-bottom: 1rem;

  @media screen and (max-width: 900px) {
    margin-bottom: 1rem;
    width: 8rem;
    height: 2.5rem;
    font-size: 0.8rem;
  }

  @media screen and (max-width: 456px) {
    margin-bottom: 0;
    width: 8rem;
    margin-top: 0.6rem;
    height: 2.5rem;
    font-size: 0.8rem;
  }
`;

export const TriiboWhiteButton = styled.button`
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  border-radius: 10px;
  padding: 0.4rem;
  background-color: white;
  border: 1px solid ${triiboGray};
  color: ${triiboGray};
  min-width: 100%;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  filter: brightness(138%);

  &:disabled {
    cursor: not-allowed;
  }

  &:hover {
    filter: brightness(100%);
  }

  ${({ blue }) =>
    blue &&
    `
    color: ${triiboBlue};
    border: 1px solid ${triiboBlue};
    `}

  ${({ gray }) =>
    gray &&
    `
    color: ${triiboGray};
    border: 1px solid ${triiboGray};
    `}

  ${({ purple }) =>
    purple &&
    `
    color: ${triiboPurple};
    border: 1px solid ${triiboPurple};
    `}

  ${({ black }) =>
    black &&
    `
    color: ${triiboBlack};
    border: 1px solid ${triiboBlack};
    `}

  ${({ transparent }) =>
    transparent &&
    `
    color: white;
    border: 1px solid white;
    background-color: transparent;
    `}

@media (min-width: 900px) {
    min-width: 5%;
    padding: 0.5rem 1.8rem;
  }
`;

export const TriiboTextField = styled.textarea`
  font-family: 'Source Sans Pro', sans-serif;
  border-radius: 10px;
  min-height: 1.5rem;
  padding: 0.7rem;
  outline: none;
  border: 1px solid ${triiboGray};
  width: 100%;
  resize: none;
  background-color: white;
  transition: 0.3s ease-in-out;

  &:disabled {
    cursor: not-allowed;
  }
`;

export const BirthDateMask = styled(ReactInputMask)`
  font-family: 'Source Sans Pro', sans-serif;
  border-radius: 10px;
  min-height: 1.5rem;
  padding: 0.7rem;
  outline: none;
  border: 1px solid ${triiboGray};
  width: 100%;
  resize: none;
  background-color: white;
  transition: 0.3s ease-in-out;

  &:disabled {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

export const TriiboField = styled.input`
  font-family: 'Source Sans Pro', sans-serif;
  border-radius: 10px;
  min-height: 1.5rem;
  padding: 0.7rem;
  outline: none;
  border: 1px solid ${({ error }) => (error ? 'red' : triiboGray)};
  width: 100%;
  resize: none;
  background-color: white;
  transition: 0.3s ease-in-out;

  &:disabled {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

export const TriiboTagsField = styled.div`
  font-family: 'Source Sans Pro', sans-serif;
  border-radius: 10px;
  min-height: 8rem;
  padding: 0.7rem;
  outline: none;
  border: 1px solid ${triiboGray};
  width: 100%;
  resize: none;
  background-color: white;
  /* overflow-y: scroll; */
  font-size: 0.9rem;
  color: ${triiboGray};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem 0.4rem;
  flex-wrap: wrap;

  &:disabled {
    cursor: not-allowed;
  }
`;

export const TriiboTagsPlaceholder = styled.span`
  display: block;

  ${({ $visibility }) =>
    $visibility &&
    `
    display: none;
    `}
`;

export const UserTags = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  background: gray;
  color: white;
  border-radius: 2rem;
  padding: 0.3rem 0.6rem;

  &:disabled {
    cursor: not-allowed;
  }
`;

export const TagText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`;

export const TagDelete = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
`;

export const TriiboDateField = styled.input.attrs({
  type: 'date',
})`
  font-family: 'Source Sans Pro', sans-serif;
  border-radius: 10px;
  min-height: 1.5rem;
  padding: 0.7rem;
  outline: none;
  border: 1px solid ${triiboGray};
  width: 100%;
  resize: none;
  background-color: white;

  &:disabled {
    cursor: not-allowed;
  }
`;

export const TriiboTelField = styled.input.attrs({
  type: 'number',
})`
  font-family: 'Source Sans Pro', sans-serif;
  border-radius: 10px;
  min-height: 1.5rem;
  padding: 0.7rem;
  outline: none;
  border: 1px solid ${triiboGray};
  width: 100%;
  resize: none;
  background-color: white;
`;

export const ModalContainer = styled.div`
  z-index: 3000;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #0000008c;
`;

export const ModalBackground = styled.div`
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
`;

export const ModalBody = styled.span`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.6rem;
  background: white;
  min-width: 20rem;
  min-height: 10rem;
  border-radius: 10px;
  padding: 3rem 1.8rem;
  font-family: 'Source Sans Pro', sans-serif;
`;

export const ModalLabelContainer = styled.label`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Source Sans Pro', sans-serif;
  gap: 1rem;
`;

export const ModalText = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const ModalTitle = styled(ModalText)`
  width: 60%;
  font-weight: 600;
`;

export const ModalSubtitle = styled(ModalText)`
  width: 80%;
  font-weight: 500;
`;

export const SaveAdminsButton = styled(TriiboFilledButton)`
  font-size: 1.2rem;
  margin-left: 2rem;

  @media screen and (max-width: 900px) {
    margin: 0;
  }
`;
