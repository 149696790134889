import { withStyles } from '@mui/styles';
import Badge from '@mui/material/Badge';

const StyledBadge2 = withStyles((theme) => ({
  badge: {
    fontSize: '12px',
    color: '#6c6c6c',
    borderRadius: '50%',
    height: '20px',
    width: '20px',
    right: -2,
    top: 3,
    border: '1px solid #6c6c6c',
    backgroundColor: '#fff',
    padding: '0',
  },
}))(Badge);

export default StyledBadge2;

