import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import * as S from '../../../components/Establishment/styles';
import * as D from 'js/components/DesignSystem/styles';

import {
  Paper,
  Select,
  TextField,
  Input,
  Button,
  Grid,
  Chip,
  Typography,
  IconButton,
  FormLabel,
  FormControlLabel,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  Switch,
  Box,
  CircularProgress,
} from '@mui/material';
import { Send, ArrowBack, Close, Help, DoneAll } from '@mui/icons-material';

import placeholder from 'styles/assets/placeholder/placeholder_triibo.png';
import thumbnailPromocao from 'styles/assets/promocao/thumbnail-promocao.png';
import listaFotos from 'styles/assets/promocao/listaFotos-promocao.png';

import Loading from 'js/containers/Loading/Loading';
import ImportImage from 'js/components/TriiboComponents/ImportImage';
import Downshift from 'downshift';
import deburr from 'lodash/deburr';
import { getKeywords } from 'js/library/utils/API/SearchUser/apiGetKeywords';

import { updateStoreBusinessPartnerAction } from 'js/core/actions/storeBusinessPartnerActions';
import {
  downloadImage,
  downloadImageAsBlob,
  uploadImage,
} from 'js/library/services/StorageManager';
import { convertToDate } from 'js/library/utils/helpers';
import { isAdminPage, b64toBlob, getUserInfo } from 'js/library/utils/helpers.js';
import EditShowId from 'js/components/Store/Promotion/EditShowId';
import { BsXLg } from 'react-icons/bs';

import { getStoreChangeRequest } from 'js/library/utils/API/Store/apiGetStoreChangeRequest';
import { toast } from 'react-toastify';
import { confirmStoreChanges } from 'js/library/utils/API/Store/apiConfirmStoreChanges';
import { updateStore, updateStoreApprovals } from 'js/library/utils/API/Store/apiUpdateStore';
import getEstablishmentId from 'js/library/utils/API/Establishment/apiReadEstablishment';
import Transition from 'js/containers/Transition';
import StyledBadge from 'js/containers/Store/StyledBadge';
import StyledBadge2 from 'js/containers/Store/StyledBadge2';
import renderInput from 'js/containers/Store/InputKeywords';
import { renderSuggestion } from 'js/containers/Store/suggestion';
import { verificarAprovacaoPromocao } from 'js/library/services/DatabaseManager';
import { GoBackHeader } from '../../Configurations/CreateClub/RelationshipClub/GoBackHeader';

let suggestions = [];

function getSuggestions(value) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0
    ? []
    : suggestions.filter((suggestion) => {
        const keep =
          count < 5 && suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

        if (keep) {
          count += 1;
        }

        return keep;
      });
}

function StoreBusinessPartnerExternal(props) {
  const history = useHistory();
  const editPage = window.location.href.includes('/editar');

  //convertendo enable date
  let enableDateInit = new Date(props.location.state.enableDate || Date.now());

  const enableYear = enableDateInit.getFullYear();
  const enableMonth = String(enableDateInit.getMonth() + 1).padStart(2, '0');
  const enableDay = String(enableDateInit.getDate()).padStart(2, '0');
  const enableHour = String(enableDateInit.getHours()).padStart(2, '0');
  const enableMinute = String(enableDateInit.getMinutes()).padStart(2, '0');

  enableDateInit = `${enableYear}-${enableMonth}-${enableDay}T${enableHour}:${enableMinute}`;

  //convertendo disable date
  let disableDateInit = new Date(props.location.state.disableDate || Date.now() + 86400000 * 365);

  const disableYear = disableDateInit.getFullYear();
  const disableMonth = String(disableDateInit.getMonth() + 1).padStart(2, '0');
  const disableDay = String(disableDateInit.getDate()).padStart(2, '0');
  const disableHour = String(disableDateInit.getHours()).padStart(2, '0');
  const disableMinute = String(disableDateInit.getMinutes()).padStart(2, '0');

  disableDateInit = `${disableYear}-${disableMonth}-${disableDay}T${disableHour}:${disableMinute}`;

  const [id, setId] = useState(null); // dados da store
  const [isPublished, setIsPublished] = useState(false);
  const [level, setLevel] = useState(99);
  const [detailsTitle, setDetailsTitle] = useState(null);
  const [detailsDescription, setDetailsDescription] = useState(null);
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [enableDate, setEnableDate] = useState(enableDateInit);
  const [disableDate, setDisableDate] = useState(disableDateInit);
  const [url, setUrl] = useState(null);
  const [region, setRegion] = useState('local');
  const [droidWebView, setDroidWebView] = useState(false);
  const [iOSWebView, setIOSWebView] = useState(false);
  const [type, setType] = useState('businessPartner');

  const [keywordsSetConsumption, setKeywordsSetConsumption] = useState('');
  const [keywords, setKeywords] = useState('');
  const [keywordsSetConsumptionList, setKeywordsSetConsumptionList] = useState([]);
  const [keyWordsSet, setKeywordsSet] = useState([]);
  const [tags, setTags] = useState([]);

  const [tagCapture, setTagCapture] = useState('');
  const [messageBlockedKw, setMessageBlockedKw] = useState('');

  const [thumbnail, setThumbnail] = useState(null);
  const [photo, setPhoto] = useState(null);

  const [thumbnailFile, setThumbnailFile] = useState('');
  const [photoFile, setPhotoFile] = useState('');
  const [photoListFile, setPhotoListFile] = useState([]);

  const [buttonLabel, setButtonLabel] = useState('');
  const [buttonDescription, setButtonDescription] = useState('');
  const [showButtonDescription, setShowButtonDescription] = useState(false);
  const [goToPlaceLabel, setGoToPlaceLabel] = useState('');
  const [showGoToPlace, setShowGoToPlace] = useState(false);

  const [campanhaTriibo, setCampanhaTriibo] = useState(false);

  const [goBack, setGoBack] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [aprovacao, setAprovacao] = useState(null);

  const [checkoutCode, setCheckoutCode] = useState(null);
  const wherePromo = localStorage.getItem('fromWherePromotion');
  const [action, setAction] = useState(null);
  const [status, setStatus] = useState('');
  const [comment, setComment] = useState('');

  const establishmentId = props.match.params.id;
  const [establishmentName, setEstablishmentName] = useState('');
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);

  useEffect(() => {
    const fetchPromoData = async () => {
      if (editPage) {
        const promoId = props.match.params.promoId;

        try {
          const dataReceived = await getStoreChangeRequest(promoId);
          const promoData = dataReceived.response;

          // storeInfo
          const storeInfo = promoData.storeInfo;

          // storeApprovals
          const dataApprovals = promoData.storeApprovals;
          let storeApprovals;
          if (dataApprovals && Object.keys(dataApprovals).length > 0) {
            const firstApprovalKey = Object.keys(dataApprovals)[0];
            storeApprovals = dataApprovals[firstApprovalKey];
          }

          // storeEdits
          const dataStoreEdits = promoData.storeEdits;
          let storeEdits = '';
          if (dataStoreEdits) {
            Object.values(dataStoreEdits).forEach((edits) => {
              storeEdits = edits;
            });
          }

          const mergeFields = (info, edits) => {
            return {
              ...info,
              ...edits,
            };
          };

          const finalState = mergeFields(storeInfo, storeEdits.changedFields || {});

          //ajuste region escrito errado
          finalState.region =
            finalState.region === 'nacional' ? 'national' : finalState.region ?? 'local';

          // Update state with finalState data
          setAction(storeApprovals?.action);
          setStatus(storeApprovals?.status);
          setComment(storeApprovals?.comment);
          setId(promoId);
          setIsPublished(finalState.isPublished);
          setLevel(finalState.level);
          setDetailsTitle(finalState.detailsTitle);
          setDetailsDescription(finalState.detailsDescription);
          setTitle(finalState.title);
          setDescription(finalState.description);
          setEnableDate(convertToDate(finalState.enableDate));
          setDisableDate(finalState.disableDate ? convertToDate(finalState.disableDate) : null);
          setUrl(finalState.url);
          setRegion(finalState.region ?? 'local');
          setDroidWebView(finalState.droidWebView ?? false);
          setIOSWebView(finalState.iOSWebView ?? false);
          setType(finalState.type);
          setKeywordsSetConsumptionList(finalState.keyWordsSetConsumption ?? []);
          setKeywordsSet(finalState.keyWordsSet ?? []);
          setTags(finalState.tags ?? []);
          setMessageBlockedKw(finalState.message);
          setThumbnail(finalState.thumbnail);
          setPhoto(finalState.photo);
          setButtonLabel(finalState.buttonLabel);
          setButtonDescription(finalState.buttonDescription);
          setShowButtonDescription(finalState.showButtonDescription ?? false);
          setGoToPlaceLabel(finalState.goToPlaceLabel ?? '');
          setShowGoToPlace(finalState.showGoToPlace ?? false);
          setCampanhaTriibo(finalState.campanhaTriibo ?? false);
          setCheckoutCode(finalState.checkoutCode ?? null);

          // Handle photo list files
          if (finalState.photoList !== undefined) {
            let newPhotoListFile = [];
            for (let i = 0; i < Object.keys(finalState.photoList).length; i++) {
              const downloadedImage = await downloadImage('promocao', finalState.photoList[i]);
              newPhotoListFile[i] = downloadedImage;
            }
            setPhotoListFile(newPhotoListFile);
          }

          const folderThumb =
            finalState.type === 'promotion' && finalState.thumbnail !== undefined
              ? finalState.thumbnail.split('/')[0]
              : 'promocao';

          // Handle thumbnail
          try {
            const downloadedThumbnail = await downloadImage(folderThumb, finalState.thumbnail);
            setThumbnailFile(downloadedThumbnail);
          } catch (error) {
            console.error('Foto não encontrada:', storeInfo.thumbnail);
          }

          // Handle photo
          if (finalState.photo !== undefined) {
            const loadedPhoto = await downloadImage('promocao', finalState.photo);
            setPhotoFile(loadedPhoto);
          }
        } catch (error) {
          console.error('Erro ao obter dados da promoção', error);
        }
      }
    };

    //buscando dados da promoção
    fetchPromoData();
  }, []);

  useEffect(() => {
    const fetchEstablishmentData = async () => {
      try {
        const result = await getEstablishmentId(establishmentId);

        setEstablishmentName(result.nome);
        setLat(result.lat);
        setLong(result.long);

        if (editPage) {
          const resultAprovacao = await verificarAprovacaoPromocao(
            props.match.params.promoId,
            'businessPartner',
            establishmentId,
            result.nome
          );
          setAprovacao(resultAprovacao);
        }
      } catch (error) {
        setLoading(false);
        toast.error('Ocorreu um erro ao buscar os dados.');
      }
    };

    if (establishmentId) {
      // Só chama a função se establishmentId estiver definido
      fetchEstablishmentData();
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataReceived = await getKeywords();

        suggestions = dataReceived.map((item) => ({
          label: item.value,
        }));
      } catch (error) {
        console.error('Erro na data:', error);
      }
    };

    fetchData();
  }, []);

  // LISTA DE IMAGENS -------------->
  const photoListChangedHandler = (file) => {
    if (photoFile === '') {
      setPhotoFile(file);
    } else {
      let photoListFileAux = [...(photoListFile ?? [])];
      if (file !== '') photoListFileAux.push(file);

      setPhotoListFile(photoListFileAux);
    }
  };

  const goBackFunc = () => {
    if (goBack === false) {
      window.history.back();
    } else {
      setOpen(true);
    }
  };

  const removeImage = (idx) => {
    let photoListFileAux = [...(photoListFile ?? [])];
    photoListFileAux.splice(idx, 1);

    setPhotoListFile(photoListFileAux);
  };

  const mainImage = (idx) => {
    const currentPhoto = photoFile;
    let photoListFileAux = [...(photoListFile ?? [])];
    const newPhoto = photoListFile[idx];

    photoListFileAux.splice(idx, 1);
    photoListFileAux.unshift(currentPhoto);

    setPhotoFile(newPhoto);
    setPhotoListFile(photoListFileAux);
  };

  const handlePushTag = () => {
    let tagHolder = tags;
    if (tagHolder.indexOf(tagCapture.toLocaleLowerCase()) === -1 && tagCapture.length !== 0) {
      tagHolder.push(tagCapture.toLowerCase());
    }

    setTags([...tagHolder]);
    setTagCapture('');
  };

  //handle downshift
  const handleChangeDownshiftConsume = (item) => {
    let keywordsSetConsumptionListAux = keywordsSetConsumptionList;

    if (keywordsSetConsumptionListAux.indexOf(item) === -1) {
      keywordsSetConsumptionListAux = [...keywordsSetConsumptionListAux, item];
    }

    setKeywordsSetConsumption('');
    setKeywordsSetConsumptionList(keywordsSetConsumptionListAux);
  };

  const handleDeleteDownshiftConsume = (item) => {
    setKeywordsSetConsumptionList((prevList) => {
      const newList = [...prevList];
      newList.splice(newList.indexOf(item), 1);
      return newList;
    });
  };

  const handleChangeDownshift = (item) => {
    setKeywordsSet((prevList) => {
      if (!prevList.includes(item)) {
        return [...prevList, item];
      }
      return prevList;
    });
    setKeywords('');
  };

  const handleDeleteDownshift = (item) => {
    setKeywordsSet((prevList) => {
      const newList = [...prevList];
      newList.splice(newList.indexOf(item), 1);
      return newList;
    });
  };

  //SALVAR/CRIAR PROMOÇÃO
  const checkInformation = async (e) => {
    e.preventDefault();
    const thumbnail = thumbnailFile === null;
    const photo = photoFile === null;
    if (tags.length < 1) {
      toast.error('Obrigatorio adicionar pelo menos uma tag.');
    } else if ((thumbnail || photo) && isPublished === true) {
      toast.error('Todas as imagens devem ser inseridas.');
    } else {
      setLoading(true);

      try {
        await updateStoreBusinessPartnerAction({
          id,
          isPublished,
          level,
          keyWordsSet,
          keywordsSetConsumptionList,
          messageBlockedKw,
          tags,
          detailsTitle,
          detailsDescription,
          title,
          description,
          establishmentId,
          establishmentName,
          lat,
          long,
          enableDate,
          disableDate,
          url,
          photo,
          thumbnail,
          type,
          region,
          droidWebView,
          iOSWebView,
          thumbnailFile,
          photoFile,
          buttonLabel,
          buttonDescription,
          showButtonDescription,
          goToPlaceLabel,
          showGoToPlace,
          photoListFile,
          campanhaTriibo,
          checkoutCode,
        });

        setLoading(false);
        history.push('/estabelecimento/' + establishmentId + '/promocoes');
      } catch (error) {
        setLoading(false);
        toast.error(error?.response?.data?.error ?? 'Ocorreu um erro.');
      }
    }
  };

  const handlePromotion = async (e, actionType) => {
    e.preventDefault();

    if (actionType === 'negado' && (comment === undefined || comment.trim() === '')) {
      toast.error('A mensagem de avaliação é obrigatória!');
      return;
    }

    const storageFolderStore = 'promocao';
    let currentDate = Date.now();

    // Lógica para tratamento de arquivos (foto, thumbnail e lista de fotos)
    let photoFileAux = photoFile;
    let photoName = photo;
    if (photoFileAux !== null) {
      if (photoFileAux.indexOf('https') === 0) {
        photoFileAux = await downloadImageAsBlob(photoFileAux);
      } else {
        photoFileAux = b64toBlob(photoFileAux);
      }

      photoName = 'storage:' + id + ':photo:' + currentDate;
      uploadImage(storageFolderStore, photoFileAux, photoName);
    }

    let thumbnailFileAux = thumbnailFile;
    let thumbName = thumbnail;
    if (thumbnailFileAux !== null) {
      if (thumbnailFileAux.indexOf('https') === 0) {
        thumbnailFileAux = await downloadImageAsBlob(thumbnailFileAux);
      } else {
        thumbnailFileAux = b64toBlob(thumbnailFileAux);
      }

      thumbName = 'storage:' + id + ':thumbnail:' + currentDate;
      uploadImage(storageFolderStore, thumbnailFileAux, thumbName);
    }

    let listaFotos = [];
    if (photoListFile !== null) {
      for (const key in photoListFile) {
        listaFotos[key] = 'gal' + key + '_' + id + ':' + currentDate;

        if (photoListFile[key].indexOf('https') === 0) {
          photoListFile[key] = await downloadImageAsBlob(photoListFile[key]);
        } else {
          photoListFile[key] = b64toBlob(photoListFile[key]);
        }
      }
    }

    // Lógica para definir o caminho da página
    let pathnameArray = window.location.pathname.split('/');
    let pathname = '/';

    if (pathnameArray[1] === 'admin' && pathnameArray[2] === 'estabelecimento') {
      pathname = '/estabelecimento/' + establishmentId + '/promocoes';
    } else if (
      pathnameArray[1] === 'admin' &&
      pathnameArray[2] === 'aprovacao' &&
      pathnameArray[3] === 'promocao'
    ) {
      pathname = '/admin/aprovar/promocoes';
    } else {
      pathname = '/estabelecimento/' + establishmentId + '/promocoes';
    }

    const userId = getUserInfo().uId;

    // Dados da promoção
    const data = {
      id: id,
      isPublished: isPublished,
      level: level,
      keyWordsSet,
      keyWordsSetConsumption: keywordsSetConsumptionList,
      message: messageBlockedKw,
      tags: tags,
      detailsTitle: detailsTitle,
      detailsDescription: detailsDescription,
      title: title,
      description: description,
      establishmentId: establishmentId,
      establishmentName: establishmentName,
      lat: lat,
      long: long,
      enableDate: Date.parse(enableDate),
      disableDate: Date.parse(disableDate),
      url: url,
      photo: photoName,
      thumbnail: thumbName,
      type: type,
      region: region,
      droidWebView: droidWebView,
      iOSWebView: iOSWebView,
      photoList: listaFotos,
      buttonLabel: buttonLabel,
      buttonDescription: buttonDescription,
      showButtonDescription: showButtonDescription,
      goToPlaceLabel: goToPlaceLabel,
      showGoToPlace: showGoToPlace,
      campanhaTriibo: campanhaTriibo,
      checkoutCode: checkoutCode,
    };

    const emptyFields = [];

    if (!data.tags[0]) {
      emptyFields.push('Tags');
    }

    if (emptyFields.length > 0) {
      const errorMessage =
        emptyFields.length === 1
          ? `O seguinte campo ainda precisa ser preenchido: ${emptyFields[0]}`
          : `Os seguintes campos ainda precisam ser preenchidos: ${emptyFields.join(', ')}`;
      toast.error(errorMessage);
      return;
    }

    // Lógica para aprovar ou reprovar a promoção
    if (actionType === 'aprovado') {
      if (action !== 'create') {
        // Aprovação de edição
        confirmStoreChanges(userId, id, true, comment, data).then(() => {
          history.push('/admin/aprovado/promocoes');
        });
      } else {
        // Aprovação de criação
        const payload = {
          status: 'aprovado',
          comment: comment ?? '',
        };
        updateStoreApprovals(userId, id, payload, null).then(() => {
          history.push('/admin/aprovado/promocoes');
          updateStore(id, data, pathname);
        });
      }
    } else if (actionType === 'negado') {
      if (action !== 'create') {
        // Reprovação de edição
        confirmStoreChanges(userId, id, false, comment, data).then(() => {
          history.push('/admin/aprovado/promocoes');
        });
      } else {
        // Reprovação de criação
        const payload = {
          status: 'negado',
          comment: comment,
        };
        updateStoreApprovals(userId, id, payload, null).then(() => {
          history.push('/admin/aprovado/promocoes');
          updateStore(id, data, pathname);
        });
      }
    }

    uploadImage(storageFolderStore, photoListFile, listaFotos);
  };

  if (loading) {
    return (
      <div className="loading">
        <Loading />
      </div>
    );
  } else {
    return editPage === true && title === null ? (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          height: '100vh',
          alignItems: 'center',
        }}
      >
        <CircularProgress size="100px" />
      </div>
    ) : (
      <div
        style={{
          padding: '30px 50px',
          maxWidth: '100%',
        }}
      >
        <GoBackHeader />
        {id === null ? (
          <Typography
            variant="h5"
            gutterBottom
            color="primary"
            style={{ fontWeight: '700', fontSize: '25px' }}
          >
            Adicionar Promoção
          </Typography>
        ) : (
          <EditShowId text="Editar Promoção" id={id} />
        )}

        {!id && (
          <Typography variant="h5" gutterBottom color="primary">
            Cadastro da oferta{' '}
          </Typography>
        )}
        <br />
        <form onSubmit={checkInformation}>
          <TextField
            placeholder=" Adicione o Título da Promoçã Ex.: Promoção Triibo..."
            className="input-field"
            type="text"
            value={title ?? ''}
            id="title"
            label="Título da Promoção"
            onChange={(e) => {
              setTitle(e.target.value);
              setGoBack(true);
            }}
            required={isPublished}
          />

          <TextField
            placeholder=" Descreva um pouco a promoção Ex.: A cada compra na Triibo..."
            className="input-field"
            multiline
            type="text"
            value={description ?? ''}
            id="description"
            required={isPublished}
            label="Descrição da Promoção"
            onChange={(e) => {
              setDescription(e.target.value);
              setGoBack(true);
            }}
          />

          <br />

          <div style={{ paddingTop: '1em' }}>
            <FormLabel name="2f">Tags da Promoção*</FormLabel>
            <div>
              <S.TagsContainer>
                <S.TagsAddContainer>
                  <D.TriiboTextField
                    rows={1}
                    placeholder={'Insira o tipo e aperte enter'}
                    value={tagCapture}
                    onChange={(e) => {
                      setTagCapture(e.target.value.trim());
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handlePushTag();
                      }
                    }}
                  />
                  <S.TagsAddButton
                    blue={true}
                    onClick={(e) => {
                      e.preventDefault();
                      handlePushTag();
                    }}
                  >
                    Adicionar tag
                  </S.TagsAddButton>
                </S.TagsAddContainer>
                <D.TriiboTagsField>
                  <D.TriiboTagsPlaceholder $visibility={tags.length === 0 ? false : true}>
                    Digite a tag desejada no campo acima e pressione o botão para adicioná-las
                  </D.TriiboTagsPlaceholder>
                  {tags.length > 0 &&
                    tags.map((body, index) => (
                      <D.UserTags key={index}>
                        <D.TagText>{body}</D.TagText>
                        <D.TagDelete
                          onClick={(e) => {
                            e.preventDefault();
                            let newArr = tags;
                            newArr.splice(index, 1);

                            setTags([...newArr]);
                          }}
                        >
                          {<BsXLg style={{ color: 'white' }} />}
                        </D.TagDelete>
                      </D.UserTags>
                    ))}
                </D.TriiboTagsField>
              </S.TagsContainer>
            </div>
          </div>

          <br />

          <Typography
            style={{
              marginTop: '10px',
              marginBottom: '-15px',
              fontWeight: '700',
              fontSize: '20px',
            }}
            variant="h5"
            gutterBottom
            color="primary"
          >
            Fotos da promoção
          </Typography>

          <br />
          <br />
          <div className="flex-external">
            <div
              className="img-thumb"
              style={{
                maxWidth: '400px',
                position: 'relative',
                top: '0',
                left: '0',
              }}
            >
              <Typography
                variant="subtitle2"
                gutterBottom
                color="secondary"
                style={{ fontWeight: '550', textAlign: 'center' }}
              >
                Thumbnail (aparecerá em "Promoções Próximas")
              </Typography>

              <img
                src={thumbnailPromocao}
                alt="Thumbnail"
                style={{
                  width: '100%',
                  maxWidth: '400px',
                  maxHeight: '447px',
                  position: 'relative',
                  top: '0',
                  left: '0',
                }}
              />

              <img
                className="img-position-simples"
                alt="thumbnail"
                src={thumbnailFile && thumbnailFile !== '' ? thumbnailFile : placeholder}
              />

              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ImportImage
                  imageDefault={null}
                  autoWidth={true}
                  image={thumbnailFile}
                  setImage={setThumbnailFile}
                  ratio={4 / 3}
                />
              </div>
            </div>

            {/* Lista de fotos */}
            <div>
              <Typography
                variant="subtitle2"
                gutterBottom
                color="secondary"
                className="style-text"
                style={{ fontWeight: '550' }}
              >
                Lista de Fotos (aparecerá em "Detalhe do Estabelecimento")
              </Typography>

              <section className="grid-style">
                <div
                  className="Foto-detalhe"
                  style={{
                    width: '100%',
                    maxWidth: '400px',
                    position: 'relative',
                    top: '0',
                    left: '0',
                  }}
                >
                  <img
                    alt="Lista de Fotos"
                    src={listaFotos}
                    style={{
                      width: '100%',
                      maxWidth: '392px',
                      position: 'relative',
                      top: '0',
                      left: '0',
                    }}
                  />

                  <StyledBadge2
                    style={{
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      height: '100%',
                      width: '100%',
                    }}
                    invisible={photoFile && photoFile[0] ? false : true}
                    badgeContent={
                      <Tooltip
                        title={
                          photoFile && photoFile[0]
                            ? 'Para trocar clique em uma imagem ao lado'
                            : 'Utilize o botão "Adicionar fotos"'
                        }
                        style={{ height: '100%', width: '100%' }}
                      >
                        <Help color="secondary" style={{ fontSize: 18 }} />
                      </Tooltip>
                    }
                    color="primary"
                  >
                    <img
                      src={photoFile && photoFile !== '' ? photoFile : placeholder}
                      alt="lista de fotos"
                      width="100%"
                      style={{
                        maxWidth: '393px',
                        position: 'absolute',
                        top: '0',
                        left: '0',
                      }}
                    />
                  </StyledBadge2>
                </div>

                <div className="lista-detalhes" id="photoList">
                  {photoListFile.map((item, i) => {
                    return (
                      <Grid key={i} item md={10} style={{ marginBottom: '15px' }}>
                        <StyledBadge
                          badgeContent={
                            <Tooltip title="Excluir">
                              <Close
                                style={{ width: '100%', height: '100%' }}
                                onClick={() => removeImage(i)}
                              />
                            </Tooltip>
                          }
                          invisible={false}
                        >
                          <Tooltip title={'Tornar principal'}>
                            <img
                              style={{ cursor: 'pointer' }}
                              onClick={() => mainImage(i)}
                              src={item ?? placeholder}
                              width="100%"
                              height="100%"
                              alt="Lista de Fotos"
                            />
                          </Tooltip>
                        </StyledBadge>
                      </Grid>
                    );
                  })}
                </div>
              </section>

              <div style={{ display: 'flex', justifyContent: 'left' }}>
                <ImportImage
                  imageDefault={null}
                  autoWidth={false}
                  image={''}
                  setImage={photoListChangedHandler}
                  ratio={4 / 3}
                />
              </div>
            </div>
          </div>

          <br />

          <TextField
            className="input-field"
            type="text"
            placeholder="Adicione um Título mais detalhado Ex.: Promoção Triibo não perca está oportunidade... "
            value={detailsTitle ?? ''}
            id="detailsTitle"
            label="Título da Promoção Detalhado"
            onChange={(e) => {
              setDetailsTitle(e.target.value);
              setGoBack(true);
            }}
          />

          <TextField
            className="input-field"
            multiline
            type="text"
            placeholder="Descreva com mais detalhes Ex.: Usando o nome Triibo recebará um Desconto Especial..."
            value={detailsDescription ?? ''}
            id="detailsDescription"
            label="Descrição da Promoção Detalhado"
            onChange={(e) => {
              setDetailsDescription(e.target.value);
              setGoBack(true);
            }}
          />

          <br />

          <Downshift
            id="downshift-multiple"
            inputValue={keywords}
            onChange={handleChangeDownshift}
            selectedItem={keyWordsSet}
            style={{ width: '100%' }}
          >
            {({
              getInputProps,
              getItemProps,
              isOpen,
              inputValue: inputValue2,
              selectedItem: selectedItem2,
              highlightedIndex,
            }) => (
              <div style={{ width: '100%', marginTop: '1em' }}>
                {renderInput({
                  fullWidth: true,
                  InputProps: getInputProps({
                    startAdornment: keyWordsSet.map((item) => (
                      <Chip
                        key={item}
                        tabIndex={-1}
                        label={item}
                        onDelete={() => handleDeleteDownshift(item)}
                      />
                    )),
                    onChange: (e) => {
                      setKeywords(e.target.value);
                      setGoBack(true);
                    },
                    placeholder: 'Selecione a keyword na lista abaixo',
                  }),
                  label: 'Keywords',
                })}
                {isOpen ? (
                  <Paper square>
                    {getSuggestions(inputValue2).map((suggestion, index) =>
                      renderSuggestion({
                        suggestion,
                        index,
                        itemProps: getItemProps({ item: suggestion.label }),
                        highlightedIndex,
                        selectedItem: selectedItem2,
                      })
                    )}
                  </Paper>
                ) : null}
              </div>
            )}
          </Downshift>

          <Downshift
            id="downshift-multiple"
            inputValue={keywordsSetConsumption}
            onChange={handleChangeDownshiftConsume}
            selectedItem={keywordsSetConsumptionList}
            style={{ width: '100%' }}
          >
            {({
              getInputProps,
              getItemProps,
              isOpen,
              inputValue: inputValue3,
              selectedItem: selectedItem3,
              highlightedIndex,
            }) => (
              <div style={{ width: '100%', marginTop: '1em' }}>
                {renderInput({
                  fullWidth: true,
                  InputProps: getInputProps({
                    startAdornment: keywordsSetConsumptionList.map((item) => (
                      <Chip
                        key={item}
                        tabIndex={-1}
                        label={item}
                        onDelete={() => handleDeleteDownshiftConsume(item)}
                      />
                    )),
                    onChange: (e) => {
                      setKeywordsSetConsumption(e.target.value);
                      setGoBack(true);
                    },
                    placeholder: 'Selecione as keywords de consumo',
                  }),
                  label: 'Keywords de consumo',
                })}
                {isOpen ? (
                  <Paper square>
                    {getSuggestions(inputValue3).map((suggestion, index) =>
                      renderSuggestion({
                        suggestion,
                        index,
                        itemProps: getItemProps({ item: suggestion.label }),
                        highlightedIndex,
                        selectedItem: selectedItem3,
                      })
                    )}
                  </Paper>
                ) : null}
              </div>
            )}
          </Downshift>

          <TextField
            style={{ width: '100%' }}
            placeholder="Você não tem essa permissão!"
            className="input-field"
            disabled={keywordsSetConsumptionList <= 0}
            value={messageBlockedKw ?? ''}
            multiline
            onChange={(e) => {
              setMessageBlockedKw(e.target.value);
              setGoBack(true);
            }}
            type="text"
            id="messageBlockedKw"
            required={keywordsSetConsumptionList.length > 0}
            label="Mensagem de bloqueio por keyword"
          />

          <br />
          <br />

          <FormLabel component="legend">Prioridade da Oferta</FormLabel>
          <Select
            style={{ minWidth: '500px' }}
            value={region}
            onChange={(e) => {
              setRegion(e.target.value);
              setGoBack(true);
            }}
            inputProps={{ id: 'region' }}
          >
            <MenuItem value="regional">Regional</MenuItem>
            <MenuItem value="national">Nacional</MenuItem>
            <MenuItem value="local">Local</MenuItem>
          </Select>

          <br />

          <TextField
            style={{ width: '300px' }}
            className="input-field"
            onChange={(e) => {
              setLevel(e.target.value > 99 || e.target.value < 1 ? level : e.target.value);

              toast.error(
                e.target.value > 99 || e.target.value < 1
                  ? 'O nível da hierarquia deve ser entre 1 e 99.'
                  : null
              );
            }}
            value={level}
            type="number"
            id="level"
            required={isPublished}
            label="Hierarquia da Oferta"
          />

          <TextField
            className="input-field"
            onChange={(e) => {
              setCheckoutCode(e.target.value);
              setGoBack(true);
            }}
            value={checkoutCode ?? ''}
            type="text"
            id="checkoutCode"
            InputLabelProps={{
              shrink: checkoutCode ? true : false,
            }}
            label="Código de verificação"
          />

          <Input
            placeholder="Adicione o Link da Promoção"
            required={isPublished}
            id="url"
            fullWidth={true}
            value={url ?? ''}
            style={{ marginTop: '30px' }}
            onChange={(e) => {
              setUrl(e.target.value);
              setGoBack(true);
            }}
            startAdornment={
              <Tooltip title="Verificar a URL">
                <IconButton
                  onClick={() => {
                    let url = url;

                    if (
                      url.indexOf('{code}') > 0 ||
                      url.indexOf('{email}') > 0 ||
                      url.indexOf('{name}') > 0 ||
                      url.indexOf('{uid}') > 0
                    ) {
                      window.open(url, '_blank');
                    } else {
                      toast.error('URL inválida!');
                    }
                  }}
                >
                  <Send />
                </IconButton>
              </Tooltip>
            }
          />

          <FormControlLabel
            control={
              <Switch
                checked={droidWebView}
                onChange={(e) => {
                  setDroidWebView(e.target.checked);
                  setGoBack(true);
                }}
                color="primary"
              />
            }
            label={'Ver site dentro do aplicativo em Andoid?'}
          />

          <FormControlLabel
            control={
              <Switch
                checked={iOSWebView}
                onChange={(e) => {
                  setIOSWebView(e.target.checked);
                  setGoBack(true);
                }}
                color="primary"
              />
            }
            label={'Ver site dentro do aplicativo em Ios?'}
          />

          <br />

          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={campanhaTriibo}
                  onChange={(e) => {
                    setCampanhaTriibo(e.target.checked);
                    setGoBack(true);
                  }}
                  color="primary"
                />
              }
              label={'Campanha Triibo?'}
            />

            <br />
            <br />

            <TextField
              className="input-field"
              value={buttonLabel ?? ''}
              multiline
              onChange={(e) => {
                setButtonLabel(e.target.value);
                setGoBack(true);
              }}
              type="text"
              id="buttonLabel"
              label="Texto do botão"
            />

            <TextField
              className="input-field"
              value={buttonDescription ?? ''}
              multiline
              onChange={(e) => {
                setButtonDescription(e.target.value);
                setGoBack(true);
              }}
              type="text"
              id="buttonDescription"
              label="Descrição do botão"
            />

            <TextField
              className="input-field"
              value={goToPlaceLabel ?? ''}
              multiline
              onChange={(e) => {
                setGoToPlaceLabel(e.target.value);
                setGoBack(true);
              }}
              type="text"
              id="goToPlaceLabel"
              label="Texto do botão para o estabelecimento"
            />

            <FormControlLabel
              control={
                <Switch
                  checked={showButtonDescription}
                  onChange={(e) => {
                    setShowButtonDescription(e.target.checked);
                    setGoBack(true);
                  }}
                  color="primary"
                />
              }
              label={'Mostrar descrição do botão?'}
            />

            <FormControlLabel
              control={
                <Switch
                  checked={showGoToPlace}
                  onChange={(e) => {
                    setShowGoToPlace(e.target.checked);
                    setGoBack(true);
                  }}
                  color="primary"
                />
              }
              label={'Mostrar botão de visualizar estabelecimento?'}
            />
          </div>

          <br />
          <br />

          <section>
            <Grid container spacing={4} alignItems="stretch" flexDirection="column">
              <Grid item>
                <TextField
                  id="enableDate"
                  label="Data de Ativação"
                  type="datetime-local"
                  value={enableDate ?? ''}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    setEnableDate(e.target.value);
                    setGoBack(true);
                  }}
                  required={isPublished}
                />
              </Grid>

              <Grid item>
                <TextField
                  id="disableDate"
                  label="Data de Desativação"
                  type="datetime-local"
                  value={disableDate ?? ''}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    setDisableDate(e.target.value);
                    setGoBack(true);
                  }}
                  required={isPublished}
                />
              </Grid>
            </Grid>
          </section>

          <FormControlLabel
            control={
              <Switch
                checked={isPublished}
                onChange={(e) => {
                  setIsPublished(e.target.checked);
                  setGoBack(true);
                }}
                color="primary"
              />
            }
            label={'Publicar a promoção?'}
          />

          <br />
          <br />

          {/* para promoções novas */}
          {wherePromo === null && (
            <>
              <Button
                style={{
                  fontWeight: '550',
                  marginTop: '20px',
                  color: 'white',
                  textTransform: 'capitalize',
                }}
                variant="contained"
                color="secondary"
                onClick={() => goBackFunc()}
                startIcon={<ArrowBack color="white" />}
              >
                Voltar
              </Button>

              <Button
                style={{
                  fontWeight: '550',
                  marginLeft: '10px',
                  marginTop: '20px',
                  color: 'white',
                  textTransform: 'capitalize',
                }}
                variant="contained"
                color="primary"
                type="submit"
                startIcon={<DoneAll color="white" />}
              >
                {isAdminPage()
                  ? 'Salvar'
                  : !id
                  ? 'Solicitar Aprovação'
                  : aprovacao && aprovacao.status === 'negado'
                  ? 'Solicitar Revisão'
                  : 'Atualizar Informação'}
              </Button>
            </>
          )}

          {/* para promoções existentes */}
          {wherePromo === 'estabelecimento-promo' && (
            <>
              <Button
                style={{
                  fontWeight: '550',
                  marginTop: '20px',
                  color: 'white',
                  textTransform: 'capitalize',
                }}
                variant="contained"
                color="secondary"
                onClick={() => goBackFunc()}
                startIcon={<ArrowBack color="white" />}
              >
                Voltar
              </Button>

              <Button
                style={{
                  fontWeight: '550',
                  marginLeft: '10px',
                  marginTop: '20px',
                  color: 'white',
                  textTransform: 'capitalize',
                }}
                variant="contained"
                color="primary"
                type="submit"
                startIcon={<DoneAll color="white" />}
              >
                {isAdminPage()
                  ? 'Salvar'
                  : !id
                  ? 'Solicitar Aprovação'
                  : aprovacao && aprovacao.status === 'negado'
                  ? 'Solicitar Revisão'
                  : 'Atualizar Informação'}
              </Button>
            </>
          )}

          {wherePromo === 'curadoria-promo' && (
            <S.CardContainer style={{ marginTop: 20 }}>
              <S.SideContainer>
                <S.SectionLabelContainer style={{ padding: '0rem 2rem 2rem 2rem' }}>
                  <Box sx={{ paddingBottom: '4rem' }}>
                    <S.TitleH2>Campo de Mensagem</S.TitleH2>
                  </Box>

                  <Box>
                    <form onSubmit={(e) => e.preventDefault()}>
                      <S.TagsLabelContainer style={{ marginTop: '0rem' }}>
                        Mensagem de Avaliação !
                      </S.TagsLabelContainer>

                      <br />
                      <D.TriiboTextField
                        disabled={status !== 'aberto'}
                        rows={1}
                        placeholder={'Mensagem...'}
                        maxLength="40"
                        onChange={(e) => setComment(e.target.value)}
                        value={comment === '' ? '' : comment}
                      />
                      <div>
                        <S.TagsAddButton
                          disabled={status !== 'aberto' ? true : false}
                          onClick={(e) => handlePromotion(e, 'aprovado')}
                          style={{
                            marginTop: '20px',
                          }}
                        >
                          Aprovar
                        </S.TagsAddButton>

                        <S.FailButton
                          disabled={status !== 'aberto' ? true : false}
                          onClick={(e) => handlePromotion(e, 'negado')}
                          style={{
                            marginLeft: '10px',
                            marginTop: '20px',
                          }}
                        >
                          Reprovar
                        </S.FailButton>
                      </div>
                    </form>
                  </Box>
                </S.SectionLabelContainer>
              </S.SideContainer>
            </S.CardContainer>
          )}
        </form>

        {/*ALERTS E DIALOGS*/}
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {'Deseja voltar a página anterior?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              As alterações realizadas não serão salvas.
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary">
              cancelar
            </Button>
            <Button onClick={() => window.history.back()} color="primary">
              voltar
            </Button>
          </DialogActions>
        </Dialog>
        {/*FIM ALERTS E DIALOGS*/}
      </div>
    );
  }
}

export default StoreBusinessPartnerExternal;

