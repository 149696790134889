import { uploadImage, downloadImageAsBlob } from 'js/library/services/StorageManager';
import { firebaseDatabase } from 'js/library/utils/firebaseUtils';
import { convertToMillis, getUserInfo, b64toBlob } from 'js/library/utils/helpers';
import createStore from 'js/library/utils/API/Store/apiCreateStore';
import { updateStore } from 'js/library/utils/API/Store/apiUpdateStore';
import { updateHasPromo } from 'js/library/utils/API/Store/apiUpdateHasPromo';

export const updateStoreBusinessPartnerAction = async ({
  id,
  isPublished,
  level,
  keyWordsSet,
  keywordsSetConsumptionList,
  messageBlockedKw,
  tags,
  detailsTitle,
  detailsDescription,
  title,
  description,
  establishmentId,
  establishmentName,
  lat,
  long,
  enableDate,
  disableDate,
  url,
  photo,
  thumbnail,
  type,
  region,
  droidWebView,
  iOSWebView,
  thumbnailFile,
  photoFile,
  buttonLabel,
  buttonDescription,
  showButtonDescription,
  goToPlaceLabel,
  showGoToPlace,
  photoListFile,
  campanhaTriibo,
  checkoutCode,
}) => {
  let newPromotion = id ? false : true;

  id = id !== null ? id : firebaseDatabase.ref('Store').push().key;

  //convertendo a data para milisegundos
  enableDate = enableDate !== null ? convertToMillis(enableDate) : null;
  disableDate = disableDate !== null ? convertToMillis(disableDate) : null;

  //checando a prioridade da região
  region = region === 'local' ? null : region;

  const currentDate = Date.now();

  //nomeando corretamente e convertendo as imagens
  if (thumbnailFile !== null) {
    thumbnail = 'storage:' + id + ':thumbnail:' + currentDate;

    if (thumbnailFile.indexOf('https') === 0) {
      thumbnailFile = await downloadImageAsBlob(thumbnailFile);
    } else {
      thumbnailFile = b64toBlob(thumbnailFile);
    }
  }

  if (photoFile !== null) {
    photo = 'storage:' + id + ':photo:' + currentDate;

    if (photoFile.indexOf('https') === 0) {
      photoFile = await downloadImageAsBlob(photoFile);
    } else {
      photoFile = b64toBlob(photoFile);
    }
  }

  let photoList = [];
  if (photoListFile !== null) {
    photoListFile.map(async (item, i) => {
      photoList.push('gal' + i + '_' + id + ':' + currentDate);

      if (item.indexOf('https') === 0) {
        photoListFile[i] = await downloadImageAsBlob(item);
      } else {
        photoListFile[i] = b64toBlob(item);
      }
    });
  }

  //tratamento de string
  if (buttonLabel === '') buttonLabel = null;
  if (buttonDescription === '') buttonDescription = null;
  if (goToPlaceLabel === '') goToPlaceLabel = null;

  const dataStore = {
    id,
    isPublished,
    level: parseInt(level, 10),
    keyWordsSet,
    keyWordsSetConsumption: keywordsSetConsumptionList,
    message: messageBlockedKw,
    tags,
    detailsTitle,
    detailsDescription,
    title,
    description,
    establishmentId,
    establishmentName,
    lat: lat ?? 0,
    long: long ?? 0,
    enableDate,
    disableDate,
    dueDate: disableDate,
    url,
    photo,
    thumbnail,
    photoList: photoList ?? '',
    type,
    region: region ?? '',
    droidWebView,
    iOSWebView,
    buttonLabel: buttonLabel ?? '',
    buttonDescription: buttonDescription ?? '',
    showButtonDescription,
    goToPlaceLabel: goToPlaceLabel ?? '',
    showGoToPlace,
    campanhaTriibo,
    checkoutCode,
  };

  const storageFolder = 'promocao';

  const userInfo = getUserInfo();
  const uId = userInfo?.uId;

  if (newPromotion) {
    const resultCreateStore = await createStore(uId, id, dataStore);
  } else {
    const resultUpdate = await updateStore(id, dataStore);
  }

  updateHasPromo(establishmentId);
  uploadImage(storageFolder, photoListFile, photoList);
  uploadImage(storageFolder, thumbnailFile, thumbnail);
  uploadImage(storageFolder, photoFile, photo);
};

