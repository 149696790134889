import React from 'react';
import { Link } from 'react-router-dom';
import * as S from './styles';

import { StyledButton } from 'js/components/Configurations/AdminFunctions/styledButton';

import { HeaderTitle } from 'js/components/Configurations/CreateClub/RelationshipClub/HeaderTitle';
import { GoBackHeader } from 'js/components/Configurations/CreateClub/RelationshipClub/GoBackHeader';
import { CardButton } from 'js/components/DesignSystem/Buttons/CardButton';

const Promote = (props) => {
  console.log(props.match.params.id);
  return (
    <div style={{ padding: '4rem 32px' }}>
      <GoBackHeader />
      <S.Container>
        <HeaderTitle pageInfo="Promover" />
        <S.Grid container spacing={3}>
          <StyledButton
            url={`/estabelecimento/${props.match.params.id}/marketing`}
            value={'Geração de materiais'}
            index={0}
          />
          <StyledButton
            url={{
              pathname: `/estabelecimento/${props.match.params.id}/promover/links-uteis`,
              state: props.location.state,
            }}
            value={'Adicionar links úteis'}
            index={1}
          />
          <StyledButton
            url={`/estabelecimento/relacionamento/lista-comunicacoes/${props.match.params.id}`}
            value={'Enviar comunicação'}
            index={2}
          />
          <StyledButton
            url={`/estabelecimento/relacionamento/lista-postagem/${props.match.params.id}`}
            value={'Enviar postagem'}
            index={3}
          />
        </S.Grid>
      </S.Container>
    </div>
  );
};

export default Promote;
