import React from 'react';
import { Button } from '@mui/material';

export const CardButton = ({ children, block = false, newcolor, handleClick = null }) => {
  return (
    <Button
      disabled={block}
      onClick={handleClick}
      variant="contained"
      sx={{
        border: '0.5px solid #70707024',
        opacity: 1,
        borderLeft: `9px solid ${newcolor} !important`,
        boxShadow: '0px 3px 6px #00000029',
        width: '100%',
        height: '50px',
        background: '#ffffff',
        borderRadius: '10px',
        color: '#383838',
        fontSize: '18px',
        margin: '0px',
        padding: '10px',
        textTransform: 'none',
        '&:hover': {
          color: '#ffffff',
          transition: '0.3s',
          background: `${newcolor} !important`,
        },
      }}
    >
      {children}
    </Button>
  );
};
