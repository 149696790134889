import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  FormControl,
  CircularProgress,
  Typography,
  IconButton,
  FormHelperText,
  Popover,
} from '@mui/material';
import { AddPhotoAlternate, Send } from '@mui/icons-material';
import { Question } from 'phosphor-react';
import { toast } from 'react-toastify';

import csvModel from '../../../../styles/assets/sendingPoints/modelo-envio-pontos.csv';
import xlsModel from '../../../../styles/assets/sendingPoints/modelo-envio-pontos.xls';

import { separateBase64String, blobToBase64 } from 'js/library/utils/helpers';
import { uploadFiles } from 'js/library/utils/API/apiUploadFiles';
import { pointsExchangePartnerFiles } from 'js/library/utils/API/Points/pointsExchangePartnerFiles';

export default function SendByFile({ history, clubId, establishmentId, fromWhere }) {
  const [eventDescription, setEventDescription] = useState('');
  const [clientList, setClientList] = useState(null);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const fileChangedHandler = (event) => {
    if (event.target.files.length !== 0) {
      setClientList(event.target.files[0]);
    }
  };

  const handleFileButtonClick = () => {
    document.getElementById('thumbnailStore').click();
  };

  const openHelper = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const uploadingFile = async (file) => {
    const newFile = separateBase64String(await blobToBase64(file));
    try {
      const res = await uploadFiles('points-exchanges', {
        mimeType: newFile.mimeType,
        fileName: file.type === 'text/plain' ? 'usuarios.txt' : 'usuarios.csv',
        buffer: newFile.buffer,
      });
      const response = res.split('/');
      return 'points-exchanges/' + response[response.length - 1].split('?')[0];
    } catch {
      throw new Error('Erro ao fazer upload do arquivo');
    }
  };

  const addEventByFile = async (event) => {
    event.preventDefault();

    const getIdAndType = () => {
      if (clubId) return { type: 'clubId', id: clubId };
      if (establishmentId) return { type: 'establishmentId', id: establishmentId };
      return {};
    };

    const { type, id } = getIdAndType();

    const validateForm = () => {
      if (!eventDescription) return 'A descrição é obrigatória!';
      if (!clientList) return 'Insira o arquivo com os uId dos usuários.';
      return null;
    };

    const validationError = validateForm();
    if (validationError) {
      toast.error(validationError, { autoClose: 2500 });
      return;
    }

    try {
      setLoadingAdd(true);

      const path = await uploadingFile(clientList);
      const additionalPayload =
        fromWhere === 'adminConfig' ? { establishmentId: '-TriiboWifi' } : {};

      await pointsExchangePartnerFiles({
        filePath: path,
        description: eventDescription,
        ...additionalPayload,
        [type]: id,
      });

      toast.success('Pontos enviados com sucesso!');
      setTimeout(() => {
        setLoadingAdd(false);
        history.goBack();
      }, 2000);
    } catch (error) {
      const errorMessage = error.response?.data?.error || 'Ocorreu um erro inesperado.';
      toast.error(errorMessage, { autoClose: 2500 });
      setLoadingAdd(false);
    }
  };

  return (
    <FormControl fullWidth margin="normal">
      <Box mt={2}>
        <Typography variant="subtitle1">
          Carregue um arquivo nos formatos .xls ou .csv para enviar pontos aos membros do seu clube.
          Para simplificar, baixe o template disponível, edite-o conforme necessário seguindo as
          orientações e faça o upload do arquivo no sistema.
        </Typography>
      </Box>

      <Box display="flex" alignItems="center" gap={2} mt={2}>
        <Typography variant="subtitle1">
          <a href={xlsModel} download>
            baixar template .xls
          </a>{' '}
          |{' '}
          <a href={csvModel} download>
            baixar template .csv
          </a>
        </Typography>

        <IconButton onClick={openHelper} color="secondary">
          <Question size={30} weight="fill" />
        </IconButton>
      </Box>

      <Box display="flex" alignItems="center" mt={2}>
        <input
          id="thumbnailStore"
          accept=".txt, .csv, .xls"
          style={{ display: 'none' }}
          type="file"
          onChange={fileChangedHandler}
        />

        <Button
          variant="contained"
          color="secondary"
          startIcon={<AddPhotoAlternate />}
          onClick={handleFileButtonClick}
          sx={{ textTransform: 'capitalize', fontWeight: 550 }}
        >
          Adicionar Arquivo
        </Button>

        {clientList && (
          <Box ml={2} fontSize="14px">
            <strong>Arquivo selecionado: </strong>
            {clientList.name}
          </Box>
        )}
      </Box>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Box p={2} maxWidth={360}>
          <Typography variant="body1" gutterBottom>
            • O arquivo deve ser enviado sem cabeçalho.
          </Typography>
          <Typography variant="body1">• As colunas devem estar na seguinte ordem:</Typography>
          <Typography variant="body2">1. Chave de Identificação do Usuário</Typography>
          <Typography variant="body2">2. Motivo do Envio (máx. 80 caracteres)</Typography>
          <Typography variant="body2">3. Data do Evento (AAAA-MM-DD)</Typography>
          <Typography variant="body2">4. Valor (apenas números inteiros)</Typography>
        </Box>
      </Popover>

      <TextField
        fullWidth
        required
        multiline
        label="Nome do envio"
        value={eventDescription}
        onChange={(e) => setEventDescription(e.target.value)}
        margin="normal"
        aria-describedby="eventDescription-helper"
      />
      <FormHelperText id="eventDescription-helper">
        *O texto acima será exibido no relatório de envios. Utilize um texto único e identificável
        para facilitar a organização e o acompanhamento.
      </FormHelperText>

      <Button
        variant="contained"
        color="primary"
        type="submit"
        startIcon={!loadingAdd && <Send />}
        disabled={loadingAdd || !clientList || !eventDescription}
        onClick={addEventByFile}
        sx={{ mt: 6, textTransform: 'capitalize', fontWeight: 550 }}
      >
        {loadingAdd ? (
          <CircularProgress size={25} thickness={6} sx={{ color: '#fff' }} />
        ) : (
          'Enviar'
        )}
      </Button>
    </FormControl>
  );
}
