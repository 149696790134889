import axios from 'axios';

import authToken from 'js/library/utils/API/authToken';
import cfac22 from '../../cfac22';

export function checkUserPartnerCellphone(partnerId, cellPhone) {
  return new Promise(async (resolve, reject) => {
    authToken().then((token) => {
      const config = {
        method: 'post',
        url: `${cfac22('API_HOST_V2')}/userPartner/checkUserPartnerCellphone`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: {
          cellphone: cellPhone,
          partnerId: partnerId,
        },
      };

      axios(config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  });
}
