import React from 'react';
import * as S from './styles';
import { Typography, Button, Paper } from '@mui/material';
import { ChevronRight, ExpandMore, Stop } from '@mui/icons-material';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { RecursiveItem } from './RecursiveItem';
import trashIcon from '../../../../styles/assets/SearchUser/trash.svg';

export function PrintUser({
  user,
  count,
  title,
  setPartnerSellected,
  setPartnerNameSellected,
  setIsModalDeleteUserOpen,
}) {
  const name = user.partnerName?.trim() || user.name?.trim() || '';
  const fileName = `${name}${title?.trim() || 'arquivo'}${
    count !== null ? `_${count + 1}` : ''
  }.json`;

  return (
    <S.PrintUserBox key={user.name + title + count}>
      <Paper
        style={{
          borderRadius: '10px',
          boxShadow: 'none',
        }}
      >
        <Typography variant="h5" align="center">
          {title}
        </Typography>
        <br />
        <TreeView
          defaultExpandIcon={<ChevronRight />}
          defaultCollapseIcon={<ExpandMore />}
          defaultEndIcon={<Stop style={{ fontSize: '10px' }} />}
        >
          {RecursiveItem(user, '')}
        </TreeView>
        <div className="button">
          <div></div>

          {title === 'Triibo' ? (
            <>
              <Button
                href={
                  'data:text/json;charset=utf-8,' +
                  encodeURIComponent(JSON.stringify(user, null, 4))
                }
                download={
                  (user.partnerName
                    ? user.partnerName !== null && user.partnerName !== undefined
                      ? user.partnerName.replace(/\s/, '')
                      : ''
                    : user.name !== null && user.name !== undefined
                    ? user.name.replace(/\s/, '')
                    : '') +
                  title.replace(/\s/, '') +
                  (count === null ? '' : '_' + (count + 1)) +
                  '.json'
                }
                align="center"
                variant="contained"
                color="primary"
              >
                Baixar JSON
              </Button>
              <S.DeleteButton
                onClick={() => {
                  setPartnerNameSellected(title);
                  setPartnerSellected(user);
                  setIsModalDeleteUserOpen(true);
                }}
              >
                <img src={trashIcon} alt="" />
                <Typography variant="h6" component="span">
                  Excluir
                </Typography>
              </S.DeleteButton>
            </>
          ) : (
            user.status === 'AuthorizedAndRegistered' && (
              <>
                <Button
                  href={
                    'data:text/json;charset=utf-8,' +
                    encodeURIComponent(JSON.stringify(user, null, 4))
                  }
                  download={fileName}
                  align="center"
                  variant="contained"
                  color="primary"
                >
                  Baixar JSON
                </Button>

                <S.DeleteButton
                  onClick={() => {
                    setPartnerNameSellected(title);
                    setPartnerSellected(user);
                    setIsModalDeleteUserOpen(true);
                  }}
                >
                  <img src={trashIcon} alt="" />
                  <Typography variant="h6" component="span">
                    Excluir
                  </Typography>
                </S.DeleteButton>
              </>
            )
          )}
          {user.status === 'ExceptionList' && user.hasOwnProperty('partnerId') && (
            <>
              <Button
                href={
                  'data:text/json;charset=utf-8,' +
                  encodeURIComponent(JSON.stringify(user, null, 4))
                }
                download={
                  (user.partnerName
                    ? user.partnerName !== null && user.partnerName !== undefined
                      ? user.partnerName.replace(/\s/, '')
                      : ''
                    : user.name !== null && user.name !== undefined
                    ? user.name.replace(/\s/, '')
                    : '') +
                  title.replace(/\s/, '') +
                  (count === null ? '' : '_' + (count + 1)) +
                  '.json'
                }
                align="center"
                variant="contained"
                color="primary"
              >
                Baixar JSON
              </Button>

              <S.DeleteButton
                onClick={() => {
                  setPartnerNameSellected(title);
                  setPartnerSellected(user);
                  setIsModalDeleteUserOpen(true);
                }}
              >
                <img src={trashIcon} alt="" />
                <Typography variant="h6" component="span">
                  Excluir
                </Typography>
              </S.DeleteButton>
            </>
          )}
        </div>
      </Paper>
    </S.PrintUserBox>
  );
}

