import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as D from 'js/components/DesignSystem/styles';
import * as S from './styles';
import { BsXLg } from 'react-icons/bs';
import axios from 'axios';
import { compareDatesFromStr } from 'js/library/utils/helpers';
import { geocoding } from 'js/library/utils/API/apiGeocoding';
import EditTimesTableModal from './EditTimesTableModal';
import { toast } from 'react-toastify';
import { Switch, FormControlLabel, Typography, CircularProgress } from '@mui/material';
import phoneMaskArray from 'js/components/DesignSystem/phoneMaskArray.json';

const StepOne = (props) => {
  const history = useHistory();
  const {
    formState,
    setFormState,
    setCurrentScreen,
    progress,
    setProgress,
    editPage,
    setDisabledButton,
  } = props;

  const [editModal, setEditModal] = useState(false);
  const [enderecoModel, setEnderecoModel] = useState('');
  const [tagCapture, setTagCapture] = useState('');
  const [auxiliarAddress, setAuxiliarAddress] = useState(false);
  const [loadingAddress, setLoadingAddress] = useState(false);
  const disableDateField = new Date(formState.disableDate);
  const [countryID, setCountryID] = useState(0);
  const [oldDataAdress] = useState({ ...formState });
  const [logradouroSwitch, setLogradouroSwitch] = useState(formState.endereco !== 'Brasil');

  const weekArr = ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Domingo'];

  useEffect(() => {
    if (formState.cep === ' ' || formState.cep === '') {
      setLogradouroSwitch(false);
    }
  }, []);

  const handleTagCapture = (e) => {
    setDisabledButton && setDisabledButton(false);
    setTagCapture(e.target.value);
  };
  const handlePressEnter = (e) => {
    setDisabledButton && setDisabledButton(false);
    if (e.key === 'Enter') {
      e.preventDefault();
      handlePushTag();
    }
  };
  const handlePushTag = (e) => {
    setDisabledButton && setDisabledButton(false);
    let tagHolder = formState.tags;
    if (tagHolder.indexOf(tagCapture.toLocaleLowerCase()) === -1 && tagCapture.length !== 0) {
      tagHolder.push(tagCapture.toLowerCase());
    }
    setFormState({
      ...formState,
      tags: [...tagHolder],
    });
    setTagCapture('');
  };
  const handleNameChange = (e) => {
    setDisabledButton && setDisabledButton(false);
    setFormState({
      ...formState,
      nome: e.target.value,
    });
  };
  const handleDescChange = (e) => {
    setDisabledButton && setDisabledButton(false);
    setFormState({
      ...formState,
      descricao: e.target.value,
    });
  };

  const handleSwitchChange = () => {
    setDisabledButton && setDisabledButton(false);
    if (logradouroSwitch) {
      setFormState({
        ...formState,
        endereco: 'Brasil',
        logradouro: '',
        numero: '',
        bairro: '',
        cidade: '',
        estado: '',
        cep: '',
      });
    } else {
      setFormState({
        ...formState,
        endereco: oldDataAdress.endereco,
        logradouro: oldDataAdress.logradouro,
        numero: oldDataAdress.numero,
        bairro: oldDataAdress.bairro,
        cidade: oldDataAdress.cidade,
        estado: oldDataAdress.estado,
        cep: oldDataAdress.cep,
      });
    }
    setLogradouroSwitch(!logradouroSwitch);
  };

  const handleZIPChange = (e) => {
    setDisabledButton && setDisabledButton(false);
    if (e.target.value.length <= 9) {
      setFormState({
        ...formState,
        cep: e.target.value.replace(/[a-z]|[A-Z]|-/g, '').slice(0, 8),
      });
      if (e.target.value.replace(/[a-z]|[A-Z]|-/g, '').slice(0, 8).length === 8) {
        setLoadingAddress(true);
        const newCep = e.target.value.replace(/[a-z]|[A-Z]|-/g, '').slice(0, 8);
        axios
          .get(`https://viacep.com.br/ws/${newCep}/json/`)
          .then((response) => {
            if (response.data.erro) {
              setLoadingAddress(false);
              toast.error('CEP Inválido!');
            } else {
              response = response.data;
              setLoadingAddress(false);
              return setFormState({
                ...formState,
                cep: newCep,
                logradouro: response.logradouro,
                bairro: response.bairro,
                cidade: response.localidade,
                estado: response.uf,
              });
            }
          })
          .catch(() => {
            setLoadingAddress(false);
            toast.error('CEP Inválido!');
          });
      }
    }
  };
  const handleStreetChange = (e) => {
    setDisabledButton && setDisabledButton(false);
    setFormState({
      ...formState,
      logradouro: e.target.value,
    });
  };
  const handleNumeroChange = (e) => {
    setDisabledButton && setDisabledButton(false);
    setFormState({
      ...formState,
      numero: e.target.value,
    });
  };
  const handleComplementoChange = (e) => {
    setDisabledButton && setDisabledButton(false);
    setFormState({
      ...formState,
      complemento: e.target.value,
    });
  };
  const handleBairroChange = (e) => {
    setDisabledButton && setDisabledButton(false);
    setFormState({
      ...formState,
      bairro: e.target.value,
    });
  };
  const handleCityChange = (e) => {
    setDisabledButton && setDisabledButton(false);
    setFormState({
      ...formState,
      cidade: e.target.value,
    });
  };
  const handleStateChange = (e) => {
    setDisabledButton && setDisabledButton(false);
    setFormState({
      ...formState,
      estado: e.target.value,
    });
  };
  const handlePhoneChange = (e) => {
    setDisabledButton && setDisabledButton(false);
    setFormState({
      ...formState,
      contatos: [e.target.value],
    });
  };
  const handleDateChange = (e) => {
    setDisabledButton && setDisabledButton(false);
    setFormState({
      ...formState,
      disableDate: new Date(e.target.value).getTime() + 1000 * 60 * 60 * 3,
    });
  };
  const handleEmailChange = (e) => {
    setDisabledButton && setDisabledButton(false);
    setFormState({
      ...formState,
      email: e.target.value.toLowerCase(),
    });
  };
  const handleWebsiteChange = (e) => {
    setDisabledButton && setDisabledButton(false);
    setFormState({
      ...formState,
      site: e.target.value.toLowerCase(),
    });
  };
  const searchLatLong = (address) => {
    geocoding(encodeURIComponent(address))
      .then((result) => {
        if (
          result.results[0].geometry.location.lat !== formState.lat ||
          result.results[0].geometry.location.lng !== formState.long
        ) {
          if (result.status === 'OK' && result.results.length > 0) {
            setFormState({
              ...formState,
              lat: result.results[0].geometry.location.lat,
              long: result.results[0].geometry.location.lng,
            });
          } else {
            setFormState({
              ...formState,
              lat: 0,
              long: 0,
            });
          }
        }
      })
      .catch((error) => {
        setFormState({
          ...formState,
          lat: 0,
          long: 0,
        });
      });
  };

  useEffect(() => {
    validateAddressFields();
  }, []);

  let latLongTimeout;

  useEffect(() => {
    if (
      !formState.rua &&
      !formState.logradouro &&
      !formState.numero &&
      !formState.bairro &&
      !formState.cep
    ) {
      return;
    }

    if (
      formState.endereco !==
      `${formState.logradouro}, ${formState.numero} - ${formState.bairro}, ${formState.cidade} - ${formState.estado}, ${formState.cep}, Brasil`
    ) {
      setFormState({
        ...formState,
        endereco: `${formState.logradouro}, ${formState.numero} - ${formState.bairro}, ${formState.cidade} - ${formState.estado}, ${formState.cep}, Brasil`,
      });
    }

    if (
      formState.logradouro.length > 3 &&
      // formState.numero.length > 0 &&
      // formState.bairro.length > 3 &&
      formState.cidade.length > 3 &&
      formState.estado.length > 1 &&
      formState.cep.length > 7
    ) {
      latLongTimeout = setTimeout(() => {
        searchLatLong(
          `${formState.logradouro} ${formState.cidade} ${formState.estado} ${formState.cep}`
        );
      }, 5000);
    }
    return () => {
      clearTimeout(latLongTimeout);
    };
  }, [formState]);

  //serve para controlar a barra de progresso da etapa
  useEffect(() => {
    // const hasCep = formState.cep.length > 7 ? 6 : 0;
    // const hasRua = formState.logradouro.length > 3 ? 4 : 0;
    // const hasNumero = formState.numero.length !== 0 ? 5 : 0;
    // const hasBairro = formState.bairro.length > 3 ? 10 : 0;
    // const hasCidade = formState.cidade.length > 2 ? 5 : 0;
    // const hasState = formState.estado.length !== 0 ? 5 : 0;

    const hasNome = formState.nome.length > 1 ? 20 : 0;

    // const hasDesc = formState.descricao.length > 4 ? 20 : 0;
    const hasDesc = formState.descricao.length > 4 ? (logradouroSwitch ? 10 : 20) : 0;

    let hasCep = 0;
    if (!logradouroSwitch || formState.cep.length > 7) {
      hasCep = 10;
    }

    let hasRua = 0;
    if (!logradouroSwitch || formState.logradouro.length > 3) {
      hasRua = 10;
    }

    const hasTag =
      formState.tags.length !== 0
        ? logradouroSwitch
          ? 10 // Se logradouroSwitch for true e tags.length diferente de 0
          : 20 // Se logradouroSwitch for false e tags.length diferente de 0
        : 0; // Se tags.length for igual a 0

    const hasPhone = formState.contatos.length !== 0 ? 20 : 0;
    const hasEmail = formState.email.length > 3 ? 15 : 0;
    let hasValidDates = 5;
    for (let i = 0; formState.funcionamento.length > i; i++) {
      if (
        compareDatesFromStr(
          formState.funcionamento[i].horarioFim,
          formState.funcionamento[i].horarioInicio
        )
      ) {
        hasValidDates = 0;
      }
    }

    setProgress(
      0 + hasNome + hasDesc + hasCep + hasRua + hasTag + hasPhone + hasEmail + hasValidDates
    );
  }, [formState]);

  const validateAddressFields = () => {
    setEnderecoModel(formState.endereco);
    const addressFields = ['bairro', 'cep', 'cidade', 'estado', 'logradouro'];
    const hasAllKeys = addressFields.every((field) => formState[field]);

    if (formState.endereco !== 'Brasil') {
      setLogradouroSwitch(true);
      if (!hasAllKeys && window.location.pathname !== '/estabelecimento/novo/adicionar') {
        setAuxiliarAddress(true);
      }
    }
  };

  return (
    <>
      <EditTimesTableModal
        open={editModal}
        setOpen={setEditModal}
        formState={formState}
        setFormState={setFormState}
        setDisabledButton={setDisabledButton && setDisabledButton}
      />
      <S.MainContainer>
        <S.CardContainer id="card-01">
          <S.FormElement>
            <S.FormLabel>Nome do negócio*</S.FormLabel>
            <D.TriiboTextField
              disabled={formState.status && formState.status !== 'aberto'}
              rows={1}
              placeholder={'Nome do negócio*'}
              maxLength="40"
              value={formState.nome}
              onChange={(e) => handleNameChange(e)}
            />
          </S.FormElement>
          <S.FormElement>
            <S.FormLabel>Descrição do negócio*</S.FormLabel>
            <S.TextCountContainer>
              <D.TriiboTextField
                disabled={formState.status && formState.status !== 'aberto'}
                rows={13}
                placeholder={'Descrição do negócio*'}
                maxLength="600"
                value={formState.descricao}
                onChange={(e) => handleDescChange(e)}
              />
              <S.TextCounter>
                {formState.descricao.length} {'/600'}
              </S.TextCounter>
            </S.TextCountContainer>
          </S.FormElement>
        </S.CardContainer>
        <S.CardContainer white={true}>
          <S.OpenTimesLabels>Horário de funcionamento</S.OpenTimesLabels>
          <S.TimesContainer>
            {formState.funcionamento.map(({ ativo, horarioFim, horarioInicio }, index) => (
              <span key={'funcionamento' + index}>
                <S.OpenTimeElements
                  open={ativo ? true : false}
                  invalid={compareDatesFromStr(horarioFim, horarioInicio)}
                  key={index}
                >
                  <S.OpenTimeFields>{weekArr[index]}</S.OpenTimeFields>
                  <S.OpenTimeFields activity={true}>
                    <S.TextJustifier>
                      <S.OpenBullet
                        open={ativo ? true : false}
                        invalid={compareDatesFromStr(horarioFim, horarioInicio)}
                      />
                      {ativo ? <span>Aberto</span> : <span>Fechado</span>}
                    </S.TextJustifier>
                  </S.OpenTimeFields>
                  <S.OpenTimeFields times={true}>
                    {horarioInicio === '00:00' && horarioFim === '23:59' ? (
                      <span>{'24h'}</span>
                    ) : (
                      <span style={{ width: '5rem' }}>
                        {horarioInicio ? horarioInicio : '09:00'}
                        {' às '}
                        {horarioFim ? horarioFim : '18:00'}
                      </span>
                    )}
                  </S.OpenTimeFields>
                </S.OpenTimeElements>
                <S.DividerContainer>
                  <S.Divider />
                </S.DividerContainer>
              </span>
            ))}
          </S.TimesContainer>
          <S.EditTimesButton
            onClick={() => {
              setEditModal(true);
            }}
          >
            Editar horários de funcionamento
          </S.EditTimesButton>
        </S.CardContainer>

        <S.CardContainer id="card-03">
          {auxiliarAddress && (
            <div>
              <Typography
                variant="h6"
                color="primary"
                style={{ color: 'white', fontSize: 15, fontWeight: 900 }}
              >
                Endereço mal formatado. Use o modelo abaixo para corrigir
              </Typography>
              <Typography
                variant="h6"
                style={{ backgroundColor: 'white', fontSize: 15, padding: 5 }}
              >
                {enderecoModel}
              </Typography>
            </div>
          )}

          <div style={{ color: 'white', marginRight: '50%' }}>
            <FormControlLabel
              control={
                <Switch
                  disabled={formState.status && formState.status !== 'aberto'}
                  checked={logradouroSwitch}
                  onChange={handleSwitchChange}
                />
              }
              label="Possui endereço*"
            />
          </div>

          {logradouroSwitch ? (
            loadingAddress ? (
              <CircularProgress />
            ) : (
              <>
                <S.FormElement>
                  <S.FormLabel>CEP</S.FormLabel>
                  <D.TriiboTextField
                    disabled={formState.status && formState.status !== 'aberto'}
                    rows={1}
                    placeholder={'CEP'}
                    value={formState.cep}
                    onChange={(e) => handleZIPChange(e)}
                  />
                </S.FormElement>
                <S.FormElement>
                  <S.FormLabel>Rua</S.FormLabel>
                  <D.TriiboTextField
                    disabled={formState.status && formState.status !== 'aberto'}
                    rows={1}
                    placeholder={'Rua'}
                    value={formState.logradouro}
                    onChange={(e) => handleStreetChange(e)}
                  />
                </S.FormElement>
                <S.Logradouro>
                  <S.FormElement>
                    <S.FormLabel>número</S.FormLabel>
                    <D.TriiboTextField
                      disabled={formState.status && formState.status !== 'aberto'}
                      rows={1}
                      placeholder={'999'}
                      value={formState.numero}
                      onChange={(e) => handleNumeroChange(e)}
                    />
                  </S.FormElement>
                  <S.FormElement>
                    <S.FormLabel>Complemento</S.FormLabel>
                    <D.TriiboTextField
                      disabled={formState.status && formState.status !== 'aberto'}
                      rows={1}
                      placeholder={'Complemento'}
                      value={formState.complemento}
                      onChange={(e) => handleComplementoChange(e)}
                    />
                  </S.FormElement>
                </S.Logradouro>
                <S.FormElement>
                  <S.FormLabel>Bairro</S.FormLabel>
                  <D.TriiboTextField
                    disabled={formState.status && formState.status !== 'aberto'}
                    rows={1}
                    placeholder={'Bairro'}
                    value={formState.bairro}
                    onChange={(e) => handleBairroChange(e)}
                  />
                </S.FormElement>
                <S.Logradouro>
                  <S.FormElement>
                    <S.FormLabel>Cidade</S.FormLabel>
                    <D.TriiboTextField
                      disabled={formState.status && formState.status !== 'aberto'}
                      rows={1}
                      placeholder={'Cidade'}
                      value={formState.cidade}
                      onChange={(e) => handleCityChange(e)}
                    />
                  </S.FormElement>
                  <S.FormElement>
                    <S.FormLabel>UF</S.FormLabel>
                    <D.TriiboTextField
                      disabled={formState.status && formState.status !== 'aberto'}
                      rows={1}
                      placeholder={'UF'}
                      value={formState.estado}
                      onChange={(e) => handleStateChange(e)}
                    />
                  </S.FormElement>
                </S.Logradouro>
              </>
            )
          ) : (
            <>
              {/* {!formState.logradouro && ( */}
              <S.FormElement>
                <S.FormLabel>Endereço</S.FormLabel>
                <D.TriiboTextField
                  disabled={formState.status && formState.status !== 'aberto'}
                  rows={1}
                  value={
                    formState.endereco ? formState.endereco.replace(/[-.,;\s]/g, '') : 'Brasil'
                  }
                  readOnly
                />
              </S.FormElement>
              {/* )} */}
            </>
          )}
        </S.CardContainer>

        <S.CardContainer id="card-04" white={true}>
          <S.TagsLabelContainer>
            <S.TagsBigLabel>Tags do seu negócio</S.TagsBigLabel>
            <S.TagsSmallLabel>
              As tags auxiliam na classificação e busca do seu negócio dentro do App. Adicione uma
              palavra por vez.
            </S.TagsSmallLabel>
          </S.TagsLabelContainer>
          <S.TagsContainer>
            <S.TagsAddContainer>
              <D.TriiboTextField
                disabled={formState.status && formState.status !== 'aberto'}
                rows={1}
                placeholder={'Ex.pizza*'}
                value={tagCapture}
                onChange={(e) => {
                  handleTagCapture(e);
                }}
                onKeyDown={(e) => {
                  handlePressEnter(e);
                }}
              />
              <S.TagsAddButton
                disabled={formState.status && formState.status !== 'aberto'}
                blue={true}
                onClick={(e) => {
                  handlePushTag(e);
                }}
              >
                Adicionar tag
              </S.TagsAddButton>
            </S.TagsAddContainer>
            <D.TriiboTagsField>
              <D.TriiboTagsPlaceholder
                $visibility={(formState.tags.length === 0 ? false : true).toString()}
              >
                Digite a tag desejada no campo acima e pressione o botão para adicioná-las
              </D.TriiboTagsPlaceholder>
              {/* <div style={{background: "gray"}}>{weekArr[0]}</div> */}
              {formState.tags.map((body, index) => (
                <span key={'tag' + index}>
                  <D.UserTags key={index}>
                    <D.TagText>{body}</D.TagText>
                    <D.TagDelete
                      onClick={(e) => {
                        e.preventDefault();
                        setDisabledButton(false);
                        let newArr = formState.tags;
                        newArr.splice(index, 1);
                        setFormState({
                          ...formState,
                          tags: [...newArr],
                        });
                      }}
                    >
                      {formState.status && formState.status !== 'aberto' ? (
                        <></>
                      ) : (
                        <BsXLg style={{ color: 'white' }} />
                      )}
                    </D.TagDelete>
                  </D.UserTags>
                </span>
              ))}
            </D.TriiboTagsField>
          </S.TagsContainer>
          <S.FormElement>
            <S.TagsLabelContainer>
              <S.TagsBigLabel>Data de desativação (opcional)</S.TagsBigLabel>
              <S.TagsSmallLabel>
                Defina uma data para que seu negócio não seja mais exibido ao público. Data de
                desativação prevista no momento:{' '}
                <b>
                  {('0' + disableDateField.getDate()).slice(-2) +
                    '/' +
                    ('0' + (disableDateField.getMonth() + 1)).slice(-2) +
                    '/' +
                    disableDateField.getFullYear()}
                </b>
              </S.TagsSmallLabel>
            </S.TagsLabelContainer>
            <D.TriiboDateField
              disabled={formState.status && formState.status !== 'aberto'}
              type="date"
              onChange={(e) => handleDateChange(e)}
            />
          </S.FormElement>
          <S.FormElement>
            <S.FormLabel black={true}>Email de atendimento*</S.FormLabel>
            <D.TriiboTextField
              disabled={formState.status && formState.status !== 'aberto'}
              rows={1}
              placeholder={'Visível apenas para a equipe Triibo.'}
              value={formState.email}
              onChange={(e) => handleEmailChange(e)}
              style={{ textTransform: 'lowercase' }}
            />
          </S.FormElement>
          <S.FormElement>
            <S.FormLabel black={true}>Site</S.FormLabel>
            <D.TriiboTextField
              disabled={formState.status && formState.status !== 'aberto'}
              rows={1}
              placeholder={'https://triibo.com.br'}
              value={formState.site}
              onChange={(e) => handleWebsiteChange(e)}
              style={{ textTransform: 'lowercase' }}
            />
          </S.FormElement>
          <S.FormElement>
            <S.FormLabel black={true}>Telefone Privado*</S.FormLabel>
            <S.TagsSmallLabel>
              Apenas a equipe Triibo terá acesso, será utilizado para suporte
            </S.TagsSmallLabel>
            <S.contactFieldMask>
              <S.PlaceholderContainer>
                <S.ContactCountryField
                  isPhone={true}
                  value={countryID}
                  onChange={(e) => {
                    e.preventDefault();
                    setCountryID(e.target.value);
                  }}
                >
                  {phoneMaskArray.map(({ initials, emoji, ddd, id }, index) => {
                    return (
                      <S.Options key={id} value={id}>
                        {emoji} {initials} {ddd}
                      </S.Options>
                    );
                  })}
                </S.ContactCountryField>
                <div style={{ padding: '0 5px' }}></div>
                <S.ContactTextField
                  value={
                    formState.contatos.length > 0 ? formState.contatos[0].replace('+55', '') : ''
                  }
                  onChange={(e) => handlePhoneChange(e)}
                  mask={phoneMaskArray.filter((e) => e.id === parseInt(countryID))[0].mask}
                />
              </S.PlaceholderContainer>
            </S.contactFieldMask>
          </S.FormElement>
        </S.CardContainer>
      </S.MainContainer>
      {!editPage ? (
        <S.ButtonContainer>
          <D.TriiboWhiteButton
            onClick={() => {
              history.goBack();
            }}
            blue={true}
          >
            Cancelar
          </D.TriiboWhiteButton>

          <D.TriiboFilledButton
            onClick={() => {
              if (progress < 100) {
                toast.error(`Os seguintes campos ainda precisam ser preenchidos: 
                    ${
                      formState.nome.length < 2
                        ? 'NOME DO NEGÓCIO PRECISA TER NO MÍNIMO 2 CARACTERES,'
                        : ''
                    }
                    ${
                      formState.descricao.length < 5
                        ? 'DESCRIÇÃO DO NEGÓCIO PRECISA TER NO MÍNIMO 5 CARACTERES,'
                        : ''
                    }
                    ${
                      logradouroSwitch && formState.cep.length < 8
                        ? 'CEP PRECISA TER NO MÍNIMO 8 CARACTERES,'
                        : ''
                    }
                    ${
                      logradouroSwitch && formState.logradouro.length < 3
                        ? 'RUA PRECISA TER NO MÍNIMO 3 CARACTERES,'
                        : ''
                    }
                    ${!formState.tags[0] ? 'TAGS DO SEU NEGÓCIO,' : ''}
                    ${!formState.email ? 'EMAIL DO ATENDIMENTO,' : ''}
                    ${!formState.contatos[0] ? 'TELEFONE PRIVADO,' : ''}
                    `);
                return;
              }
              setCurrentScreen(1);
            }}
            blue={true}
          >
            Próxima etapa
          </D.TriiboFilledButton>
        </S.ButtonContainer>
      ) : (
        <></>
      )}
    </>
  );
};

export default StepOne;

