import React, { useState, useEffect, useCallback } from 'react';
import { Redirect } from 'react-router-dom';
import 'react-responsive-ui/style.css';

import { withStyles } from '@mui/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  Chip,
  Paper,
  Button,
  FormLabel,
  MenuItem,
  FormControl,
  Select,
  Typography,
  TextField,
  FormControlLabel,
  Switch,
  CircularProgress,
} from '@mui/material';

import { Save, Delete, ExpandMore, ArrowBack } from '@mui/icons-material';
import { Autocomplete } from '@mui/lab';

import Loading from 'js/containers/Loading/Loading';
import Downshift from 'downshift';
import PropTypes from 'prop-types';
import { getKeywords } from 'js/library/utils/API/SearchUser/apiGetKeywords';
import { getCardByTitle } from 'js/library/utils/API/getCardByTitle';
import { findStoreByProximity } from 'js/library/utils/API/Store/findStoreByProximity';
import { apiListEstablishment } from 'js/library/utils/API/Establishment/apiListEstablishments';
import { firebaseDatabase } from 'js/library/utils/firebaseUtils';

import { days } from 'js/library/utils/helpers';

import axios from 'axios';
import apiQRCode from 'js/library/utils/API/apiQRCode';
import { geocoding } from 'js/library/utils/API/apiGeocoding';

import { getUserInfo } from 'js/library/utils/helpers';
import QRCode from 'qrcode.react';
import { HeaderTitle } from './CreateClub/RelationshipClub/HeaderTitle';
import { toast } from 'react-toastify';
import { getAllOrgs } from 'js/library/utils/API/Org/apiGetAllOrgs';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getStore } from 'js/library/utils/API/getStore';
import { getCardById } from 'js/library/utils/API/getCardById';
import { getEstablishment } from 'js/library/utils/API/getEstablishment';

function renderInput(inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps;

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
    />
  );
}

const app_screen_list = [
  { type: 'Home', value: 'screen_home' },
  { type: 'Carteira', value: 'screen_wallet' },
  { type: 'Extrato', value: 'screen_statement' },
  { type: 'Perfil', value: 'screen_profile' },
  { type: 'Notificações', value: 'screen_notifications' },
  { type: 'Chat', value: 'screen_chat' },
  { type: 'Comunidades', value: 'screen_recommended_orgs_list' },
];

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex, selectedItem }) {
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || '').indexOf(suggestion) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion + index}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion}
    </MenuItem>
  );
}

renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};

const styles = (theme) => ({
  root: {
    background: '#ffffff',
    borderRadius: 5,
    border: '1px solid #6c6c6c',
    color: 'white',
  },
});

const QRCodeInformation = (props) => {
  const uid = getUserInfo().uId;

  const [isLinkGenerated, setIsLinkGenerated] = useState(false);
  const getLink = useCallback(
    (id) => {
      if (isLinkGenerated) return;

      setIsLinkGenerated(true);

      const dynamicLinkKey = 'AIzaSyDqzPWDKUb24RGQWGfLyNXsfBTukuwavRc';
      const dynamicLinkApi = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${dynamicLinkKey}`;
      const dynamicLinkDomain = 'mj8vs.app.goo.gl';
      const androidPackageName = 'br.com.sysmobil.triiboclient';
      const iosPackageName = 'br.com.triiboclient';
      const iosStoreId = '1118996431';
      const baseLink = 'https://triibo.com.br';
      const finalLink = `${baseLink}?qrCode=${id}`;

      const linkRequest = {
        dynamicLinkInfo: {
          dynamicLinkDomain,
          link: finalLink,
          androidInfo: { androidPackageName },
          iosInfo: { iosBundleId: iosPackageName, iosAppStoreId: iosStoreId },
        },
      };

      axios
        .post(dynamicLinkApi, linkRequest)
        .then((result) => {
          setForm((prevForm) => ({
            ...prevForm,
            qrCodeShortLink: result.data.shortLink,
          }));
        })
        .catch(() => {
          setIsLinkGenerated(false);
        });
    },
    [isLinkGenerated]
  );

  const state = props.location?.state || {};

  const [form, setForm] = useState({
    oldTitle: state.title || '',
    validatorId: 'qrvalidator@sms,triibo,com,br',
    title: state.title || '',
    action: state.action || 'add_triibo',
    data: state.data
      ? state.action === 'validate_promo'
        ? state.data.split('|')[0]
        : state.data
      : '',
    createDate: state.createDate || Date.now(),
    dueDate: state.dueDate || Date.now() + 86400000,
    keyWordsSet: state.keyWordsSet || [],
    lat: state.lat || 0.0,
    long: state.long || 0.0,
    owner: state.owner || { name: { user: uid } },
    qrCodeId: state.qrCodeId || firebaseDatabase.ref('QRCodes').push().key,
    radius: state.radius || 200,
    availableHours: state.availableHours || [
      { type: '24h', shifts: [] },
      { type: '24h', shifts: [] },
      { type: '24h', shifts: [] },
      { type: '24h', shifts: [] },
      { type: '24h', shifts: [] },
      { type: '24h', shifts: [] },
      { type: '24h', shifts: [] },
    ],
    establishmentList: [],
    productList: [],
    promotionList: [],
    keywordsList: [],
    cardsList: [],
    useGeoInfo: false,
    keywords: '',
    openModal: false,
    active: state.active || false,
    orgList: [],
  });

  const keywords = form.keywords;
  const [loading, setLoading] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [searchApi, setSearchApi] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isDownshiftOpen, setIsDownshiftOpen] = useState(false);
  const pathName = '/admin/configuracoes/qrcodes/editar';
  const history = useHistory();

  const fetchData = async (query = '', from = 0, size = 10) => {
    setLoadingAdd(true);

    const payloadStore = {
      title: query,
      isPublished: '',
      from: from,
      size: size,
    };

    const payloadCard = {
      from: from,
      size: size,
    };

    try {
      switch (form.action) {
        case 'add_triibo':
          const keywordsData = await getKeywords('');
          setForm((prevForm) => ({
            ...prevForm,
            keywordsList: keywordsData,
          }));
          break;

        case 'open_card':
          const cardData = await getCardByTitle(payloadCard, query);
          if (cardData) {
            setForm((prevForm) => ({
              ...prevForm,
              cardsList: cardData,
            }));
          } else {
            setForm((prevForm) => ({
              ...prevForm,
              cardsList: [],
            }));
          }
          break;

        case 'promotion_item':
        case 'validate_promo':
          const storeData = await findStoreByProximity(payloadStore);
          setForm((prevForm) => ({
            ...prevForm,
            promotionList: storeData,
          }));
          break;

        case 'product_info':
          const productData = await findStoreByProximity(payloadStore);
          setForm((prevForm) => ({
            ...prevForm,
            productList: productData,
          }));
          break;
        case 'establishment_info':
          const establishmentData = await apiListEstablishment(query, from, size);
          setForm((prevForm) => ({
            ...prevForm,
            establishmentList: establishmentData,
          }));
          break;
        case 'org_info':
          const orgsData = await getAllOrgs([]);
          setForm((prevForm) => ({
            ...prevForm,
            orgList: orgsData.organizations,
          }));
          break;
        default:
          return '';
      }
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    } finally {
      setLoadingAdd(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [form.action]);

  useEffect(() => {
    if (searchApi.trim() !== '') {
      debouncedSearch(searchApi);
    }
  }, [searchApi]);

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const debouncedSearch = useCallback(
    debounce((value) => {
      fetchData(value, 0, 10);
    }, 500),
    [searchApi.length > 3]
  );

  useEffect(() => {
    const path = window.location.pathname;

    if (path.includes('/editar/')) {
      const fetchSelectedObject = async () => {
        try {
          let selectedObject = null;

          switch (form.action) {
            case 'add_triibo':
              const kw = await getKeywords();
              selectedObject = kw.find((item) => item.value === form.data);
              break;

            case 'open_card':
              if (form.data) {
                const card = await getCardById(form.data);
                selectedObject = card;
                break;
              }
            case 'promotion_item':
            case 'validate_promo':
            case 'product_info':
              if (form.data) {
                const store = await getStore(form.data);
                selectedObject = store;
                break;
              }
            case 'establishment_info':
              if (form.data) {
                const establishment = await getEstablishment(form.data);
                selectedObject = establishment;
                break;
              }
            case 'org_info':
              const orgsData = await getAllOrgs();
              selectedObject = orgsData.organizations.find((org) => org.orgID === form.data);
              break;

            default:
              console.warn('Ação desconhecida:', form.action);
              return;
          }

          if (selectedObject) {
            setForm((prevForm) => ({
              ...prevForm,
              selectedObject,
            }));
          }
        } catch (error) {
          console.error('Erro ao buscar objeto:', error);
        }
      };
      fetchSelectedObject();
    }
  }, [form.data]);

  // Gera Link pro QRCode preview
  getLink(form.qrCodeId);

  window.pathname = null;

  //altera os states dos campos, caso o usuário insira informações nos input
  const handleChange = (name) => (event) => {
    if (name === 'radius' && event.target.value < 0) {
      setForm((prevForm) => ({
        ...prevForm,
        openCheck: true,
      }));
      toast.error('Valor não pode ser negativo.', { autoClose: 2000 });
    } else if (name === 'owner') {
      setForm((prevForm) => {
        const aux = { ...prevForm.owner };
        aux.name.user = event.target.value;
        return { ...prevForm, owner: aux };
      });
    } else if (name === 'cep' && event.target.value.length >= 8) {
      const newValue = event.target.value.replace(/[a-zA-Z-]/g, '');
      axios
        .get(`https://viacep.com.br/ws/${newValue}/json/`)
        .then((response) => {
          response = response.data;
          setForm((prevForm) => ({
            ...prevForm,
            cep: newValue,
            logradouro: response.logradouro,
            bairro: response.bairro,
            cidade: response.localidade,
            estado: response.uf,
          }));
          searchLatLong();
        })
        .catch((error) => {
          console.error('Erro ao buscar CEP:', error);
        });
    } else if (name === 'logradouro' || name === 'numero' || name === 'bairro') {
      setForm((prevForm) => ({
        ...prevForm,
        [name]: event.target.value,
      }));
      searchLatLong();
    } else {
      setForm((prevForm) => ({
        ...prevForm,
        [name]: event.target.value,
      }));
    }
  };

  const handleChangeSwitch = (name) => (event) => {
    setForm((prevForm) => ({
      ...prevForm,
      [name]: event.target.checked,
      goBack: true,
    }));
  };

  const fetchKeywordsForQRCode = async () => {
    try {
      setLoadingAdd(true);
      const keywordsData = await getKeywords('');

      const arraySuggestions = keywordsData.map((keyword) => keyword.value);
      setSuggestions([...arraySuggestions]);
    } catch (error) {
      console.error('Erro ao buscar keywords:', error);
    } finally {
      setLoadingAdd(false);
    }
  };

  useEffect(() => {
    fetchKeywordsForQRCode();
  }, []);

  //handle downshift
  const handleChangeDownshift = (item) => {
    if (!item || item.trim() === '') return;

    setIsDownshiftOpen(false);

    let keyWordsSet = form.keyWordsSet;
    if (!keyWordsSet.includes(item)) {
      keyWordsSet = [...keyWordsSet, item];
    }

    setSuggestions((prevSuggestions) =>
      prevSuggestions.filter((suggestion) => suggestion !== item)
    );

    setForm((prevForm) => ({
      ...prevForm,
      keywords: '',
      keyWordsSet,
    }));
  };

  const handleDeleteDownshift = (item) => () => {
    const keyWordsSet = form.keyWordsSet;

    const updatedKeyWordsSet = keyWordsSet.filter((keyword) => keyword !== item);

    setSuggestions((prevSuggestions) => {
      if (!prevSuggestions.includes(item)) {
        return [...prevSuggestions, item];
      }
      return prevSuggestions;
    });

    setForm((prevForm) => ({
      ...prevForm,
      keyWordsSet: updatedKeyWordsSet,
    }));
  };

  //fim handle downshift
  const handleSubmit = () => {
    const { data, action, keyWordsSet, useGeoInfo, validatorId } = form;

    if (data === '') {
      setForm((prevForm) => ({
        ...prevForm,
        openCheck: true,
      }));
      toast.error(
        action === 'app_screen'
          ? 'Selecione a tela do app'
          : "Campo 'Pesquisar' deve ser preenchido."
      );
      return;
    }
    setLoading(true);

    let qrCodeInfo = { ...form };

    if (qrCodeInfo.action === 'validate_promo') {
      qrCodeInfo.data = `${qrCodeInfo.data}|${validatorId}`;
    }

    if (!keyWordsSet || keyWordsSet.length < 1) {
      qrCodeInfo.keyWordsSet = undefined;
    }

    if (!useGeoInfo) {
      qrCodeInfo.lat = undefined;
      qrCodeInfo.long = undefined;
      qrCodeInfo.radius = undefined;
    }

    apiQRCode(qrCodeInfo)
      .then(() => {
        window.pathname = '/admin/configuracoes/qrcodes';
        setForm((prevForm) => ({
          ...prevForm,
          openCheck: true,
        }));
        setLoading(false);
        toast.success('Informação salva.', { autoClose: 2000 });
        history.goBack();
      })
      .catch(() => {
        setForm((prevForm) => ({
          ...prevForm,
          openCheck: true,
        }));
        setLoading(false);
        toast.error('Erro ao tentar salvar informação.', { autoClose: 2000 });
      });
  };

  const searchLatLong = () => {
    const address = encodeURIComponent(
      `${form.logradouro} ${form.numero} ${form.bairro} ${form.cidade} ${form.estado}`
    );

    geocoding(address)
      .then((result) => {
        if (result.status === 'OK' && result.results.length > 0) {
          setForm((prevForm) => ({
            ...prevForm,
            lat: result.results[0].geometry.location.lat,
            long: result.results[0].geometry.location.lng,
          }));
        } else {
          setForm((prevForm) => ({
            ...prevForm,
            lat: 0,
            long: 0,
          }));
        }
      })
      .catch(() => {
        setForm((prevForm) => ({
          ...prevForm,
          openCheck: true,
          lat: 0,
          long: 0,
        }));
        setLoading(false);
      });
  };

  const addShift = (idx) => {
    const newHour = [...form.availableHours];

    if (!newHour[idx].shifts) {
      newHour[idx].shifts = [];
    }

    newHour[idx].shifts.push({ starts: '00:00', ends: '23:59' });
    if (newHour[idx].type !== '24h') {
      newHour[idx].type = 'open';
    }

    setForm((prevForm) => ({
      ...prevForm,
      availableHours: newHour,
    }));
  };

  const changeShift = (idx, idx2, name) => (event) => {
    const changeHour = [...form.availableHours];

    changeHour[idx].shifts[idx2][name] = event.target.value;
    setForm((prevForm) => ({ ...prevForm, availableHours: changeHour }));
  };

  const deleteShift = (idx, idx2) => {
    const deleteHour = [...form.availableHours];

    deleteHour[idx].shifts.splice(idx2, 1);
    if (deleteHour[idx].shifts.length === 0 && deleteHour[idx].type !== '24h') {
      deleteHour[idx].type = 'closed';
    }

    setForm((prevForm) => ({ ...prevForm, availableHours: deleteHour }));
  };

  const changeShiftType = (idx) => (event) => {
    const changeHour = [...form.availableHours];

    if (!changeHour[idx].shifts) {
      changeHour[idx].shifts = [];
    }

    if (event.target.checked) {
      changeHour[idx].type = '24h';
    } else {
      changeHour[idx].type = changeHour[idx].shifts.length > 0 ? 'open' : 'closed';
    }

    setForm((prevForm) => ({ ...prevForm, availableHours: changeHour }));
  };

  const getList = () => {
    switch (form.action) {
      case 'add_triibo':
        return form.keywordsList;
      case 'open_card':
        return form.cardsList;
      case 'establishment_info':
        return form.establishmentList;
      case 'validate_promo':
      case 'promotion_item':
        return form.promotionList;
      case 'product_info':
        return form.productList;
      case 'org_info':
        return form.orgList;
      default:
        return [];
    }
  };

  const getOptionTitle = (option) => {
    switch (form.action) {
      case 'add_triibo':
        return option.value;
      case 'open_card':
        return `${option.title}`;
      case 'establishment_info':
        return option.nome || option.key;
      case 'validate_promo':
      case 'promotion_item':
        return `${option.title} (Estabelecimento: ${option.establishmentName})`;
      case 'product_info':
        return `${option.title} (Estabelecimento: ${option.establishmentName})`;
      case 'org_info':
        return option.name;
      default:
        return '';
    }
  };

  const classes = props;

  const selectedItem = form.keyWordsSet;

  if (window.pathname !== null) {
    return (
      <Redirect
        to={{
          pathname: window.pathname,
          state: props.location.state,
        }}
      />
    );
  } else {
    if (loading) {
      return (
        <div className="loading">
          <Loading />
        </div>
      );
    } else {
      return (
        <div style={{ padding: '4rem 32px', maxWidth: '100%' }}>
          <HeaderTitle pageInfo={form.qrCodeId ? `Editando QRCode` : 'Criar QRCode'} />
          <br />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <TextField
              value={form.title}
              required
              onChange={handleChange('title')}
              className="input-field"
              type="text"
              id="description"
              label="Descrição"
              style={{ marginBottom: '1em' }}
            />

            <FormLabel component="legend">Ação</FormLabel>
            <FormControl className="input-field">
              <Select
                style={{ minWidth: '300px' }}
                value={form.action}
                onChange={(event) => {
                  const selectedAction = event.target.value;
                  setForm((prevForm) => ({
                    ...prevForm,
                    action: selectedAction,
                    selectedObject: null,
                    data: '',
                  }));
                }}
                inputProps={{ id: 'action' }}
              >
                <MenuItem value="add_triibo">Adicionar keyword</MenuItem>
                <MenuItem value="open_card">Abrir card</MenuItem>
                <MenuItem value="promotion_item">Abrir promoção</MenuItem>
                <MenuItem value="validate_promo">Consumir promoção</MenuItem>
                <MenuItem value="product_info">Abrir produto</MenuItem>
                <MenuItem value="establishment_info">Abrir estabelecimento</MenuItem>
                <MenuItem value="webview">Abrir URL na webview</MenuItem>
                <MenuItem value="browser">Abrir URL no navegador</MenuItem>
                <MenuItem value="org_info">Informação de Organização</MenuItem>
                <MenuItem value="app_screen">Navegação no App</MenuItem>
              </Select>

              {form.action === 'app_screen' && (
                <div
                  style={{
                    marginTop: '10px',
                    width: '100%',
                    marginBottom: '10px',
                  }}
                >
                  <FormLabel component="legend">Selecione a tela do app</FormLabel>
                  <Select
                    style={{ minWidth: '300px', width: '100%' }}
                    value={form.data}
                    onChange={(e) =>
                      setForm((prevForm) => ({
                        ...prevForm,
                        data: e.target.value,
                      }))
                    }
                    inputProps={{ id: 'list_screen' }}
                    required
                  >
                    {app_screen_list.map((item) => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.type}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              )}

              {form.action !== 'app_screen' && (
                <Autocomplete
                  id="Busca"
                  options={getList()}
                  getOptionLabel={(option) => getOptionTitle(option)}
                  style={{ width: '100%', marginTop: '15px' }}
                  value={form.selectedObject || null}
                  onChange={(event, option) => {
                    if (option) {
                      setForm((prevForm) => ({
                        ...prevForm,
                        data:
                          form.action === 'add_triibo' ? option.value : option.id || option.orgID,
                        selectedObject: option || null,
                      }));
                      fetchData(
                        form.action === 'add_triibo' ? option.value : option.title || '',
                        0,
                        10
                      );
                    } else {
                      setForm((prevForm) => ({
                        ...prevForm,
                        data: '',
                        selectedObject: null,
                      }));
                    }
                  }}
                  noOptionsText={
                    loadingAdd ? <CircularProgress size={10} /> : 'Nenhum resultado encontrado...'
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      value={form.data}
                      onChange={(e) => setSearchApi(e.target.value)}
                      label="Pesquisar"
                    />
                  )}
                  disabled={form.action === 'webview' || form.action === 'browser'}
                />
              )}

              {(form.action === 'webview' || form.action === 'browser') && (
                <TextField
                  value={form.data}
                  required
                  onChange={handleChange('data')}
                  className="input-field"
                  type="text"
                  id="data"
                  label={form.action === 'webview' || form.action === 'browser' ? 'URL' : 'ID'}
                />
              )}

              <Downshift
                id="downshift-multiple"
                inputValue={keywords}
                onChange={handleChangeDownshift}
                selectedItem={selectedItem}
                isOpen={isDownshiftOpen}
                style={{ width: '100%' }}
              >
                {({
                  getInputProps,
                  getItemProps,
                  isOpen,
                  inputValue: inputValue2,
                  selectedItem: selectedItem2,
                  highlightedIndex,
                }) => (
                  <div className={classes.container} style={{ marginTop: '2vh', width: '100%' }}>
                    {selectedItem &&
                      renderInput({
                        fullWidth: true,
                        classes,
                        InputProps: getInputProps({
                          startAdornment: selectedItem.map((item) => (
                            <Chip
                              key={item}
                              tabIndex={-1}
                              label={item}
                              className={classes.chip}
                              onDelete={handleDeleteDownshift(item)}
                            />
                          )),
                          onChange: handleChange('keywords'),
                          onFocus: (e) => {
                            e.preventDefault();
                            setIsDownshiftOpen(true);
                          },
                          onClick: (e) => {
                            e.preventDefault();
                            setIsDownshiftOpen(true);
                          },
                          onBlur: (e) => {
                            e.preventDefault();
                            setIsDownshiftOpen(false);
                          },
                          placeholder: 'Selecione a keyword na lista abaixo',
                        }),
                        label: 'Keywords de limitação do QRCode',
                      })}
                    {isOpen && (
                      <Paper square>
                        {suggestions.filter((suggestion) =>
                          suggestion.toLowerCase().includes(inputValue2.toLowerCase())
                        ).length > 0 ? (
                          suggestions
                            .filter((suggestion) =>
                              suggestion.toLowerCase().includes(inputValue2.toLowerCase())
                            )
                            .map((suggestion, index) =>
                              renderSuggestion({
                                suggestion,
                                index,
                                itemProps: getItemProps({ item: suggestion }),
                                highlightedIndex,
                                selectedItem: selectedItem2,
                              })
                            )
                        ) : (
                          <div
                            style={{
                              padding: '8px',
                              color: '#999',
                            }}
                          >
                            Nenhum resultado encontrado
                          </div>
                        )}
                      </Paper>
                    )}
                  </div>
                )}
              </Downshift>

              <FormControlLabel
                style={{ marginTop: '5em' }}
                control={
                  <Switch
                    checked={form.useGeoInfo}
                    onChange={handleChangeSwitch('useGeoInfo')}
                    color="primary"
                  />
                }
                label={'Limitar uso por localização?'}
              />

              <div>
                <TextField
                  className="input-field"
                  type="text"
                  inputProps={{ maxLength: 8 }}
                  // value={form.localizacao ? form.localizacao.cep  : "" }
                  value={form.cep ? form.cep : ''}
                  id="endereco"
                  label="CEP do Estabelecimento"
                  placeholder="Ex.: 04024020"
                  onChange={handleChange('cep')}
                  required={form.useGeoInfo}
                  disabled={!form.useGeoInfo}
                />
              </div>

              <Grid container spacing={2} alignItems="stretch">
                <Grid item sm={12} md={4}>
                  <TextField
                    className="input-field"
                    type="text"
                    value={form.logradouro ? form.logradouro : ''}
                    id="logradouro"
                    label="Logradouro"
                    placeholder="Ex.: Rua Guapiaçu"
                    onChange={handleChange('logradouro')}
                    required={form.useGeoInfo}
                    disabled={!form.useGeoInfo}
                  />
                </Grid>

                <Grid item sm={12} md={4}>
                  <TextField
                    className="input-field"
                    type="number"
                    value={form.numero ? form.numero : ''}
                    id="numero"
                    label="Número"
                    placeholder="Ex.: nº 5"
                    onChange={handleChange('numero')}
                    required={form.useGeoInfo}
                    disabled={!form.useGeoInfo}
                  />
                </Grid>

                <Grid item sm={12} md={4}>
                  <TextField
                    className="input-field"
                    type="text"
                    value={form.complemento ? form.complemento : ''}
                    id="complemento"
                    label="Complemento"
                    placeholder="Ex.: apto. 15"
                    onChange={handleChange('complemento')}
                    disabled={!form.useGeoInfo}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} alignItems="stretch">
                <Grid item sm={12} md={4}>
                  <TextField
                    className="input-field"
                    type="text"
                    value={form.bairro ? form.bairro : ''}
                    id="bairro"
                    label="Bairro"
                    placeholder="Ex.: Vila da Saúde"
                    onChange={handleChange('bairro')}
                    required={form.useGeoInfo}
                    disabled={!form.useGeoInfo}
                  />
                </Grid>

                <Grid item sm={12} md={4}>
                  <TextField
                    className="input-field"
                    type="text"
                    value={form.cidade ? form.cidade : ''}
                    id="cidade"
                    label="Cidade"
                    placeholder="Ex.: São Paulo"
                    onChange={handleChange('cidade')}
                    required={form.useGeoInfo}
                    disabled={!form.useGeoInfo}
                  />
                </Grid>

                <Grid item sm={12} md={4}>
                  <TextField
                    className="input-field"
                    inputProps={{ maxLength: 2 }}
                    type="text"
                    value={form.estado ? form.estado : ''}
                    id="estado"
                    label="UF"
                    placeholder="Ex.: SP"
                    onChange={handleChange('estado')}
                    required={form.useGeoInfo}
                    disabled={!form.useGeoInfo}
                  />
                </Grid>
              </Grid>

              {/* <TextField value={ form.lat } required={form.useGeoInfo} disabled={!form.useGeoInfo} onChange = {handleChange('lat') }  className = "input-field" type = "number" id="lat"
					label = "Latitude" />

					<TextField value={ form.long } required={form.useGeoInfo} disabled={!form.useGeoInfo} onChange = {handleChange('long') }  className = "input-field" type = "number" id="long"
					label = "Longitude" /> */}

              <TextField
                value={form.radius}
                required={form.useGeoInfo}
                disabled={!form.useGeoInfo}
                onChange={handleChange('radius')}
                className="input-field"
                type="number"
                id="radius"
                label="Raio de utilização em metros"
              />

              <div style={{ marginTop: '5em' }}>
                <Grid container spacing={2} alignItems="stretch">
                  <Grid item sm={12} md={6}>
                    {form.availableHours.map((item, i) => {
                      return (
                        <Accordion elevation={0} style={{ borderBottom: '2px solid #0000001f' }}>
                          <AccordionSummary
                            expandIcon={<ExpandMore />}
                            IconButtonProps={{ edge: 'start' }}
                          >
                            <Typography>{days[i]}</Typography>

                            <Typography
                              style={{
                                textAlign: 'right',
                                width: '100%',
                                marginRight: '1.0em',
                                textTransform: 'capitalize',
                              }}
                            >
                              {item.type}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <div>
                              {item.shifts && item.shifts.length > 0 && item.type !== '24h' && (
                                <div
                                  style={{
                                    display: 'flex',
                                    marginBottom: '1.5em',
                                  }}
                                >
                                  <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    color="secondary"
                                    style={{ fontWeight: '550' }}
                                  >
                                    Início do Turno
                                  </Typography>

                                  <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    color="secondary"
                                    style={{
                                      fontWeight: '550',
                                      marginLeft: '29px',
                                    }}
                                  >
                                    Fim do Turno
                                  </Typography>
                                </div>
                              )}

                              {item.shifts && item.type !== '24h'
                                ? item.shifts.map((item2, i2) => {
                                    return (
                                      <div
                                        style={{
                                          display: 'block',
                                          marginBottom: '1.5em',
                                        }}
                                      >
                                        <TextField
                                          id="time"
                                          // label="Início do Turno"
                                          type="time"
                                          style={{ marginRight: '55px' }}
                                          value={item2.starts}
                                          onChange={changeShift(i, i2, 'starts')}
                                          className={classes.textField}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                        />

                                        <TextField
                                          id="time"
                                          type="time"
                                          // style={{width: '195px', marginLeft: '1.5em'}}
                                          value={item2.ends}
                                          onChange={changeShift(i, i2, 'ends')}
                                          className={classes.textField}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                        />
                                        <IconButton
                                          aria-label="delete"
                                          onClick={() => {
                                            deleteShift(i, i2);
                                          }}
                                        >
                                          <Delete />
                                        </IconButton>
                                      </div>
                                    );
                                  })
                                : ''}

                              {item.type !== '24h' && (
                                <span
                                  style={{
                                    cursor: 'pointer',
                                    color: 'blue',
                                    textDecoration: 'underline',
                                  }}
                                  onClick={() => {
                                    addShift(i);
                                  }}
                                >
                                  Adicionar horário de funcionamento
                                  <br />
                                  <br />
                                </span>
                              )}
                              <FormControlLabel
                                control={
                                  <Switch
                                    onChange={changeShiftType(i)}
                                    checked={item.type === '24h'}
                                    color="primary"
                                  />
                                }
                                label={'Aberto 24 horas'}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                  </Grid>

                  <Grid item sm={12} md={6}>
                    {form.qrCodeShortLink ? (
                      <QRCode
                        id="qrcode-image"
                        size={1024}
                        style={{
                          display: 'block',
                          maxWidth: '300px',
                          maxHeight: '300px',
                          height: 'auto',
                          margin: '2em auto',
                        }}
                        level="L"
                        value={form.qrCodeShortLink}
                      />
                    ) : (
                      <Loading />
                    )}
                  </Grid>
                </Grid>
              </div>

              <FormControlLabel
                style={{ marginTop: '10px' }}
                control={
                  <Switch
                    checked={form.active}
                    onChange={handleChangeSwitch('active')}
                    color="primary"
                  />
                }
                label={'Ativo'}
              />
            </FormControl>

            <Button
              onClick={() => window.history.back()}
              style={{
                fontWeight: '550',
                marginTop: '20px',
                marginLeft: '10px',
                color: 'white',
                textTransform: 'capitalize',
              }}
              variant="contained"
              color="secondary"
              startIcon={<ArrowBack />}
            >
              Voltar
            </Button>

            <Button
              style={{
                fontWeight: '550',
                marginTop: '20px',
                marginLeft: '10px',
                color: 'white',
                textTransform: 'capitalize',
              }}
              variant="contained"
              color="primary"
              type="submit"
              startIcon={<Save />}
            >
              Salvar QRCode
            </Button>
          </form>
        </div>
      );
    }
  }
};

export default withStyles(styles)(QRCodeInformation);
