import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { store } from 'js/core/configureStore';

import thumbnailPromocao from 'styles/assets/promocao/thumbnail-promocao.png';
import listaFotos from 'styles/assets/promocao/listaFotos-promocao.png';
import cuponsValidos from 'styles/assets/promocao/cuponsValidos.png';
import nomeCupom from 'styles/assets/promocao/nomeCupom.png';

import placeholder from 'styles/assets/placeholder/placeholder_triibo.png';
import placeholderThumbnail from 'styles/assets/placeholder/placeholder_thumbnail.png';
import ImportImage from 'js/components/TriiboComponents/ImportImage';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  TextField,
  Button,
  FormGroup,
  FormControl,
  FormControlLabel,
  Switch,
  Grid,
  Radio,
  RadioGroup,
  Tooltip,
  Alert,
  Box,
  CircularProgress,
} from '@mui/material';
import { ArrowBack, Help, DoneAll, Close, FileCopy } from '@mui/icons-material';

import { styled } from '@mui/system';

import Loading from 'js/containers/Loading/Loading';
import { updateStorePromotionExternalAction } from 'js/core/actions/storePromotionInformationExternalActions';
import {
  downloadImage,
  downloadImageAsBlob,
  uploadImage,
} from 'js/library/services/StorageManager';

import {
  msToTime,
  convertIsoToDate,
  getUserInfo,
  formatDate,
  isAdminPage,
  b64toBlob,
  calcCooldown,
  isBase64,
  blobToBase64,
} from 'js/library/utils/helpers.js';
import EditShowId from 'js/components/Store/Promotion/EditShowId';
import { getTrialStatus } from 'js/library/utils/API/Trial/apiGetTrial';
import { updateTrialStatus } from 'js/library/utils/API/Trial/apiUpdateTrial';

import * as S from 'js/components/Establishment/styles';
import * as D from 'js/components/DesignSystem/styles';
import { BsXLg } from 'react-icons/bs';
import { getVoucherTemplate } from 'js/library/utils/API/getVoucherTemplate';

import readEstablishment from 'js/library/utils/API/Establishment/apiReadEstablishment';
import { getStoreChangeRequest } from 'js/library/utils/API/Store/apiGetStoreChangeRequest';
import { confirmStoreChanges } from 'js/library/utils/API/Store/apiConfirmStoreChanges';

import { updateStore, updateStoreApprovals } from 'js/library/utils/API/Store/apiUpdateStore';
import { toast } from 'react-toastify';
import { updateVoucherTemplate } from 'js/library/utils/API/apiUpdateVoucherTemplate';
import Transition from 'js/containers/Transition';
import StyledBadge from 'js/containers/Store/StyledBadge';
import StyledBadge2 from 'js/containers/Store/StyledBadge2';

// Estilo da mensagem de aprovado/rejeitado - Alert
const StyledAlert = styled(Alert)({
  root: {
    width: '100%',
    maxWidth: '100%',
    padding: '6px 50px',
    fontSize: '18px',
    textAlign: 'center',
    marginBottom: '25px',
    textAlignLast: 'center',
  },
});

function StorePromotionExternal(props) {
  const history = useHistory();

  const establishmentId = props.match.params.id;
  const editPage = window.location.href.includes('/editar');

  const dayMs = 86400000;
  const responseDays = 14; //Tempo de espera para aprovação em dias

  //convertendo enable date
  let enableDateInit = new Date(props?.location?.state?.enableDate || Date.now());

  const enableYear = enableDateInit.getFullYear();
  const enableMonth = String(enableDateInit.getMonth() + 1).padStart(2, '0');
  const enableDay = String(enableDateInit.getDate()).padStart(2, '0');
  const enableHour = String(enableDateInit.getHours()).padStart(2, '0');
  const enableMinute = String(enableDateInit.getMinutes()).padStart(2, '0');

  enableDateInit = `${enableYear}-${enableMonth}-${enableDay}T${enableHour}:${enableMinute}`;

  //convertendo disable date
  let disableDateInit = new Date(
    props?.location?.state?.disableDate || Date.now() + 86400000 * 365
  );

  const disableYear = disableDateInit.getFullYear();
  const disableMonth = String(disableDateInit.getMonth() + 1).padStart(2, '0');
  const disableDay = String(disableDateInit.getDate()).padStart(2, '0');
  const disableHour = String(disableDateInit.getHours()).padStart(2, '0');
  const disableMinute = String(disableDateInit.getMinutes()).padStart(2, '0');

  disableDateInit = `${disableYear}-${disableMonth}-${disableDay}T${disableHour}:${disableMinute}`;

  // Dados do estabelecimento
  const [idGestor, setIdGestor] = useState(null);
  const [idEstabelecimento, setIdEstabelecimento] = useState(null);
  const [nomeEstabelecimento, setNomeEstabelecimento] = useState(null);
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const [address, setAddress] = useState(null);
  const [neighborhood, setNeighborhood] = useState('');

  // Dados da Store
  const [action, setAction] = useState(null);
  const idStore = props.match.params.promoId;
  const [description, setDescription] = useState('');
  const [showQRCode, setShowQRCode] = useState(null);
  const [title, setTitle] = useState('');
  const [storeType, setStoreType] = useState('promotion');
  const [autoValidate, setAutoValidate] = useState(null);
  const [enableDate, setEnableDate] = useState(enableDateInit);
  const [disableDate, setDisableDate] = useState(disableDateInit);

  const [tagCapture, setTagCapture] = useState('');
  const [tags, setTags] = useState([]);
  const [storeThumbnail, setStoreThumbnail] = useState(null);
  const [fileStoreThumbnail, setFileStoreThumbnail] = useState('');
  const [storePhoto, setStorePhoto] = useState(null);
  const [fileStorePhoto, setFileStorePhoto] = useState('');

  const [photoListFile, setPhotoListFile] = useState([]);

  // Dados do template
  const [id, setId] = useState(null);
  const [consumoUnico, setConsumoUnico] = useState(false);
  const [cooldownDays, setCooldownDays] = useState(0);
  const [cooldownHours, setCooldownHours] = useState(0);
  const [cooldownMinutes, setCooldownMinutes] = useState(0);
  const [dataGeracao, setDataGeracao] = useState(null);
  const [descricao, setDescricao] = useState('');
  const [detailsDescription, setDetailsDescription] = useState(null);
  const [hasSimultaneo, setHasSimultaneo] = useState(false);
  const [qtdSimultaneo, setQtdSimultaneo] = useState(1);
  const [titulo, setTitulo] = useState('');
  const [copyTemplateMessage, setCopyTemplateMessage] = useState('Copiar ID do template');
  const [templateInfo, setTemplateInfo] = useState({});

  const [type, setType] = useState(null);
  const [consumo, setConsumo] = useState(null);

  const [foto, setFoto] = useState('');
  const [fileFoto, setFileFoto] = useState('');
  const [thumbnail, setThumbnail] = useState(null);
  const [fileThumbnail, setFileThumbnail] = useState(null);

  // States de validação local
  const [open, setOpen] = useState(false);
  const [goBack, setGoBack] = useState(false);
  const [loading, setLoading] = useState(false);
  const [aprovacao, setAprovacao] = useState(false);

  // Mensagem de aprovação
  const [newStatus, setNewStatus] = useState(null);
  const [comment, setComment] = useState('');

  // Botões de adicionar imagem
  const [sameInformation, setSameInformation] = useState(false);
  const [sameImages, setSameImages] = useState(false);
  const [disableButtonUpdate, setDisableButtonUpdate] = useState(true);

  // Controle template
  const [couponGetMode, setCouponGetMode] = useState('simpleGetMode');

  // Controle do botão de avaliação da curadoria
  const [isApproveButtonDisabled, setIsApproveButtonDisabled] = useState(false);

  const establishmentListInfos = store.getState().authModel.establishmentList;

  const [unlimitedDisableDate, setUnlimitedDisableDate] = useState(null);

  const wherePromo = localStorage.getItem('fromWherePromotion');
  const [status, setStatus] = useState(null);

  // trás os dados do estabelecimento
  useEffect(() => {
    const fetchEstablishmentData = async () => {
      try {
        const result = await readEstablishment(establishmentId);
        const dataEstablishment = result;

        setIdEstabelecimento(dataEstablishment.id ?? establishmentId);
        setIdGestor(dataEstablishment.idGestor);
        setNomeEstabelecimento(dataEstablishment.nome);
        setLat(dataEstablishment.lat);
        setLong(dataEstablishment.long);
        setAddress(
          dataEstablishment.endereco === 'Brasil'
            ? dataEstablishment.endereco
            : `${dataEstablishment.logradouro}, ${dataEstablishment.numero} - ${dataEstablishment.bairro}, ${dataEstablishment.cidade} - ${dataEstablishment.estado}, `
        );
        setNeighborhood(dataEstablishment.bairro);
      } catch (error) {
        console.error('Erro ao obter dados do estabelecimento', error);
      }
    };

    fetchEstablishmentData();

    if (editPage) {
      getStoreChangeRequest(idStore).then((dataReceived) => {
        const promoData = dataReceived.response;

        //storeInfo
        const storeInfo = promoData.storeInfo;

        // storeApprovals
        const dataApprovals = promoData.storeApprovals;
        let storeApprovals;

        // Verifica se dataApprovals está definido e não vazio
        if (dataApprovals && Object.keys(dataApprovals).length > 0) {
          // Verifica se há algum objeto com o campo "action" igual a "edit"
          const editApprovalKey = Object.keys(dataApprovals).find(
            (key) => dataApprovals[key].action === 'edit'
          );

          // Se houver um objeto com "action" === "edit", define storeApprovals como esse objeto
          // Caso contrário, define como o primeiro objeto
          storeApprovals = editApprovalKey
            ? dataApprovals[editApprovalKey]
            : dataApprovals[Object.keys(dataApprovals)[0]];
        }

        //storeEdits
        const dataStoreEdits = promoData.storeEdits;
        let storeEdits = '';

        if (dataStoreEdits) {
          Object.values(dataStoreEdits).forEach((edits) => {
            storeEdits = edits;
          });
        }

        const mergeFields = (info, edits) => {
          return {
            ...info,
            ...edits,
          };
        };

        // Finalizando o estado com os valores atualizados de storeEdits
        const finalState = mergeFields(storeInfo, storeEdits.changedFields || {});

        setAction(storeApprovals?.action);
        setStatus(storeApprovals?.status);
        setComment(storeApprovals?.comment);
        setNewStatus(null);

        setDescription(finalState.description ?? null);
        setShowQRCode(
          finalState.id === undefined
            ? true
            : finalState.showQRCode === undefined
            ? null
            : finalState.showQRCode
        );
        setTitle(finalState.title ?? null);
        setStoreType(finalState.type ?? 'promotion');
        setAutoValidate(
          finalState.id === undefined
            ? 'cliente'
            : finalState.autoValidate === true
            ? 'cliente'
            : 'estabelecimento'
        );

        setTagCapture('');

        setTags(finalState.tags ?? []);

        setStoreThumbnail(finalState.thumbnail ?? null);
        setStorePhoto(finalState.photo ?? null);

        setFileStoreThumbnail(null);
        setFileStorePhoto(null);

        // dados do template
        setId(finalState.templateId);

        // Atualizando photoList
        if (finalState.photoList !== undefined) {
          let photoListFile = [];

          finalState.photoList.map((item, i) => {
            const folderPhotoList = finalState.type === 'product' ? item.split('/')[0] : 'promocao';
            const imgPathList =
              finalState.type === 'product' && item.split('/')[1] ? item.split('/')[1] : item;

            downloadImage(folderPhotoList, imgPathList).then((downloadedImage) => {
              photoListFile[i] = downloadedImage;
              setPhotoListFile(photoListFile);
            });
          });
        }

        // Atualizando thumbnail
        if (finalState.thumbnail !== undefined) {
          const folderThumb =
            finalState.type === 'product' ? finalState.thumbnail.split('/')[0] : 'promocao';

          const thumbnailName =
            finalState.type === 'product'
              ? finalState.thumbnail.split('/')[1]
              : finalState.thumbnail;

          downloadImage(folderThumb, thumbnailName)
            .then((downloadedImage) => {
              setFileStoreThumbnail(downloadedImage);
            })
            .catch(() => {
              // console.log("Foto não encontrada:", storeInfo.thumbnail);
            });
        }

        if (finalState.photo !== undefined) {
          const folderPhoto =
            finalState.type === 'product' ? finalState.photo.split('/')[0] : 'promocao';

          const imgPath =
            finalState.type === 'product' && finalState.photo.split('/')[1]
              ? finalState.photo.split('/')[1]
              : finalState.photo;

          downloadImage(folderPhoto, imgPath).then((loaded) => {
            setFileStorePhoto(loaded);
          });
        }

        //baixando dados da Voucher Template, caso seja edição
        if (storeInfo.templateId) {
          getVoucherTemplate(storeInfo.templateId)
            .then((dataReceived) => {
              const dataVoucherTemplate = dataReceived.result;

              setTemplateInfo(dataVoucherTemplate);

              const cooldown = msToTime(dataVoucherTemplate.cooldown);

              if (dataVoucherTemplate.thumbnail !== undefined) {
                dataVoucherTemplate.loadedThumbnail = placeholderThumbnail;
                downloadImage('voucher', dataVoucherTemplate.thumbnail)
                  .then((downloadedImage) => {
                    setThumbnail(dataVoucherTemplate.thumbnail);
                    setFileThumbnail(downloadedImage);
                  })
                  .catch(() => {
                    // console.log("Foto não encontrada:", dataReceived.thumbnail);
                  });
              }

              if (dataVoucherTemplate.foto !== undefined) {
                dataVoucherTemplate.loadedFoto = placeholder;
                downloadImage('voucher', dataVoucherTemplate.foto)
                  .then((downloadedImage) => {
                    setFoto(dataVoucherTemplate.foto);
                    setFileFoto(downloadedImage);
                  })
                  .catch(() => {
                    // console.log("Foto não encontrada:", dataReceived.thumbnail);
                  });
              }

              dataReceived.nomeEstabelecimento = nomeEstabelecimento;

              setConsumoUnico(dataVoucherTemplate.consumoUnico ?? false);
              setDataGeracao(dataVoucherTemplate.dataGeracao);
              setDescricao(dataVoucherTemplate.descricao);
              setDetailsDescription(dataVoucherTemplate.detailsDescription);
              setQtdSimultaneo(dataVoucherTemplate.qtdSimultaneo ?? 1);
              setTitulo(dataVoucherTemplate.titulo);

              setType(dataVoucherTemplate.type);
              setConsumo(dataVoucherTemplate.couponGetMode === ' ' ? 'posterior' : 'imediato');
              setCouponGetMode(dataVoucherTemplate.couponGetMode ?? 'simpleGetMode');
              setFoto(dataVoucherTemplate.foto);
              setThumbnail(dataVoucherTemplate.thumbnail);

              setCooldownDays(!cooldown.days || cooldown.days === NaN ? 0 : cooldown.days);
              setCooldownHours(!cooldown.hours || cooldown.hours === NaN ? 0 : cooldown.hours);
              setCooldownMinutes(
                !cooldown.minutes || cooldown.minutes === NaN ? 0 : cooldown.minutes
              );
            })
            .catch((error) => {
              // console.log("Erro na data:", error);
            });
        }
      });

      //convertendo enable date
      let enableDate = new Date(Date.now());
      if (enableDate !== undefined) {
        enableDate = new Date(enableDate);
      }

      const enableYear = enableDate.getFullYear();
      const enableMonth =
        enableDate.getMonth() + 1 < 10
          ? '0' + (enableDate.getMonth() + 1)
          : enableDate.getMonth() + 1;
      const enableDay =
        enableDate.getDate() < 10 ? '0' + enableDate.getDate() : enableDate.getDate();
      const enableHour =
        enableDate.getHours() < 10 ? '0' + enableDate.getHours() : enableDate.getHours();
      const enableMinute =
        enableDate.getMinutes() < 10 ? '0' + enableDate.getMinutes() : enableDate.getMinutes();

      enableDate =
        enableYear + '-' + enableMonth + '-' + enableDay + 'T' + enableHour + ':' + enableMinute;

      //convertendo disable date
      let disableDate = new Date(Date.now() + 86400000 * 365);
      if (disableDate !== undefined) {
        disableDate = new Date(disableDate);
      }

      const disableYear = disableDate.getFullYear();
      const disableMonth =
        disableDate.getMonth() + 1 < 10
          ? '0' + (disableDate.getMonth() + 1)
          : disableDate.getMonth() + 1;
      const disableDay =
        disableDate.getDate() < 10 ? '0' + disableDate.getDate() : disableDate.getDate();
      const disableHour =
        disableDate.getHours() < 10 ? '0' + disableDate.getHours() : disableDate.getHours();
      const disableMinute =
        disableDate.getMinutes() < 10 ? '0' + disableDate.getMinutes() : disableDate.getMinutes();

      disableDate =
        disableYear +
        '-' +
        disableMonth +
        '-' +
        disableDay +
        'T' +
        disableHour +
        ':' +
        disableMinute;

      setEnableDate(enableDate);
      setDisableDate(disableDate);
      setUnlimitedDisableDate(
        Math.abs(Date.parse(disableDate) - Date.parse(enableDate)) <= 1000 * 60 * 60 * 24 * 365 * 5
          ? false
          : true
      );
    }
  }, []);

  //altera os states dos campos, caso o usuário insira informações nos input
  const handleChange = (name, event) => {
    const value = event.target.value;

    if (['cooldownDays', 'cooldownHours', 'cooldownMinutes'].includes(name)) {
      const maxValues = { cooldownMinutes: 59, cooldownHours: 23 };
      const newValue = Math.max(0, Math.min(value, maxValues[name] || value));
      setGoBack(true);
      if (newValue < 0) toast.error('Este valor não pode ser negativo.');

      return name === 'cooldownDays'
        ? setCooldownDays(newValue)
        : name === 'cooldownHours'
        ? setCooldownHours(newValue)
        : name === 'cooldownMinutes'
        ? setCooldownMinutes(newValue)
        : null;
    }

    if (value === 'imediato') {
      setShowQRCode(true);
      setAutoValidate('cliente');
      setQtdSimultaneo(1);
      setCouponGetMode('simpleGetMode');
    } else if (value === 'posterior') {
      setShowQRCode(null);
      setAutoValidate('estabelecimento');
      setCouponGetMode(null);
    }

    setGoBack(true);
    return name === 'consumo'
      ? setConsumo(value)
      : name === 'couponGetMode'
      ? setCouponGetMode(value)
      : name === 'enableDate'
      ? setEnableDate(value)
      : name === 'disableDate'
      ? setDisableDate(value)
      : null;
  };

  const handlePushTag = () => {
    let tagHolder = tags;
    if (tagHolder.indexOf(tagCapture.toLocaleLowerCase()) === -1 && tagCapture.length !== 0) {
      tagHolder.push(tagCapture.toLowerCase());
    }

    setTags([...tagHolder]);
    setTagCapture('');
    setDisableButtonUpdate(false);
  };

  const photoListChangeStore = (file) => {
    if (fileStorePhoto === '') {
      setFileStorePhoto(file);
    } else {
      let photoListFileAux = [...(photoListFile ?? [])];
      if (file !== '') photoListFileAux.push(file);

      setPhotoListFile(photoListFileAux);
    }
  };

  const goBackFunc = () => {
    if (goBack === false) {
      history.goBack();
    } else {
      setOpen(true);
    }
  };

  const checkInformationFunc = async () => {
    setLoading(true);

    let couponGetModeValue = couponGetMode;
    let showQRCodeValue = showQRCode;
    let autoValidateValue;
    if (consumo === 'imediato') {
      showQRCodeValue = true;
      autoValidateValue = couponGetModeValue === 'scannerMode' ? 'cliente' : 'estabelecimento';
    } else {
      couponGetModeValue = null;
    }

    //convertendo imagens do cupom
    let newFileThumbnail = fileThumbnail;
    let newFileFoto = fileFoto;

    if (sameImages) {
      if (isBase64(fileStoreThumbnail)) {
        newFileThumbnail = fileStoreThumbnail;
      } else {
        newFileThumbnail = await downloadImageAsBlob(fileStoreThumbnail);
        newFileThumbnail = await blobToBase64(newFileThumbnail);
      }

      if (isBase64(fileStorePhoto)) {
        newFileFoto = fileStorePhoto;
      } else {
        newFileFoto = await downloadImageAsBlob(fileStorePhoto);
        newFileFoto = await blobToBase64(newFileFoto);
      }
    }

    const uid = getUserInfo().uId;
    let modalStepIsOpen = false;

    getTrialStatus(uid).then((result) => {
      updateTrialStatus({
        establishmentStep: result.establishmentStep,
        storeStep: true,
        relationshipStep: result.relationshipStep,
        congrats: result.congrats,
      }).then((result) => {
        if (sessionStorage.getItem('secondStepNew') === 'incomplete') {
          sessionStorage.setItem('secondStepNew', 'complete');
          modalStepIsOpen = true;
        }
      });
    });

    try {
      await updateStorePromotionExternalAction({
        id,
        consumoUnico,
        cooldownHours: parseInt(cooldownHours, 10) + parseInt(24 * parseInt(cooldownDays, 10), 10),
        cooldownMinutes,
        dataGeracao,
        descricao: sameInformation ? description : descricao,
        detailsDescription,
        neighborhood,
        qtdSimultaneo,
        titulo: sameInformation ? title : titulo,
        type,
        fileThumbnail: newFileThumbnail,
        thumbnail,
        fileFoto: newFileFoto,
        foto,
        couponGetMode: couponGetModeValue,

        idStore,
        description,
        showQRCode: showQRCodeValue,
        title,
        storeType,
        autoValidate: autoValidateValue,
        enableDate,
        disableDate: unlimitedDisableDate
          ? convertIsoToDate(new Date(Date.now() + 86400000 * 365 * 60))
          : disableDate,
        tags,
        fileStoreThumbnail,
        storeThumbnail,
        fileStorePhoto,
        storePhoto,

        idEstabelecimento,
        idGestor,
        nomeEstabelecimento,
        lat,
        long,
        address,

        hasSimultaneo,
        photoListFile,

        templateInfo,
      });

      setLoading(false);

      if (modalStepIsOpen) {
        history.push({
          pathname: `/estabelecimento/${idEstabelecimento}/secondStep`,
          state: { ...establishmentListInfos[0] },
        });
      } else if (JSON.parse(JSON.stringify(sessionStorage)).secondStepNew === 'incomplete') {
        sessionStorage.setItem('secondStepNew', 'complete');

        history.push(`/estabelecimento/${idEstabelecimento}/secondStep`);
      } else {
        history.push(`/estabelecimento/${idEstabelecimento}/promocoes`);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.error ?? 'Ocorreu um erro.');
    }
  };

  const handleSubmit = () => {
    const checkInformation = !fileStoreThumbnail || !fileStorePhoto;

    if (!fileStoreThumbnail || fileStoreThumbnail === '') {
      toast.error('É necessário adicionar uma imagem de thumbnail.');
    } else if (!fileStorePhoto || fileStorePhoto === '') {
      toast.error('É necessário adicionar ao menos uma imagem na lista de fotos.');
    } else if ((!fileThumbnail || fileThumbnail === '') && !sameImages) {
      toast.error('É necessário adicionar uma imagem de thumbnail para o cupom.');
    } else if ((!fileFoto || fileFoto === '') && !sameImages) {
      toast.error('É necessário adicionar uma imagem de detalhe para o cupom.');
    } else if (tags.length < 1) {
      toast.error('É necessário adicionar ao menos uma tag.');
    } else if (checkInformation === true) {
      toast.error('Todas as informações devem ser preenchidas.');
    } else {
      checkInformationFunc();
    }
  };

  const removeImage = (idx) => {
    let photoListFileAux = [...(photoListFile ?? [])];
    photoListFileAux.splice(idx, 1);

    setPhotoListFile(photoListFileAux);
    setDisableButtonUpdate(false);
  };

  const mainImage = (idx) => {
    let currentFoto = fileStorePhoto;
    let newPhotoListFile = photoListFile;
    let newFoto = photoListFile[idx];

    newPhotoListFile.splice(idx, 1);
    newPhotoListFile.unshift(currentFoto);

    setPhotoListFile(newPhotoListFile);
    setFileStorePhoto(newFoto);
  };

  const handlePromotionApproval = async (e, isApproved) => {
    e.preventDefault();

    setIsApproveButtonDisabled(true);
    let currentDate = Date.now();

    if (!isApproved && (comment === undefined || comment.trim() === '')) {
      toast.error('A mensagem de avaliação é obrigatória!');
      return;
    }

    let thumbnailName = thumbnail;
    let fileThumbnailAux = fileThumbnail;
    if (fileThumbnail !== null) {
      fileThumbnailAux =
        fileThumbnail.indexOf('https') === 0
          ? await downloadImageAsBlob(fileThumbnail)
          : b64toBlob(fileThumbnail);

      thumbnailName = 'storage:' + idStore + ':thumbnail:' + currentDate;
      uploadImage('voucher', fileThumbnailAux, thumbnailName);
    }

    let fotoName = foto;
    let fileFotoAux = fileFoto;
    if (fileFoto !== null) {
      fileFotoAux =
        fileFoto.indexOf('https') === 0 ? await downloadImageAsBlob(fileFoto) : b64toBlob(fileFoto);

      fotoName = 'storage:' + idStore + ':foto:' + currentDate;
      uploadImage('voucher', fileFotoAux, fotoName);
    }

    const storageFolderStore = storeType === 'promotion' ? 'promocao' : 'triibomania-premios';

    let storeThumbnailName = storeThumbnail;
    let fileStoreThumbnailAux = fileStoreThumbnail;
    if (fileStoreThumbnail !== null) {
      fileStoreThumbnailAux =
        fileStoreThumbnail.indexOf('https') === 0
          ? await downloadImageAsBlob(fileStoreThumbnail)
          : b64toBlob(fileStoreThumbnail);

      storeThumbnailName = 'storage:' + idStore + ':thumbnail:' + currentDate;
      uploadImage(storageFolderStore, fileStoreThumbnailAux, storeThumbnailName);
    }

    let storePhotoName = storePhoto;
    let fileStorePhotoAux = fileStorePhoto;
    if (fileStorePhoto !== null) {
      fileStorePhotoAux =
        fileStorePhoto.indexOf('https') === 0
          ? await downloadImageAsBlob(fileStorePhoto)
          : b64toBlob(fileStorePhoto);

      storePhotoName = 'storage:' + idStore + ':foto:' + currentDate;
      uploadImage(storageFolderStore, fileStorePhotoAux, storePhotoName);
    }

    let listaFotos = [];
    let listaFotosName = [];
    if (photoListFile !== null) {
      for (const key in photoListFile) {
        listaFotos[key] = 'gal' + key + '_' + id + ':' + currentDate;
        listaFotosName[key] =
          storeType === 'promotion'
            ? 'gal' + key + '_' + id + ':' + currentDate
            : 'triibomania-premios/gal' + key + '_' + id + ':' + currentDate;

        photoListFile[key] =
          photoListFile[key].indexOf('https') === 0
            ? await downloadImageAsBlob(photoListFile[key])
            : b64toBlob(photoListFile[key]);
      }
    }

    uploadImage(storageFolderStore, photoListFile, listaFotos);

    let pathnameArray = window.location.pathname.split('/');
    let pathname =
      pathnameArray[1] === 'admin' && pathnameArray[2] === 'estabelecimento'
        ? '/estabelecimento/' + idEstabelecimento + '/promocoes'
        : pathnameArray[1] === 'admin' &&
          pathnameArray[2] === 'aprovacao' &&
          pathnameArray[3] === 'promocao'
        ? '/admin/aprovar/promocoes'
        : '/estabelecimento/' + idEstabelecimento + '/promocoes';

    const userId = getUserInfo().uId;

    const dataVoucher = {
      consumoUnico,
      cooldown: calcCooldown(cooldownHours, cooldownMinutes),
      dataGeracao,
      descricao,
      detailsDescription,
      neighborhood: neighborhood ?? ' ',
      foto: fotoName,
      thumbnail: thumbnailName,
      qtdSimultaneo,
      titulo,
      type,
      idEstabelecimento,
      idGestor,
      nomeEstabelecimento,
      lat,
      long,
      address,
      tags,
      couponGetMode: !couponGetMode ? ' ' : couponGetMode.toString(),
    };

    const data = {
      status: isApproved ? 1 : 0,
      description,
      showQRCode,
      thumbnail:
        storeType === 'promotion'
          ? storeThumbnailName
          : 'triibomania-premios/' + storeThumbnailName,
      title,
      type: storeType,
      establishmentId: idEstabelecimento,
      establishmentName: nomeEstabelecimento,
      lat,
      long,
      photo: storeType === 'promotion' ? storePhotoName : 'triibomania-premios/' + storePhotoName,
      templateId: id,
      enableDate: Date.parse(enableDate),
      disableDate: unlimitedDisableDate
        ? convertIsoToDate(new Date(Date.now() + 86400000 * 365 * 60))
        : Date.parse(disableDate),
      autoValidate: autoValidate === 'cliente' ? true : false,
      photoList: listaFotosName,
      tags,
    };

    if (action !== 'create') {
      confirmStoreChanges(userId, idStore, isApproved, comment, data).then(() => {
        history.push('/admin/aprovado/promocoes');
      });
    } else {
      const payload = { status: isApproved ? 'aprovado' : 'negado', comment: comment ?? '' };
      updateStore(idStore, data, pathname).then(() => {
        updateStoreApprovals(userId, idStore, payload, null);
        toast.success(`Promoção ${isApproved ? 'aprovada' : 'reprovada'} com sucesso!`);
        history.push('/admin/aprovado/promocoes');
      });
    }

    updateVoucherTemplate(userId, id, dataVoucher);
  };

  const copyTemplateIdToClipboard = () => {
    navigator.clipboard.writeText(id);
    setCopyTemplateMessage('ID copiado');
  };

  if (loading) {
    return (
      <div className="loading">
        <Loading />
      </div>
    );
  } else {
    return (
      <div
        style={{
          maxHeight: '100%',
          overflowY: 'auto',
          padding: '35px 22px',
          maxWidth: '100%',
        }}
      >
        <Grid item sm={12} xs={12}>
          {/* alert com msg aprovação */}
          {aprovacao && aprovacao.status !== 'aprovado' && (
            <StyledAlert
              variant="filled"
              severity={aprovacao.status === 'negado' ? 'error' : 'info'}
            >
              {aprovacao.status === 'negado'
                ? aprovacao.comment
                : `Aguarde aprovação até ${formatDate(
                    aprovacao.requestDate + dayMs * responseDays
                  )}`}
            </StyledAlert>
          )}
        </Grid>

        {editPage === true && title === null ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              height: '100vh',
              alignItems: 'center',
            }}
          >
            <CircularProgress size="100px" />
          </div>
        ) : (
          <>
            <Typography
              variant="h6"
              gutterBottom
              color="primary"
              style={{
                fontWeight: '700',
                fontSize: '25px',
                marginBottom: '10px',
              }}
            >
              {nomeEstabelecimento}
            </Typography>
            {idStore === null ? (
              <Typography
                variant="h5"
                gutterBottom
                color="primary"
                style={{ fontWeight: '700', fontSize: '25px', marginBottom: '10px' }}
              >
                Cadastro da Promoção
              </Typography>
            ) : (
              <EditShowId text="Editar Promoção111" id={idStore} />
            )}

            <form
              onChange={() => setDisableButtonUpdate(false)}
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <div>
                <S.TagsLabelContainer>
                  <S.TagsBigLabel>Tags do seu negócio</S.TagsBigLabel>
                  <S.TagsSmallLabel>
                    As tags auxiliam na classificação e busca do seu negócio dentro do App. Adicione
                    uma palavra por vez.
                  </S.TagsSmallLabel>
                </S.TagsLabelContainer>

                <S.TagsContainer>
                  <S.TagsAddContainer>
                    <D.TriiboTextField
                      rows={1}
                      placeholder={'Insira o tipo e aperte enter'}
                      value={tagCapture}
                      onChange={(e) => {
                        setTagCapture(e.target.value.trim());
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handlePushTag();
                        }
                      }}
                    />
                    <S.TagsAddButton
                      blue={true}
                      onClick={(e) => {
                        e.preventDefault();
                        handlePushTag();
                      }}
                    >
                      Adicionar tag
                    </S.TagsAddButton>
                  </S.TagsAddContainer>
                  <D.TriiboTagsField>
                    <D.TriiboTagsPlaceholder $visibility={tags.length === 0 ? false : true}>
                      Digite a tag desejada no campo acima e pressione o botão para adicioná-las
                    </D.TriiboTagsPlaceholder>
                    {tags.length > 0 &&
                      tags.map((body, index) => (
                        <D.UserTags key={index}>
                          <D.TagText>{body}</D.TagText>
                          <D.TagDelete
                            onClick={(e) => {
                              e.preventDefault();
                              let newArr = tags;
                              newArr.splice(index, 1);

                              setTags([...newArr]);
                            }}
                          >
                            {<BsXLg style={{ color: 'white' }} />}
                          </D.TagDelete>
                        </D.UserTags>
                      ))}
                  </D.TriiboTagsField>
                </S.TagsContainer>
              </div>
              <FormGroup>
                <TextField
                  placeholder="Ex: Leve 2, pague 1!!!"
                  className="input-field"
                  type="text"
                  value={title ?? ''}
                  id="title"
                  label="Título da promoção"
                  onChange={(e) => {
                    setTitle(e.target.value);
                    setGoBack(true);
                  }}
                  required={true}
                />
                <TextField
                  style={{ width: '100%' }}
                  placeholder="Ex: Ao comprar 2 produtos você paga somente por 1."
                  className="input-field"
                  multiline
                  type="text"
                  value={description ?? ''}
                  id="description"
                  required={true}
                  label="Descrição da promoção"
                  onChange={(e) => {
                    setDescription(e.target.value);
                    setGoBack(true);
                  }}
                />
                <br />
                <Typography
                  style={{
                    marginTop: '10px',
                    marginBottom: '-15px',
                    fontWeight: '700',
                    fontSize: '20px',
                  }}
                  variant="h5"
                  gutterBottom
                  color="primary"
                >
                  Fotos da promoção
                </Typography>
                <br />
                <br />
                <div className="flex-external">
                  <div
                    className="img-thumb"
                    style={{
                      maxWidth: '400px',
                      position: 'relative',
                      top: '0',
                      left: '0',
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      color="secondary"
                      style={{ fontWeight: '550', textAlign: 'center' }}
                    >
                      Thumbnail (aparecerá em "Promoções Próximas")
                    </Typography>

                    <img
                      src={thumbnailPromocao}
                      alt="Thumbnail"
                      style={{
                        width: '100%',
                        maxWidth: '400px',
                        maxHeight: '447px',
                        position: 'relative',
                        top: '0',
                        left: '0',
                      }}
                    />

                    <img
                      className="img-position-simples"
                      alt="thumbnail"
                      src={
                        !fileStoreThumbnail || fileStoreThumbnail === ''
                          ? placeholder
                          : fileStoreThumbnail
                      }
                    />

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <ImportImage
                        imageDefault={null}
                        autoWidth={true}
                        image={fileStoreThumbnail ?? ''}
                        setImage={setFileStoreThumbnail}
                        ratio={4 / 3}
                      />
                    </div>
                  </div>

                  {/* Lista de fotos */}
                  <div>
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      color="secondary"
                      className="style-text"
                      style={{ fontWeight: '550' }}
                    >
                      Lista de Fotos (aparecerá em "Detalhe da Oferta")
                    </Typography>

                    <section className="grid-style">
                      <div
                        className="Foto-detalhe"
                        style={{
                          width: '100%',
                          maxWidth: '400px',
                          position: 'relative',
                          top: '0',
                          left: '0',
                        }}
                      >
                        <img
                          alt="Lista de Fotos"
                          src={listaFotos}
                          style={{
                            width: '100%',
                            maxWidth: '392px',
                            position: 'relative',
                            top: '0',
                            left: '0',
                          }}
                        />

                        <StyledBadge2
                          style={{
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            height: '100%',
                            width: '100%',
                          }}
                          badgeContent={
                            <Tooltip
                              title={
                                fileStorePhoto && fileStorePhoto[0]
                                  ? 'Para trocar clique em uma imagem ao lado'
                                  : 'Utilize o botão "Adicionar fotos"'
                              }
                              style={{ height: '100%', width: '100%' }}
                            >
                              <Help color="secondary" style={{ fontSize: 18 }} />
                            </Tooltip>
                          }
                          color="primary"
                        >
                          <img
                            src={
                              !fileStorePhoto || fileStorePhoto === ''
                                ? placeholder
                                : fileStorePhoto
                            }
                            alt="lista de fotos"
                            width="100%"
                            style={{
                              maxWidth: '393px',
                              position: 'absolute',
                              top: '0',
                              left: '0',
                            }}
                          />
                        </StyledBadge2>
                      </div>

                      <div className="lista-detalhes" id="photoList">
                        {photoListFile.map((item, i) => {
                          return (
                            <Grid key={i} item md={10} style={{ marginBottom: '15px' }}>
                              <StyledBadge
                                badgeContent={
                                  <Tooltip title="Excluir">
                                    <Close
                                      style={{ width: '100%', height: '100%' }}
                                      onClick={() => removeImage(i)}
                                    />
                                  </Tooltip>
                                }
                              >
                                <Tooltip title={'Tornar principal'}>
                                  <img
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => mainImage(i)}
                                    src={item ?? placeholder}
                                    width="100%"
                                    height="100%"
                                    alt="Lista de Fotos"
                                  />
                                </Tooltip>
                              </StyledBadge>
                            </Grid>
                          );
                        })}
                      </div>
                    </section>

                    <div style={{ display: 'flex', justifyContent: 'left' }}>
                      <ImportImage
                        imageDefault={null}
                        autoWidth={false}
                        image={''}
                        setImage={photoListChangeStore}
                        ratio={4 / 3}
                      />
                    </div>
                  </div>
                </div>
                <br />
                <Typography
                  style={{
                    marginTop: '50px',
                    marginBottom: '-15px',
                    fontWeight: '700',
                    fontSize: '20px',
                  }}
                  placeholder="Placeholder"
                  variant="h5"
                  gutterBottom
                  color="primary"
                >
                  Informações do Cupom (para uso da promoção)
                </Typography>
                <br />
                {idStore !== null ? (
                  <Box display="flex">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      style={{
                        fontWeight: '550',
                        color: '#fff',
                        textTransform: 'capitalize',
                      }}
                      startIcon={<FileCopy />}
                      onClick={() => copyTemplateIdToClipboard()}
                    >
                      {copyTemplateMessage}
                    </Button>
                  </Box>
                ) : (
                  <></>
                )}
                <br />

                <FormControlLabel
                  control={
                    <Switch
                      checked={sameInformation}
                      onChange={(e) => {
                        setSameInformation(e.target.checked);
                        setGoBack(true);
                      }}
                      color="primary"
                    />
                  }
                  label={'Utilizar as mesmas informações da promoção'}
                />
                <TextField
                  disabled={sameInformation}
                  style={{
                    width: '100%',
                  }}
                  placeholder="Ex: Leve 2 e pague 1"
                  className="input-field"
                  value={sameInformation ? title : titulo}
                  type="text"
                  id="titulo"
                  required={true}
                  label="Título do cupom na carteira"
                  onChange={(e) => {
                    setTitulo(e.target.value);
                    setGoBack(true);
                  }}
                />
                <TextField
                  disabled={sameInformation}
                  style={{ width: '100%' }}
                  placeholder="Ex: Leve 2 produtos e pague 1. (Limite de 60 caracteres)"
                  className="input-field"
                  value={sameInformation ? description : descricao}
                  multiline
                  onChange={(e) => {
                    setDescricao(e.target.value);
                    setGoBack(true);
                  }}
                  type="text"
                  id="descricao"
                  required={true}
                  label="Descrição resumida na carteira"
                />
                <TextField
                  style={{ width: '100%' }}
                  placeholder="Ex: Parabéns, você ganhou um desconto. Apresente este cupom no local e aproveite!"
                  className="input-field"
                  value={detailsDescription ?? ''}
                  multiline
                  onChange={(e) => {
                    setDetailsDescription(e.target.value);
                    setGoBack(true);
                  }}
                  type="text"
                  id="detailsDescription"
                  required={true}
                  label="Descrição detalhada na carteira"
                />
                <br />
                <br />
                <FormControlLabel
                  control={
                    <Switch
                      checked={sameImages}
                      onChange={() => setSameImages(!sameImages)}
                      color="primary"
                    />
                  }
                  label={'Utilizar as mesmas imagens da promoção'}
                />
                {!sameImages && (
                  <Typography
                    style={{
                      marginTop: '10px',
                      marginBottom: '15px',
                      fontWeight: '700',
                      fontSize: '20px',
                    }}
                    variant="h5"
                    gutterBottom
                    color="primary"
                  >
                    Fotos do cupom na carteira
                  </Typography>
                )}
                {!sameImages && (
                  <div className="flex-external">
                    <div
                      className="img-thumb"
                      style={{
                        maxWidth: '400px',
                        position: 'relative',
                        top: '0',
                        left: '0',
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        color="secondary"
                        style={{ fontWeight: '550', textAlign: 'center' }}
                      >
                        Thumbnail (aparecerá na "Carteira")
                      </Typography>

                      <img
                        alt="Thumbnail"
                        src={cuponsValidos}
                        style={{
                          width: '100%',
                          maxWidth: '400px',
                          position: 'relative',
                          top: '0',
                          left: '0',
                        }}
                      />

                      <img
                        alt="thumbnail"
                        className="img-position-carteira"
                        src={
                          !fileThumbnail || fileStoreThumbnail === '' ? placeholder : fileThumbnail
                        }
                      />

                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <ImportImage
                          imageDefault={fileThumbnail ?? ''}
                          autoWidth={false}
                          image={fileThumbnail}
                          setImage={setFileThumbnail}
                          ratio={4 / 3}
                        />
                      </div>
                    </div>

                    <div
                      className="img-thumb"
                      style={{
                        maxWidth: '400px',
                        position: 'relative',
                        top: '0',
                        left: '0',
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        color="secondary"
                        style={{ fontWeight: '550', textAlign: 'center' }}
                      >
                        Foto detalhada (aparecerá em "Detalhe do Cupom")
                      </Typography>

                      <img
                        alt="Placeholder lista img"
                        src={nomeCupom}
                        style={{
                          maxWidth: '100%',
                          position: 'relative',
                          top: '0',
                          left: '0',
                        }}
                      />

                      <img
                        alt="Foto detalhada"
                        width="100%"
                        style={{ position: 'absolute', left: '0' }}
                        src={!fileFoto || fileFoto === '' ? placeholder : fileFoto}
                      />

                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <ImportImage
                          imageDefault={null}
                          autoWidth={false}
                          image={fileFoto ?? ''}
                          setImage={setFileFoto}
                          ratio={4 / 3}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <Typography
                  style={{
                    marginTop: '60px',
                    marginBottom: '-10px',
                    fontWeight: '700',
                    fontSize: '20px',
                  }}
                  variant="h5"
                  gutterBottom
                  color="primary"
                >
                  Informação do Cupom (para uso da promoção)
                </Typography>
                <br />
                <br />
                <FormControl className="input-field">
                  <RadioGroup
                    aria-label="consumo"
                    name="consumo"
                    value={consumo}
                    onChange={(e) => handleChange('consumo', e)}
                  >
                    <FormControlLabel
                      value="posterior"
                      control={<Radio color="primary" />}
                      label="Na carteira (Cupom enviado à carteira para consumo posterior)"
                      labelPlacement="end"
                    />

                    <FormControlLabel
                      value="imediato"
                      control={<Radio color="primary" />}
                      label="Imediato (Cupom consumido imediatamente)"
                      labelPlacement="end"
                    />
                  </RadioGroup>

                  {consumo === 'imediato' && (
                    <RadioGroup
                      aria-label="couponGetMode"
                      name="couponGetMode"
                      value={couponGetMode}
                      onChange={(e) => handleChange('couponGetMode', e)}
                      style={{ marginLeft: 50 }}
                    >
                      <FormControlLabel
                        value="simpleGetMode"
                        control={<Radio color="primary" />}
                        label="Mostrar QR code para validação do Estabelecimento."
                        labelPlacement="end"
                      />

                      <FormControlLabel
                        value="scannerMode"
                        control={<Radio color="primary" />}
                        label="Escanear QR code (Necessita escanear um QR code)"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  )}

                  <FormControlLabel
                    control={
                      <Switch
                        checked={consumoUnico}
                        onChange={(event) => {
                          setHasSimultaneo(event.target.checked);
                          setConsumoUnico(event.target.checked);
                          setGoBack(true);

                          if (!hasSimultaneo) {
                            setQtdSimultaneo(1);
                          }
                        }}
                        color="primary"
                      />
                    }
                    label={'Promoção de consumo único por usuário'}
                  />

                  <Typography
                    style={{
                      fontWeight: '400',
                      fontSize: '1rem',
                      marginTop: '30px',
                    }}
                    gutterBottom
                  >
                    Quantidade máxima de cupons não consumidos na carteira do usuário
                  </Typography>
                  <TextField
                    style={{
                      width: '80px',
                      padding: '8px',
                      marginLeft: '-6px',
                    }}
                    className="input-field"
                    disabled={consumo === 'imediato' || hasSimultaneo}
                    onChange={(event) => {
                      const value = parseInt(event.target.value);

                      if (value < 1) toast.error('Este valor não pode ser negativo.');
                      else {
                        setQtdSimultaneo(value);
                        setGoBack(true);
                      }
                    }}
                    value={qtdSimultaneo === null ? '' : qtdSimultaneo}
                    type="number"
                    id="qtdSimultaneo"
                    required
                  />

                  <Typography
                    style={{
                      fontWeight: '400',
                      fontSize: '1rem',
                      marginTop: '30px',
                    }}
                    gutterBottom
                  >
                    Tempo de espera para pegar outro cupom
                  </Typography>
                  <Grid container item lg={8}>
                    <Grid item lg={1}>
                      <TextField
                        style={{ width: '60px' }}
                        className="input-field"
                        value={cooldownDays ?? ''}
                        onChange={(e) => handleChange('cooldownDays', e)}
                        type="number"
                        id="cooldownDays"
                        required={true}
                        label="Dias"
                      />
                    </Grid>
                    <Grid item lg={1}>
                      <TextField
                        style={{ width: '60px' }}
                        className="input-field"
                        value={cooldownHours ?? ''}
                        onChange={(e) => handleChange('cooldownHours', e)}
                        type="number"
                        id="cooldownHours"
                        required={true}
                        label="Horas"
                      />
                    </Grid>
                    <Grid item lg={1}>
                      <TextField
                        style={{ width: '70px' }}
                        className="input-field"
                        value={cooldownMinutes ?? ''}
                        onChange={(e) => handleChange('cooldownMinutes', e)}
                        type="number"
                        id="cooldownMinutes"
                        required={true}
                        label="Minutos"
                      />
                    </Grid>
                  </Grid>
                </FormControl>
                <FormControl className="input-field">
                  <br />
                  <section>
                    <Grid container spacing={2} alignItems="stretch">
                      <Grid item sm={12} md={12}>
                        <Typography
                          style={{
                            fontWeight: '400',
                            fontSize: '1rem',
                          }}
                          gutterBottom
                        >
                          Data de ativação
                        </Typography>

                        <TextField
                          id="enableDate"
                          type="datetime-local"
                          value={enableDate === null ? '' : enableDate}
                          InputLabelProps={{ shrink: true }}
                          onChange={(e) => handleChange('enableDate', e)}
                          required={true}
                        />
                      </Grid>
                      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '15px' }}>
                        <FormControlLabel
                          style={{ marginTop: '10px' }}
                          control={
                            <Switch
                              checked={unlimitedDisableDate}
                              onChange={(e) => {
                                setUnlimitedDisableDate(e.target.checked);
                                setGoBack(true);
                              }}
                              color="primary"
                            />
                          }
                          label={'Desabilitar data de desativação programada'}
                        />
                        <div
                          style={{
                            color: 'red',
                            fontSize: '0.7rem',
                            fontWeight: 400,
                            padding: 0,
                          }}
                        >
                          *Se a data de desativação for maior que 5 anos esse botão será habilitado
                          automaticamente.
                        </div>
                      </div>

                      <Grid
                        item
                        sm={12}
                        md={12}
                        style={{
                          visibility: unlimitedDisableDate ? 'hidden' : 'visible',
                          width: '100%',
                        }}
                      >
                        <Typography
                          style={{
                            fontWeight: '400',
                            fontSize: '1rem',
                          }}
                          gutterBottom
                        >
                          Data de desativação
                        </Typography>
                        <TextField
                          id="disableDate"
                          name="disableDate"
                          type="datetime-local"
                          value={disableDate ?? ''}
                          InputLabelProps={{ shrink: true }}
                          onChange={(e) => handleChange('disableDate', e)}
                          required={true}
                        />
                      </Grid>
                    </Grid>
                  </section>

                  <br />
                  <br />
                </FormControl>
              </FormGroup>
              {/* para promoções novas */}
              {wherePromo === null && (
                <>
                  <Button
                    style={{
                      fontWeight: '550',
                      marginTop: '50px',
                      color: 'white',
                      textTransform: 'capitalize',
                    }}
                    variant="contained"
                    color="secondary"
                    onClick={() => goBackFunc()}
                    startIcon={<ArrowBack />}
                  >
                    Voltar
                  </Button>

                  <Button
                    disabled={disableButtonUpdate}
                    style={{
                      fontWeight: '550',
                      marginTop: '50px',
                      marginLeft: '10px',
                      color: 'white',
                      textTransform: 'capitalize',
                    }}
                    variant="contained"
                    color="primary"
                    type="submit"
                    startIcon={<DoneAll />}
                  >
                    {isAdminPage()
                      ? 'Salvar'
                      : !idStore
                      ? 'Solicitar Aprovação'
                      : aprovacao && aprovacao.status === 'negado'
                      ? 'Solicitar Revisão'
                      : 'Atualizar Informação'}
                  </Button>
                </>
              )}

              {/* para promoções existentes */}
              {wherePromo === 'estabelecimento-promo' && (
                <>
                  <Button
                    style={{
                      fontWeight: '550',
                      marginTop: '50px',
                      color: 'white',
                      textTransform: 'capitalize',
                    }}
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      localStorage.removeItem('statusPromotion');
                      goBackFunc();
                    }}
                    startIcon={<ArrowBack />}
                  >
                    Voltar
                  </Button>

                  <Button
                    disabled={disableButtonUpdate}
                    style={{
                      fontWeight: '550',
                      marginTop: '50px',
                      marginLeft: '10px',
                      color: 'white',
                      textTransform: 'capitalize',
                    }}
                    variant="contained"
                    color="primary"
                    type="submit"
                    startIcon={<DoneAll />}
                  >
                    {isAdminPage()
                      ? 'Salvar'
                      : !idStore
                      ? 'Solicitar Aprovação'
                      : aprovacao && aprovacao.status === 'negado'
                      ? 'Solicitar Revisão'
                      : 'Atualizar Informação'}
                  </Button>
                </>
              )}
            </form>
          </>
        )}

        {wherePromo === 'curadoria-promo' && (
          <S.CardContainer>
            <S.SideContainer>
              <S.SectionLabelContainer style={{ padding: '0rem 2rem 2rem 2rem' }}>
                <Box sx={{ paddingBottom: '4rem' }}>
                  <S.TitleH2>Campo de Mensagem</S.TitleH2>
                </Box>

                <Box>
                  <form onSubmit={(e) => e.preventDefault()}>
                    <S.TagsLabelContainer style={{ marginTop: '0rem' }}>
                      Mensagem de Avaliação !
                    </S.TagsLabelContainer>

                    <br />
                    <D.TriiboTextField
                      disabled={status !== 'aberto'}
                      rows={1}
                      placeholder={'Mensagem...'}
                      maxLength="40"
                      onChange={(e) => setComment(e.target.value)}
                      value={comment === '' ? '' : comment}
                    />
                    <div>
                      <S.TagsAddButton
                        disabled={status !== 'aberto' ? true : false || isApproveButtonDisabled}
                        onClick={(e) => {
                          handlePromotionApproval(e, true);
                        }}
                        style={{
                          marginTop: '20px',
                        }}
                      >
                        {isApproveButtonDisabled ? (
                          <CircularProgress size={13} color={'inherit'} />
                        ) : (
                          'Aprovar'
                        )}
                      </S.TagsAddButton>

                      <S.FailButton
                        disabled={status !== 'aberto' ? true : false || isApproveButtonDisabled}
                        onClick={(e) => {
                          handlePromotionApproval(e, false);
                        }}
                        style={{
                          marginLeft: '10px',
                          marginTop: '20px',
                        }}
                      >
                        {isApproveButtonDisabled ? (
                          <CircularProgress size={13} color={'inherit'} />
                        ) : (
                          'Reprovar'
                        )}
                      </S.FailButton>
                    </div>
                  </form>
                </Box>
              </S.SectionLabelContainer>
            </S.SideContainer>
          </S.CardContainer>
        )}

        {/* ALERTS */}
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {'Deseja voltar a página anterior?'}
          </DialogTitle>

          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              As alterações realizadas não serão salvas.
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary">
              cancelar
            </Button>
            <Button onClick={() => history.goBack()} color="primary">
              voltar
            </Button>
          </DialogActions>
        </Dialog>
        {/* FIM ALERTS */}
      </div>
    );
  }
}

export default StorePromotionExternal;

