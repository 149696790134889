import { withStyles } from '@mui/styles';
import { Badge, colors } from '@mui/material';

// Estilo da notificação - Badge
const StyledBadge = withStyles((theme) => ({
  badge: {
    fontSize: '12px',
    color: '#6c6c6c',
    borderRadius: '50%',
    height: '20px',
    width: '20px',
    right: -2,
    top: 3,
    border: '1px solid #6c6c6c',
    backgroundColor: '#fff',
    padding: '0',

    '&:hover': {
      backgroundColor: colors['red'][700],
      color: 'white',
      cursor: 'pointer',
      border: 'none',
    },
  },
}))(Badge);

export default StyledBadge;

