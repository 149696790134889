import React, { useState, useEffect, useRef } from 'react';

import { connect } from 'react-redux';
import {
  Typography,
  Grid,
  Input,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Button,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Modal,
  Paper,
} from '@mui/material';
import * as S from './styles';
import * as D from 'js/components/DesignSystem/styles';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import { Cancel } from '@mui/icons-material';

import Loading from 'js/containers/Loading/Loading';
import { getUserInfo } from 'js/library/utils/helpers';
import { userReadCellphone_v3 } from 'js/library/utils/API/apiUserRead';
import { findValueInArray, isAdminPage } from 'js/library/utils/helpers';
import { voucherSendAction } from 'js/core/actions/voucherSendAction';
import { SendVoucherBatch } from 'js/library/utils/API/Store/apiSendVoucher';
import { getVoucherTemplate } from 'js/library/utils/API/getVoucherTemplate';
import { processOrder } from 'js/library/utils/API/apiCreateOrder';
import { uploadFiles } from 'js/library/utils/API/apiUploadFiles';
import { getUsersByCellphoneFile } from 'js/library/utils/API/getUsersByCellphoneFile';
import cfac22 from 'js/library/utils/cfac22';
import { toast } from 'react-toastify';

// sobe o arquivo com a lista de usuários para a pasta 'post-users-cellphones' em storage
const uploadingFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const base64String = btoa(String.fromCharCode(...new Uint8Array(reader.result)));

      uploadFiles('post-users-cellphones', {
        mimeType: file.type,
        fileName: file.type === 'text/plain' ? 'usuarios.txt' : 'usuarios.csv',
        buffer: base64String,
      })
        .then((res) => {
          const response = res.split('/');
          const path = 'post-users-cellphones/' + response[response.length - 1].split('?')[0];
          resolve(path);
        })
        .catch((err) => {
          reject('');
        });
    };

    reader.onerror = () => {
      reject('');
    };

    reader.readAsArrayBuffer(file);
  });
};

const TextMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={[
        '+',
        5,
        5,
        ' ',
        '(',
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
    />
  );
};

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const Transition = (props) => {
  return <Slide direction="up" {...props} />;
};

const VoucherSendExternal = ({
  location,
  toggleSendVoucherModal,
  openSendVoucherModal,
  openModalSelectClients,
  loading,
}) => {
  // const { establishmentId, establishmentName } = location.state;

  const [establishmentId, setEstablishmentId] = useState('');
  const statePromotion = JSON.parse(localStorage.getItem('statePromotion'));
  const [establishmentName, setEstablishmentName] = useState('');
  const [clientList, setClientList] = useState([]);
  const [clientListByWalletSelect, setClientListByWalletSelect] = useState([]);
  const [clientPhone, setClientPhone] = useState('');
  const [templateId, setTemplateId] = useState('');
  // const [templateId] = useState(location.state.templateId);
  const [generator, setGenerator] = useState(false);
  const [qtdDisponivel, setQtdDisponivel] = useState(null);
  const [lat, setLat] = useState(null);
  const [long, setLong] = useState(null);
  const [batchId, setBatchId] = useState(null);
  // const [batchList, setBatchList] = useState(null)
  // const [groupBatchList, setGroupBatchList] = useState({})
  // const [finalBatchList, setFinalBatchList] = useState({})
  const [openDialog, setOpenDialog] = useState(false);
  const [informationLoading, setInformationLoading] = useState(false);
  const [searchUserLoading, setSearchUserLoading] = useState(false);
  const [uploadFile, setUploadFile] = useState(false);
  const [promotionType, setPromotionType] = useState('');

  const fileUploader = useRef(null);

  useEffect(() => {
    //Baixando dados da Voucher Template para controle da quantidade de Vouchers
    getVoucherTemplate(templateId).then((dataReceived) => {
      if (dataReceived.result) {
        setGenerator(dataReceived.result.generator);
        setQtdDisponivel(dataReceived.result.qtdDisponivel);
        setBatchId(dataReceived.generator ? 'AUTO' : null);
        setLat(dataReceived.result.lat);
        setLong(dataReceived.result.long);
      }
    });
    window.pathname = null;
  }, [templateId, clientList]);

  useEffect(() => {
    const clientList = JSON.parse(localStorage.getItem('ClientsSelect'));

    if (clientList && statePromotion) {
      setEstablishmentId(clientList.location.dataEstabelecimento.id);
      setEstablishmentName(clientList.location.dataEstabelecimento.nome);
      setTemplateId(statePromotion.templateId);
      setClientListByWalletSelect(clientList.clients);

      const promoType = statePromotion.hasGroup
        ? 'Promoção Fidelidade'
        : statePromotion.type === 'promotion'
        ? 'Promoção'
        : statePromotion.type === 'product'
        ? 'Vitriine'
        : statePromotion.type === 'businessPartner' && 'Site externo';

      setPromotionType(promoType);
    } else {
      const { establishmentId, establishmentName } = location.state;
      setTemplateId(location.state.templateId);
      setEstablishmentId(establishmentId);
      setEstablishmentName(establishmentName);

      const promoType = location.state.hasGroup
        ? 'Promoção Fidelidade'
        : location.state.type === 'promotion'
        ? 'Promoção'
        : location.state.type === 'product'
        ? 'Vitriine'
        : location.state.type === 'businessPartner' && 'Site externo';

      setPromotionType(promoType);
    }
  }, []);

  const searchUser = (cellphone) => {
    setSearchUserLoading(true);

    cellphone = '+55' + cellphone.replace(/\+55/g).replace(/\D+/g, '');

    if (cellphone.length >= 14) {
      userReadCellphone_v3(cellphone)
        .then((result) => {
          if (result) {
            const newClientList = [...clientList];
            newClientList.push(result.userInfo);
            setClientList(newClientList);
          } else {
            toast.error('Usuário não encontrado.');
          }
          setSearchUserLoading(false);
        })
        .catch((error) => {
          setSearchUserLoading(false);
          toast.error('Erro ao buscar usuário.');
        });
    } else {
      setSearchUserLoading(false);
    }
  };

  const goBack = () => {
    toggleSendVoucherModal(false);
  };

  const checkInformation = (voucherConsumed, clientIndex) => {
    if (qtdDisponivel <= 0) {
      toast.error('Essa promoção não possui cupons restantes.');
      return;
    }

    setInformationLoading(true);

    const client = clientList[clientIndex];
    const currentDate = new Date().getTime();

    // triiboId do usuário logado
    const loggedUserTriiboId = getUserInfo().triiboId;
    const loggedUserUId = getUserInfo().uId;

    const payload = {
      orderObject: {
        sender: loggedUserTriiboId,
        itemId: templateId,
        requestLat: lat,
        requestLon: long,
        requestDate: currentDate,
        type: statePromotion.type,
        receiver: client.triiboId,
      },
      consumed: voucherConsumed,
    };

    processOrder(loggedUserUId, payload)
      .then(() => {
        setInformationLoading(false);
        toast.success(`Promoção ${voucherConsumed ? 'consumida' : 'enviada'} com sucesso.`);
        const newClientList = [...clientList];
        newClientList.splice(clientIndex, 1);
        setClientList(newClientList);
      })
      .catch((e) => {
        setInformationLoading(false);
        toast.error('Erro ao enviar promoção: ' + e.response.data.error);
      });
  };

  const checkInformations = (voucherConsumed) => {
    if (!uploadFile) {
      if (clientListByWalletSelect.length > qtdDisponivel || (!generator && qtdDisponivel <= 0)) {
        toast.error('Essa promoção não possui cupons restantes.');
        return;
      } else {
        setInformationLoading(true);

        let obj = {};

        const clientList = clientListByWalletSelect.map((client) => {
          return {
            clientID: client,
            templateID: templateId,
            consumed: voucherConsumed,
          };
        });

        obj.vouchers = clientList;

        SendVoucherBatch(obj)
          .then((res) => {
            toast.success(`Promoções ${voucherConsumed ? 'consumidas' : 'enviadas'} com sucesso.`);
            setInformationLoading(false);
            setClientListByWalletSelect([]);
          })
          .catch((error) => {
            alert('erro');
          });
      }
    } else {
      if (clientList.length > qtdDisponivel || (!generator && qtdDisponivel <= 0)) {
        toast.error('Essa promoção não possui cupons restantes.');
        return;
      } else {
        let data = [];
        let obj = {};

        clientList.map((client, i) => {
          const cellPhone = findValueInArray(client.contactList, 'type', 'cellPhone').value;

          data.push({
            clientID: cellPhone + '@sms,triibo,com,br',
            templateID: templateId,
            consumed: voucherConsumed,
          });

          return;
        });

        obj.vouchers = data;

        SendVoucherBatch(obj)
          .then((res) => {
            toast.success(`Promoções ${voucherConsumed ? 'consumidas' : 'enviadas'} com sucesso.`);
            setInformationLoading(false);
            setClientList([]);
          })
          .catch((error) => {
            toast.error('Erro ao enviar promoções');
          });
      }
    }
  };

  const handleFileChange = async (e) => {
    let fileTxt;
    try {
      setUploadFile(true);

      const filePathUploaded = await uploadingFile(e.target.files[0]);

      const payload = {
        filePath: filePathUploaded,
        fileFolderName: 'post-users-uid',
      };

      const response = await getUsersByCellphoneFile(payload);
      fileTxt = response.fileOutputPath;

      if (response.usersNotFound && response.usersNotFound.length > 0) {
        toast.error(
          `${response.usersNotFound.length} usuário(s) listado(s) no arquivo não existem.`
        );
      }
      fetchFile(cfac22('STORAGE_URL') + encodeURIComponent(fileTxt) + '?alt=media');
    } catch (err) {
      setUploadFile(false);
      toast.error('Erro ao carregar arquivo');
    }
  };

  async function fetchFile(url) {
    try {
      const response = await fetch(url);
      const text = await response.text();

      processFile(text);
    } catch (error) {
      console.error('Erro ao buscar o arquivo:', error);
    }
  }

  function processFile(content) {
    const lines = content.split('\n');
    const parsedData = lines.map((line) => {
      const [uid, cellPhone, name] = line.split(';');
      return {
        uid,
        contactList: [{ type: 'cellPhone', value: cellPhone }],
        name,
        triiboId: cellPhone + '@sms,triibo,com,br',
      };
    });
    const finalData = parsedData.filter((item) => item.uid !== '');
    setClientList([...clientList, ...finalData]);
  }

  return (
    <>
      <Modal
        open={openSendVoucherModal}
        onClose={() => {
          localStorage.removeItem('ClientsSelect');
          localStorage.removeItem('statePromotion');
          toggleSendVoucherModal(false);
        }}
        style={{
          padding: 15,
          boxSizing: 'border-box',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Paper
          style={{
            width: 800,
            maxWidth: '90%',
            maxHeight: '90%',
            padding: '50px 20px 30px 20px',
            boxSizing: 'border-box',
            overflowY: 'auto',
            position: 'relative',
          }}
        >
          {(loading && informationLoading) || searchUserLoading ? (
            <div
              style={{
                width: 200,
                maxWidth: '100%',
                margin: '0 auto',
                display: 'block',
              }}
            >
              <Loading />
            </div>
          ) : (
            <Grid container>
              <Grid item md={12} sm={12} xs={12}>
                <Cancel
                  color="secondary"
                  onClick={() => setOpenDialog(true)}
                  style={{
                    cursor: 'pointer',
                    top: 20,
                    right: 20,
                    position: 'absolute',
                  }}
                />

                {clientListByWalletSelect.length > 0 ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignContent: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      gap: '1rem',
                    }}
                  >
                    <h3 style={{ textAlign: 'center' }}>
                      {clientListByWalletSelect.length} clientes selecionados, escolha a ação
                      desejada.
                    </h3>
                    <div
                      style={{
                        margin: '0 auto',
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        gap: '1rem',
                      }}
                    >
                      {promotionType !== 'Promoção Fidelidade' && (
                        <Button
                          onClick={() => checkInformations(false)}
                          style={{
                            minWidth: 160,
                            margin: '0 5px',
                            fontWeight: '550',
                            color: 'white',
                            textTransform: 'capitalize',
                            whiteSpace: 'nowrap',
                          }}
                          variant="contained"
                          color="primary"
                          type="button"
                        >
                          Enviar todos
                        </Button>
                      )}

                      <Button
                        onClick={() => checkInformations(true)}
                        style={{
                          minWidth: 160,
                          margin: '0 5px',
                          fontWeight: '550',
                          color: 'white',
                          textTransform: 'capitalize',
                          whiteSpace: 'nowrap',
                        }}
                        variant="contained"
                        color="primary"
                        type="button"
                      >
                        Consumir todos
                      </Button>
                      <Button
                        onClick={() => {
                          openModalSelectClients();
                          toggleSendVoucherModal(false);
                        }}
                        style={{
                          minWidth: 160,
                          margin: '0 5px',
                          fontWeight: '550',
                          color: 'white',
                          textTransform: 'capitalize',
                          whiteSpace: 'nowrap',
                        }}
                        variant="contained"
                        color="primary"
                        type="button"
                      >
                        Editar
                      </Button>
                    </div>
                  </div>
                ) : (
                  <>
                    <S.HeaderModalContainer>
                      <Typography
                        variant="h5"
                        gutterBottom
                        color="primary"
                        style={{ fontWeight: '600' }}
                      >
                        Pesquisar usuário
                      </Typography>
                      <S.Button
                        onClick={() => {
                          openModalSelectClients();

                          toggleSendVoucherModal(false);
                        }}
                      >
                        <D.TriiboH5>Selecionar clientes</D.TriiboH5>
                      </S.Button>
                    </S.HeaderModalContainer>
                    <FormControl fullWidth>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          searchUser(clientPhone);
                        }}
                      >
                        <Input
                          fullWidth={true}
                          value={clientPhone}
                          autoFocus
                          inputComponent={TextMaskCustom}
                          placeholder="Digite o celular do cliente e pressione Enter"
                          onChange={(e) => setClientPhone(e.target.value)}
                        />
                      </form>
                    </FormControl>

                    {clientList.length > 0 && (
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">Nome</TableCell>
                            <TableCell align="center">Telefone</TableCell>
                            <TableCell align="right">
                              {clientList.length > 1 && (
                                <>
                                  {promotionType !== 'Promoção Fidelidade' && (
                                    <Button
                                      onClick={() => checkInformations(false)}
                                      style={{
                                        minWidth: 160,
                                        margin: '0 5px',
                                        fontWeight: '550',
                                        color: 'white',
                                        textTransform: 'capitalize',
                                        whiteSpace: 'nowrap',
                                      }}
                                      variant="contained"
                                      color="secondary"
                                      type="button"
                                    >
                                      Enviar todos
                                    </Button>
                                  )}

                                  <Button
                                    onClick={() => checkInformations(true)}
                                    style={{
                                      minWidth: 160,
                                      margin: '0 5px',
                                      fontWeight: '550',
                                      color: 'white',
                                      textTransform: 'capitalize',
                                      whiteSpace: 'nowrap',
                                    }}
                                    variant="contained"
                                    color="secondary"
                                    type="button"
                                  >
                                    Consumir todos
                                  </Button>
                                </>
                              )}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {clientList.map((client, index) => {
                            try {
                              const cellPhone = findValueInArray(
                                client.contactList,
                                'type',
                                'cellPhone'
                              ).value;

                              return (
                                <TableRow key={index}>
                                  <TableCell align="left">{client.name}</TableCell>
                                  <TableCell align="center">{cellPhone}</TableCell>
                                  <TableCell align="right">
                                    {promotionType !== 'Promoção Fidelidade' && (
                                      <Button
                                        onClick={() => checkInformation(false, index)}
                                        style={{
                                          minWidth: 160,
                                          margin: '0 5px',
                                          fontWeight: '550',
                                          color: 'white',
                                          textTransform: 'capitalize',
                                          whiteSpace: 'nowrap',
                                        }}
                                        variant="contained"
                                        color="primary"
                                        type="button"
                                      >
                                        Enviar cupom
                                      </Button>
                                    )}

                                    <Button
                                      onClick={() => checkInformation(true, index)}
                                      style={{
                                        minWidth: 160,
                                        margin: '0 5px',
                                        fontWeight: '550',
                                        color: 'white',
                                        textTransform: 'capitalize',
                                        whiteSpace: 'nowrap',
                                      }}
                                      variant="contained"
                                      color="primary"
                                      type="button"
                                    >
                                      Consumir cupom
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              );
                            } catch (err) {
                              return null;
                            }
                          })}
                        </TableBody>
                      </Table>
                    )}

                    <div align="center" style={{ marginTop: 20 }}>
                      <input
                        type="file"
                        accept=".txt"
                        ref={fileUploader}
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                      />
                      {isAdminPage() && (
                        <Button
                          onClick={() => fileUploader.current.click()}
                          style={{
                            marginLeft: 5,
                            marginRight: 5,
                            fontWeight: '550',
                            color: 'white',
                            textTransform: 'capitalize',
                          }}
                          variant="contained"
                          color="secondary"
                          type="button"
                        >
                          Carregar arquivo
                        </Button>
                      )}
                      <Button
                        onClick={() => searchUser(clientPhone)}
                        style={{
                          marginLeft: 5,
                          marginRight: 5,
                          fontWeight: '550',
                          color: 'white',
                          textTransform: 'capitalize',
                        }}
                        variant="contained"
                        color="primary"
                        type="button"
                      >
                        Pesquisar
                      </Button>
                    </div>
                  </>
                )}
              </Grid>

              <Dialog
                open={openDialog}
                disableEnforceFocus
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                  localStorage.removeItem('ClientsSelect');
                  localStorage.removeItem('statePromotion');
                  setOpenDialog(false);
                }}
              >
                <DialogTitle>Deseja fechar?</DialogTitle>
                <DialogContent>
                  <DialogContentText style={{ padding: 0 }}>
                    As alterações realizadas não serão salvas.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setOpenDialog(false);
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    onClick={() => {
                      localStorage.removeItem('ClientsSelect');
                      localStorage.removeItem('statePromotion');
                      goBack();
                    }}
                  >
                    Fechar
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          )}
        </Paper>
      </Modal>
    </>
  );
};

//recebe as props dos Reducers
const mapStateToProps = (state) => {
  return {
    loading: state.voucherSendComponent.loading,
    success: state.voucherSendComponent.success,
    error: state.voucherSendComponent.error,
  };
};

//envia as props para as Actions
const mapDispatchToProps = (dispatch) => ({
  voucherSendComponent: (templateId, batchId, emailFile, consumed, callback) =>
    voucherSendAction(dispatch, templateId, batchId, emailFile, consumed, callback),
});

export default connect(mapStateToProps, mapDispatchToProps)(VoucherSendExternal);
