import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DebounceInput } from 'react-debounce-input';
import { useDispatch } from 'react-redux';

import {
  Button,
  Typography,
  Grid,
  Menu,
  MenuItem,
  InputLabel,
  Switch,
  CircularProgress,
  Box,
  Tab,
  Tabs,
} from '@mui/material';

import { Search, Add } from '@mui/icons-material';
import { duplicatePromotionAction } from 'js/core/actions/duplicatePromotionAction';
import { downloadImage } from 'js/library/services/StorageManager';
import placeholder from 'styles/assets/placeholder/placeholder_thumbnail.png';
import DataTableStoreExternal from 'js/containers/DataTable/DataTableStoreExternal';
import VoucherSendExternal from 'js/components/Store/VoucherSend/VoucherSendExternal';
import { getUserInfo } from 'js/library/utils/helpers';
import ModalSelectClients from './VoucherSend/ModalSelectClients';
import { HeaderTitle } from '../Configurations/CreateClub/RelationshipClub/HeaderTitle';
import { GoBackHeader } from '../Configurations/CreateClub/RelationshipClub/GoBackHeader';
import { getStoreByEstablishment } from 'js/library/utils/API/Store/apiGetStoreByEstablishment';
import { useSelector } from 'react-redux';

const StoreListExternal = (props) => {
  localStorage.setItem('fromWherePromotion', 'estabelecimento-promo');
  localStorage.removeItem('statusPromotion');
  delete window.promoListOrganized;

  const [establishmentId] = useState(props.match.params.id);
  const [dataEstabelecimento] = useState(props.location.state);

  const [rows] = useState([
    { id: 'thumbnail', numeric: 'false', disablePadding: true, label: 'Thumbnail' },
    { id: 'promocao', numeric: 'false', disablePadding: false, label: 'Nome da Promoção' },
    { id: 'type', numeric: 'false', disablePadding: true, label: 'Tipo da Promoção' },
    { id: 'active', numeric: 'false', disablePadding: false, label: 'Status' },
    { id: 'acao', numeric: 'false', disablePadding: false, label: 'Ação' },
  ]);
  const [promotionList, setPromotionList] = useState([]);
  const [promotionListInactive, setPromotionListInactive] = useState([]);
  const [search, setSearch] = useState('');
  const [pathName] = useState('');
  const [openSendVoucherModal, setOpenSendVoucherModal] = useState(false);
  const [n, setN] = useState(null);
  const [isActive, setIsActive] = useState(0);
  const [isDisableCheck, setIsDisableCheck] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [modalSelectClientsIsOpen, setModalSelectClientsIsOpen] = useState(false);
  const [adminToggle, setAdminToggle] = useState(() => {
    return localStorage.getItem('adminToggle') === 'true';
  });
  const [allPromotionList, setAllPromotionList] = useState([]);
  const [allPromotionListInactive, setAllPromotionListInactive] = useState([]);
  const [loadingPromotionList, setLoadingPromotionList] = useState(false);
  const isUserAdmin = useSelector((state) => state.authModel.superUser);
  const [anchorEl, setAnchorEl] = useState(null);
  const [pagesAccessed, setPagesAccessed] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const dispatch = useDispatch();

  const duplicatePromotion = (storeKey) => {
    dispatch(duplicatePromotionAction(dispatch, storeKey));
  };

  useEffect(() => {
    // como carrega as promoções ativas primeiro, setando o valor de 0, para poder usar em outros lugares
    localStorage.setItem('Lista de promoções', 0);
  }, []);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoadingPromotionList(true);
        setLoadingList(true);

        // 'from' é a página atual multiplicada pelo tamanho das linhas (sempre 10)
        const from = page * 10;

        // O size (rowsPerPage) = quantidade de promoções que retorna em cada requisição.
        const size = 10;

        const dataReceived = await getStoreByEstablishment(
          establishmentId,
          from, // O 'from' começa a partir da página atual
          size,
          isActive === 0 ? true : false
        );

        const dataPromotions = [];

        dataReceived.forEach((data) => {
          const [, item] = Object.entries(data)[0];
          item.storeInfo.key = item.storeInfo.id;
          item.storeInfo['downloadedThumb'] = placeholder;

          if (!item.storeInfo?.id) {
            const establishmentIdKey = Object.keys(data)[0];
            item.storeInfo.id = establishmentIdKey;
          }

          // se existir storeApprovals, cria o obj "aprovacao" que depois vai pra promotionList
          if (item.storeApprovals) {
            item.storeInfo.aprovacao = item.storeApprovals;
          }

          // se existir storeEdits, cria o obj "edicao" que depois vai pra promotionList
          if (item.storeEdits) {
            item.storeInfo.edicao = item.storeEdits;
          }

          dataPromotions.push(item.storeInfo);
        });

        if (isActive === 0) {
          setPromotionList((prev) => [...prev, ...dataPromotions]);
          setAllPromotionList((prev) => [...prev, ...dataPromotions]);
        } else {
          setPromotionListInactive((prev) => [...prev, ...dataPromotions]);
          setAllPromotionListInactive((prev) => [...prev, ...dataPromotions]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingPromotionList(false);
        setLoadingList(false);
      }
    };

    if (!pagesAccessed.includes(page) || pagesAccessed.length === 0) {
      setPagesAccessed((prev) => [...prev, page]);
      loadData();
    }
    // eslint-disable-next-line
  }, [establishmentId, page, isActive]);

  const downloadImg = (fotoThumb, key, downloadedThumb, type) => {
    if (fotoThumb !== undefined && downloadedThumb === placeholder) {
      const image = fotoThumb.split('/');
      const indexPromotionList = promotionList.findIndex((x) => x.key === key);
      const indexPromotionListInactive = promotionListInactive.findIndex((x) => x.key === key);

      const updateList = (list, index, downloadedImage, setList) => {
        const updatedList = [...list];
        updatedList[index].downloadedThumb = downloadedImage;
        setList(updatedList);
      };

      const handleDownload = (imgType, imgPath) => {
        downloadImage(imgType, imgPath)
          .then((downloadedImage) => {
            if (isActive === 0 && indexPromotionList !== -1) {
              updateList(promotionList, indexPromotionList, downloadedImage, setPromotionList);
            } else if (indexPromotionListInactive !== -1) {
              updateList(
                promotionListInactive,
                indexPromotionListInactive,
                downloadedImage,
                setPromotionListInactive
              );
            }
          })
          .catch(() => {
            // console.log("Foto não encontrada:", image[1]);
          });
      };

      if (type === 'product') {
        handleDownload(image[0], image[1]);
      } else {
        handleDownload('promocao', fotoThumb);
      }
    }
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setSearch(value);

    const normalizeText = (text) =>
      text.toLowerCase().normalize('NFD').replace(/[̀-ͯ]/g, '').replace(/'/g, '').trim();

    const filterPromotions = (list) =>
      list.filter(
        (promotion) =>
          promotion.title && normalizeText(promotion.title).includes(normalizeText(value))
      );

    if (value.length > 0) {
      if (isActive === 0) {
        setPromotionList(filterPromotions(allPromotionList));
      } else {
        setPromotionListInactive(filterPromotions(allPromotionListInactive));
      }
    } else {
      if (isActive === 0) {
        setPromotionList([...allPromotionList]);
      } else {
        setPromotionListInactive([...allPromotionListInactive]);
      }
    }
  };

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const toggleSendVoucherModal = (open, a = null) => {
    setOpenSendVoucherModal(open);
    if (a !== null) {
      setN(a);
    }
  };

  const openModalSelectClients = () => {
    setModalSelectClientsIsOpen(true);
  };

  const closeModalSelectClients = () => {
    setModalSelectClientsIsOpen(false);
  };

  const handleChangeCheckbox = () => {
    if (adminToggle) {
      // Se já estiver ativo, desativa e remove do localStorage
      setAdminToggle(false);
      localStorage.removeItem('adminToggle');
    } else {
      // Se estiver inativo, ativa e salva no localStorage
      setAdminToggle(true);
      localStorage.setItem('adminToggle', true);
    }
  };

  const handleChangePromotions = (event, newValue) => {
    setIsActive(newValue);
    setPromotionList([]);
    setPromotionListInactive([]);
    setPagesAccessed([]);
    setPage(0);

    //(ativas = 0, inativas = 1)
    localStorage.setItem('Lista de promoções', newValue);
  };

  return (
    <div
      style={{
        maxHeight: '100%',
        padding: '4rem 32px',
        paddingBottom: '70px',
        maxWidth: '100%',
      }}
    >
      <GoBackHeader />
      <HeaderTitle pageInfo="Ofertas do Estabelecimento" />
      <Grid item md={6} xs={6} />

      <Grid item sm={6} md={12} xs={12}>
        {isUserAdmin ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <span>Visão usuário</span>
            <Switch
              checked={adminToggle}
              onChange={(e) => {
                handleChangeCheckbox(e);
              }}
            />
            <span>Visão admin</span>
          </div>
        ) : (
          <></>
        )}
        <div className="position-btn2">
          <Button
            startIcon={<Add />}
            variant="contained"
            color="primary"
            className="style-botao-list"
            onClick={(event) => setAnchorEl(event.currentTarget)}
            style={{
              height: '2.2em',
              fontWeight: '550',
              width: '100%',
              textDecoration: 'none',
              color: 'white',
              textTransform: 'capitalize',
            }}
          >
            Adicionar Promoção
          </Button>
        </div>
      </Grid>

      <Grid container>
        <Grid item sm={12} xs={12} xl={12}>
          <div style={{ position: 'relative', marginTop: '15px' }}>
            <InputLabel
              style={{
                fontSize: '13px',
                marginBottom: '15px',
                focusVisible: 'none',
              }}
            >
              Pesquise sua promoção:
            </InputLabel>
            <Search style={{ position: 'absolute', bottom: '14px', left: '-1px' }} />
            <DebounceInput
              style={{
                fontSize: '16px',
                borderLeft: 'none',
                borderRight: 'none',
                borderTop: 'none',
                width: '100%',
                marginBottom: '10px',
                boxShadow: '0 0 0 0',
                outline: '0',
                paddingLeft: '26px',
                paddingBottom: '5px',
                borderBottom: '1px solid #6b6b6b',
              }}
              debounceTimeout={200}
              value={search}
              onChange={handleChange}
              disabled={loadingList}
            />
          </div>
          <div style={{ margin: '-7px -10px -5px', fontSize: '15px' }}>
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <Tabs onChange={handleChangePromotions} value={isActive} variant="scrollable">
                <Tab label="Promoções Ativas" value={0} />
                <Tab label="Promoções Inativas" value={1} />
              </Tabs>
            </Box>
          </div>
        </Grid>
      </Grid>

      <div>
        {loadingPromotionList ? (
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <CircularProgress size="50px" />
          </div>
        ) : promotionList.length !== 0 ||
          window?.promoListOrganized?.promotionListActive ||
          promotionListInactive.length !== 0 ? (
          <DataTableStoreExternal
            adminToggle={adminToggle}
            dataEstabelecimento={dataEstabelecimento}
            downloadImage={downloadImg}
            duplicatePromotion={duplicatePromotion}
            establishmentId={establishmentId}
            handleChangePage={handleChangePage}
            isAdmin={isUserAdmin}
            isActive={isActive}
            isDisableCheck={isDisableCheck}
            loadingList={loadingList}
            page={page}
            pathName={pathName}
            promotionList={isActive === 0 ? promotionList : promotionListInactive}
            rows={rows}
            rowsPerPage={rowsPerPage}
            style={{ marginBottom: '50px' }}
            tableNode={'Store'}
            toggleSendVoucherModal={toggleSendVoucherModal}
          />
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '60vh',
            }}
          >
            <Typography variant="h5">Não há promoções ativas</Typography>
          </div>
        )}
        <br />
        <br />
        <br />
      </div>

      {openSendVoucherModal && (
        <VoucherSendExternal
          location={{
            state: n,
            dataEstabelecimento: dataEstabelecimento,
          }}
          setN={setN}
          openModalSelectClients={openModalSelectClients}
          openSendVoucherModal={openSendVoucherModal}
          toggleSendVoucherModal={toggleSendVoucherModal}
        />
      )}

      <ModalSelectClients
        closeModalSelectClients={closeModalSelectClients}
        location={{
          state: n,
          dataEstabelecimento: dataEstabelecimento,
        }}
        modalSelectClientsIsOpen={modalSelectClientsIsOpen}
        toggleSendVoucherModal={toggleSendVoucherModal}
      />

      {/* Menu de promoções */}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(false)}
      >
        <MenuItem>
          <Link
            to={{
              pathname: adminToggle
                ? '/admin/estabelecimento/' + establishmentId + '/ofertas/promocao/add'
                : '/estabelecimento/' + establishmentId + '/promocao/adicionar',
              state: { dataEstabelecimento: dataEstabelecimento },
            }}
            onClick={() => localStorage.removeItem('fromWherePromotion')}
            style={{ color: 'rgba(0, 0, 0, 0.87)', textDecoration: 'none' }}
          >
            Promoção Simples Customizada
          </Link>
        </MenuItem>

        <MenuItem>
          <Link
            to={{
              pathname: adminToggle
                ? '/admin/estabelecimento/' + establishmentId + '/ofertas/promocaoAgrupada/add'
                : '/estabelecimento/' + establishmentId + '/promocaofidelidade/adicionar',
              state: { dataEstabelecimento: dataEstabelecimento },
            }}
            onClick={() => localStorage.removeItem('fromWherePromotion')}
            style={{ color: 'rgba(0, 0, 0, 0.87)', textDecoration: 'none' }}
          >
            Promoção de Fidelidade Customizada
          </Link>
        </MenuItem>
        <MenuItem>
          <Link
            to={{
              pathname: '/estabelecimento/' + establishmentId + '/promocaoexterna/adicionar',
              state: {
                dataEstabelecimento: dataEstabelecimento,
              },
            }}
            onClick={() => localStorage.removeItem('fromWherePromotion')}
            style={{
              color: 'rgba(0, 0, 0, 0.87)',
              textDecoration: 'none',
            }}
          >
            Promoção de Link Externo
          </Link>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default StoreListExternal;

