import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Tooltip } from '@mui/material';
import { CardButton } from 'js/components/DesignSystem/Buttons/CardButton';

export function StyledButton(props) {
  const { value, index, url, onClick, roles, block = false } = props;

  //Array de escolha de cores para o StyledButton
  const cor = ['#08BAD0', '#6E3296', '#6c63ff'];

  const newColor = cor[index % cor.length];

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  if (block) {
    return (
      <Tooltip
        title={'Apenas o(s) seguinte(s) cargo(s) tem acesso á este módulo: ' + roles.join(', ')}
        arrow
      >
        <div style={{ textDecoration: 'none', width: '100%', cursor: 'not-allowed' }}>
          <CardButton block={block} newColor={newColor} url={url} handleClick={handleClick}>
            {value}
          </CardButton>
        </div>
      </Tooltip>
    );
  } else {
    return (
      <div style={{ textDecoration: 'none', width: '100%', cursor: 'pointer' }}>
        <Link to={url}>
          <CardButton block={block} newcolor={newColor} url={url} handleClick={handleClick}>
            {value}
          </CardButton>
        </Link>
      </div>
    );
  }
}
