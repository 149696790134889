// Componentes extraídos
import React, { useState, useCallback, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Autocomplete,
  InputAdornment,
} from '@mui/material';
import { Send } from '@mui/icons-material';
import { Trash } from 'phosphor-react';
import { toast } from 'react-toastify';

import RenderInputDynamic from '../SearchUser/RenderInputDynamic';
import { cellPhoneMask2 } from 'js/library/utils/helpers';
import { ValidateUserPartner } from 'js/library/utils/API/apiValidateUserPartnerClub';
import { getUserInfo_v1 } from 'js/library/utils/API/getUserInfo_v1';
import { pointsExchangeUsersList } from 'js/library/utils/API/Points/pointsExchangeUsersList';
import { pointsExchangeBetweenContracts } from 'js/library/utils/API/Points/pointsExchangeBetweenContracts';

function UserList({ usersList, setUsersList }) {
  return usersList.map((item, index) => (
    <Box key={item.uId} component="span" sx={{ display: 'flex', alignItems: 'center' }}>
      <IconButton
        aria-label="Remover usuário"
        onClick={() => {
          const tempUL = [...usersList];
          tempUL.splice(index, 1);
          setUsersList([...tempUL]);
        }}
      >
        <Trash color="#da0000" />
      </IconButton>
      <Typography variant="body1" sx={{ pl: '0px' }}>
        {item.cellPhone !== item.key
          ? 'Chave: ' + item.key + ` || Telefone do usuário: ${cellPhoneMask2(item.cellPhone)}`
          : `Telefone do usuário: ${cellPhoneMask2(item.cellPhone)}`}
      </Typography>
    </Box>
  ));
}

function ClubAutocomplete({ clubesList, selectedClub, setSelectedClub }) {
  return (
    <Autocomplete
      fullWidth
      options={clubesList.sort((a, b) => a.clubeId.localeCompare(b.clubeId))}
      getOptionLabel={(option) => option.clubeId}
      id="select-club"
      value={selectedClub}
      onChange={(_, newValue) => setSelectedClub(newValue)}
      renderInput={(params) => (
        <TextField {...params} label="Selecione um clube" variant="outlined" />
      )}
    />
  );
}

function EstablishmentAutocomplete({
  userInfo,
  establishmentList,
  selectedEstablishment,
  setSelectedEstablishment,
  loadingEstablishmentList,
  setQueryEstablishment,
}) {
  if (!userInfo.admin && establishmentList.length === 0) {
    return (
      <Typography variant="body2" sx={{ pl: '10px', fontSize: '0.8rem' }}>
        Você não tem estabelecimentos vinculados a sua conta.
      </Typography>
    );
  }

  return (
    <Autocomplete
      fullWidth
      options={establishmentList}
      getOptionLabel={(option) => option.nome}
      id="select-establishment"
      value={selectedEstablishment}
      onChange={(_, newValue) => setSelectedEstablishment(newValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          disabled={loadingEstablishmentList}
          onChange={(e) => setQueryEstablishment(e.target.value)}
          label="Busque um estabelecimento"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loadingEstablishmentList ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

export default function SendUnitary({
  fieldKey,
  history,
  fromWhere,
  clubId,
  establishmentId,
  partnerId,
  clubesList,
  establishmentList,
  setQueryEstablishment,
  loadingEstablishmentList,
  userInfo,
}) {
  const [eventDescription, setEventDescription] = useState('');
  const [eventReason, setEventReason] = useState('');
  const [value, setValue] = useState(0);
  const [userSearch, setUserSearch] = useState('');
  const [usersList, setUsersList] = useState([]);
  const [selectedClub, setSelectedClub] = useState(null);
  const [selectedEstablishment, setSelectedEstablishment] = useState(null);
  const [receiverType, setReceiverType] = useState('');
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [loadingSearchUser, setLoadingSearchUser] = useState(false);

  console.log(fromWhere, 'fromWhere');

  const handleChange = (setter) => (e) => setter(e.target.value);

  const isUserDuplicated = (uId) => usersList.some((u) => u.uId === uId);

  useEffect(() => {
    setUsersList([]);
    setUserSearch('');
    setSelectedClub(null);
    setSelectedEstablishment(null);
  }, [receiverType]);

  useEffect(() => {
    console.log('selectedEstablishment', selectedEstablishment);
    console.log('selectedClub', selectedClub);
  }, [selectedEstablishment, selectedClub]);

  const searchUnitary = useCallback(
    async (e) => {
      e.preventDefault();
      if (!userSearch)
        return toast.error('Preencha o campo de chave do usuário.', { autoClose: 2000 });
      setLoadingSearchUser(true);

      try {
        if (fromWhere === 'adminConfig' || fromWhere === 'estabelecimento') {
          const payload = { contactList: [{ type: 'cellPhone', value: userSearch }] };
          const response = await getUserInfo_v1(['getUserInfo'], payload);
          const user = response.data.success.userInfo;
          if (!user) throw new Error('Usuário não encontrado.');
          if (isUserDuplicated(user.uId)) throw new Error('Usuário já adicionado.');

          setUsersList((prev) => [...prev, { ...user, cellPhone: userSearch, key: userSearch }]);
        } else {
          const user = await ValidateUserPartner(userSearch, partnerId);
          if (!user.user) throw new Error('Usuário não encontrado.');
          if (isUserDuplicated(user.uId)) throw new Error('Usuário já adicionado.');

          setUsersList((prev) => [...prev, { ...user, key: userSearch }]);
        }
      } catch (err) {
        toast.error(err.message, { autoClose: 2000 });
      } finally {
        setLoadingSearchUser(false);
        setUserSearch('');
      }
    },
    [userSearch, fromWhere, partnerId, usersList]
  );

  const addEventByList = async (e) => {
    e.preventDefault();
    const getIdAndType = () => {
      if (clubId) return { type: 'clubId', id: clubId };
      if (establishmentId) return { type: 'establishmentId', id: establishmentId };
      return {};
    };

    const { type, id } = getIdAndType();

    if (!eventDescription) return toast.error('A descrição é obrigatória!', { autoClose: 2500 });
    if (!value || value === 0)
      return toast.error('Digite um valor maior que 0.', { autoClose: 2500 });

    const typeOfSender =
      fromWhere === 'adminConfig' || fromWhere === 'estabelecimento' ? 'establishment' : 'club';

    const senderId =
      fromWhere === 'adminConfig'
        ? '-TriiboWifi'
        : fromWhere === 'estabelecimento'
        ? establishmentId
        : clubId;

    const receiverId =
      receiverType === 'club'
        ? selectedClub?.clubeId
        : receiverType === 'establishment'
        ? selectedEstablishment?.id
        : null;

    if (receiverType === 'user') {
      if (!usersList || usersList.length === 0)
        return toast.error('Adicione usuários na lista.', { autoClose: 2500 });

      const payload = {
        [type]: id,
        description: eventDescription,
        reason: eventReason,
        usersList: usersList.map((u) => u.uId),
        value,
        ...(fromWhere === 'adminConfig' ? { establishmentId: '-TriiboWifi' } : {}),
      };

      try {
        setLoadingAdd(true);
        await pointsExchangeUsersList(payload);
        toast.success('Pontos enviados com sucesso!');
        setTimeout(() => {
          setLoadingAdd(false);
          history.goBack();
        }, 2000);
      } catch (err) {
        const msg = err.response?.data?.error || 'Ocorreu um erro inesperado.';
        toast.error(msg, { autoClose: 2500 });
        setLoadingAdd(false);
      }
    } else {
      const payload = {
        typeOfSender,
        senderId,
        typeOfReceiver: receiverType,
        receiverId,
        value,
        description: eventDescription,
        reason: eventReason,
      };
      try {
        setLoadingAdd(true);
        await pointsExchangeBetweenContracts(payload);
        toast.success('Pontos enviados com sucesso!');
        setTimeout(() => {
          setLoadingAdd(false);
          history.goBack();
        }, 2000);
      } catch (err) {
        console.log(err);

        const msg = err.response?.data?.error || 'Ocorreu um erro inesperado.';
        toast.error(msg, { autoClose: 2500 });
        setLoadingAdd(false);
      }
    }
  };

  const extratoLabel = `Descrição no extrato${
    receiverType === 'user'
      ? ' do usuário'
      : receiverType === 'club'
      ? ' do clube'
      : receiverType === 'establishment'
      ? ' do estabelecimento'
      : ''
  }`;

  return (
    <FormControl fullWidth margin="normal">
      <TextField
        fullWidth
        onChange={handleChange(setValue)}
        value={value}
        type="number"
        label="Quantidade de Pontos"
        margin="normal"
      />
      <TextField
        fullWidth
        required
        multiline
        value={eventDescription}
        label="Descrição do evento (interno)"
        onChange={handleChange(setEventDescription)}
        margin="normal"
      />
      <TextField
        fullWidth
        required
        value={eventReason}
        label={extratoLabel}
        onChange={handleChange(setEventReason)}
        margin="normal"
      />

      <Grid container spacing={2} mt={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" fontWeight={600}>
            Selecione um tipo de destinatário*
          </Typography>
          <RadioGroup row value={receiverType} onChange={handleChange(setReceiverType)}>
            <FormControlLabel
              value="user"
              label={<Typography variant="subtitle1">Usuário</Typography>}
              control={<Radio />}
            />
            <FormControlLabel
              value="club"
              label={<Typography variant="subtitle1">Clube</Typography>}
              control={<Radio />}
            />
            <FormControlLabel
              value="establishment"
              label={<Typography variant="subtitle1">Estabelecimento</Typography>}
              control={<Radio />}
            />
          </RadioGroup>
        </Grid>

        <Grid item xs={12} sm={6} display="flex" gap={2}>
          {receiverType === 'user' && (
            <RenderInputDynamic
              fieldInfos={fieldKey}
              inputValue={userSearch}
              setInputValue={setUserSearch}
              handleSubmit={searchUnitary}
              loadingSearch={loadingSearchUser}
            />
          )}
          {receiverType === 'club' && (
            <ClubAutocomplete
              clubesList={clubesList}
              selectedClub={selectedClub}
              setSelectedClub={setSelectedClub}
            />
          )}
          {receiverType === 'establishment' && (
            <EstablishmentAutocomplete
              userInfo={userInfo}
              establishmentList={establishmentList}
              selectedEstablishment={selectedEstablishment}
              setSelectedEstablishment={setSelectedEstablishment}
              loadingEstablishmentList={loadingEstablishmentList}
              setQueryEstablishment={setQueryEstablishment}
            />
          )}
        </Grid>

        {usersList.length > 0 && (
          <Grid item xs={12} mt={3}>
            <UserList usersList={usersList} setUsersList={setUsersList} />
          </Grid>
        )}
      </Grid>

      <Button
        sx={{ mt: 6, color: 'white', textTransform: 'capitalize', fontWeight: 550 }}
        variant="contained"
        color="primary"
        startIcon={!loadingAdd && <Send />}
        disabled={
          loadingAdd ||
          !eventDescription ||
          !value ||
          value === 0 ||
          !eventReason ||
          !receiverType ||
          (receiverType === 'user' && usersList.length === 0) ||
          (receiverType === 'club' && !selectedClub) ||
          (receiverType === 'establishment' && !selectedEstablishment)
        }
        onClick={addEventByList}
      >
        {loadingAdd ? (
          <CircularProgress size={25} thickness={6} sx={{ color: '#fff' }} />
        ) : (
          'Enviar'
        )}
      </Button>
    </FormControl>
  );
}
