import styled from 'styled-components/macro';

export const DropDownContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DropDown = styled.div`
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || '48px'};
  position: relative;
`;

export const DropDownHeader = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 10px;
  border: 1px solid ${({ disabled, error }) => (error ? 'red' : disabled ? '#AFAFAF' : '#707070')};
  box-shadow: 0px 3px 6px #00000029;
  background: #fff;
  padding: 0.5rem 1rem;
  cursor: pointer;

  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    transition: 0.3s all ease-in-out;
  }

  svg.iconActive {
    transform: rotate(180deg);
  }
`;

export const DropDownListContainer = styled.div`
  position: absolute;
  top: ${(props) => props.top || '3rem'};
  z-index: 10;
  border-radius: 10px;
  width: 100%;
  background: #fff;
  box-shadow: 0px 3px 6px #00000029;
`;

export const DropDownList = styled.ul`
  list-style: none;
`;

export const ListItem = styled.li`
  padding: 0.7rem 1rem;
  width: 100%;
  background: #fff;
  border-radius: 10px;
  cursor: pointer;
  text-transform: capitalize;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  margin-top: 3px;
  padding: 11px;
  margin-bottom: 8px;
  box-sizing: border-box;
  border: 0;
  border-bottom: 1px solid #ccc;
  outline: 0;
`;
