import React, { useEffect, useState } from 'react';

import * as D from 'js/components/DesignSystem/styles';
import * as S from './styles';

import { Switch } from '@mui/material';

import { cloneDeep } from 'lodash';

import { getKeywords } from 'js/library/utils/API/SearchUser/apiGetKeywords';

import TriiboAutocomplete from 'js/components/DesignSystem/TriiboAutocomplete';

const StepFour = (props) => {
  const { adminForm, setAdminForm, preState, setDisabledButton } = props;
  const [allKeywords, setAllKeywords] = useState([]);
  const [current, setCurrent] = useState([]);

  useEffect(() => {
    getKeywords().then((res) => {
      setAllKeywords(res);
    });
  }, []);

  useEffect(() => {
    const newForm = cloneDeep(adminForm);
    setAdminForm({ ...newForm, keyWordsSet: current });
  }, [current]);

  const objKey = 'value';

  function handleChange(e, key) {
    e.preventDefault();
    let cloneForm = cloneDeep(adminForm);

    if (key === 'signatureId') {
      cloneForm.signatureId = e.target.value;
    }

    if (key === 'isPublished') {
      if (cloneForm.isPublished) {
        cloneForm.isPublished = false;
      } else {
        cloneForm.isPublished = true;
      }
    }

    setDisabledButton(false);
    setAdminForm(cloneForm);
  }

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <S.AdminContainer>
        <S.CardContainer id="card-01">
          <S.FormElement>
            <S.FormLabel>Desativar estabelecimento</S.FormLabel>
            <Switch
              checked={!adminForm.isPublished}
              onChange={(e) => {
                handleChange(e, 'isPublished');
              }}
            />
          </S.FormElement>
          <S.FormElement>
            <S.FormLabel>Número da assinatura</S.FormLabel>
            <D.TriiboTextField
              rows={1}
              placeholder={'000000'}
              maxLength="15"
              value={adminForm.signatureId}
              onChange={(e) => handleChange(e, 'signatureId')}
            />
          </S.FormElement>
          <S.FormLabel>Keywords:</S.FormLabel>
          <TriiboAutocomplete
            setDisabledButton={setDisabledButton}
            arr={allKeywords}
            setCurrent={setCurrent}
            objKey={objKey}
            label={'Keywords'}
            preSelected={preState.keyWordsSet}
          />
        </S.CardContainer>
      </S.AdminContainer>
    </div>
  );
};

export default StepFour;

